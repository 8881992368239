import { Platform } from 'react-native';
import Storage from '../utils/StorageHelper2';
import AppConfig from '../constants/AppConfig';

var AdjustMobilInitial = async function(){
    const {Adjust, AdjustConfig} = require('react-native-adjust');
    if(Platform.OS!=='web'){
        let appToken = '';
        let environment = '';
        if(Platform.OS==='ios'){
            appToken = AppConfig.Addjust.appToken_ios;
            environment = AppConfig.Addjust.environment_ios;
        } else {
            appToken = AppConfig.Addjust.appToken_android;
            environment = AppConfig.Addjust.environment_android;
        }
        console.log('Adjust_appToken:', appToken);
        console.log('Adjust_venvironment:', environment);

        const adjustConfig = new AdjustConfig(appToken, environment);    
        adjustConfig.setLogLevel(AdjustConfig.LogLevelVerbose);
        Adjust.create(adjustConfig);
        Adjust.getAdid((adid) => {
            Storage.storeData('Adid',adid);
        });
    }
}

var saveAdid = async function(){
    const {Adjust} = require('react-native-adjust');
    Adjust.getAdid((adid) => {
        Storage.storeData('Adid',adid);
    });
}

var getAdid = async function(){
    let getAdid ='';
    if(Platform.OS!=='web'){
         getAdid = await Storage.getData('Adid');
        if (getAdid==undefined) {
            await this.saveAdid();

            await sleep(1000);
            getAdid = await Storage.getData('Adid');
        }
        return getAdid;
    }
}

var sleep = async (milliseconds) => {
    await new Promise(resolve => {
      return setTimeout(resolve, milliseconds)
    });
  };

export default {
    AdjustMobilInitial,
    saveAdid,
    getAdid
}