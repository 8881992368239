import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions  } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import { AppContext } from '../../AppContext';
import Clipboard from '@react-native-clipboard/clipboard';
import PopupWrapper from "../../components/common/PopupWrapper";

const windowWidth = Dimensions.get('window').width;

export default class MessageMenuPopup extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      mode: 1,
      messageItem: null,
      isShowDeleteMenu: false
    }
    this.hideAction = null;
    
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {

    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (item, isShowDeleteMenu)=>{
    this.setState({ show: true,  isShowDeleteMenu: isShowDeleteMenu, messageItem: item }); 
  }

  hide = ()=>{
    this.setState({ show: false, mode: 1 });
  }

  render() {
    if(!this.state.show) return <></>;

    const popupWidth = windowWidth * 0.77
    if(this.state.mode == 1){
      return (
        <Portal style={PortalStyle.portal}>
          <View style={PortalStyle.portalContainer}>
            
            <View style={PortalStyle.overlay} />
  
            <View style={[styles.container,{width: popupWidth}]}>
              {this.state?.messageItem?.message ?
              <TouchableOpacity style={styles.btnBlue} onPress={()=>{
                Clipboard.setString(this.state?.messageItem?.message);
                // this.setState({ mode: 2});
                PopupWrapper.showMessage('クリップボードにコピーしました');
                // setTimeout(() => {
                  this.hide();
                // }, 600);
                
                }}>
                  <Text style={styles.txtBtnBlue}>メッセージをコピーする</Text>
              </TouchableOpacity>
              :
              null
              }
              {this.state?.messageItem?.messageId && this.state?.messageItem?.afterMatchingFlag == 1 && this.state?.isShowDeleteMenu == true  ?
              <TouchableOpacity style={styles.btnBlue} onPress={()=>{
                if(this.props?.handleDeleteMessage){
                  this.props?.handleDeleteMessage(this.state?.messageItem);
                }
                this.hide();

              }}>
                  <Text style={styles.txtBtnBlue}>送信を取り消す</Text>
              </TouchableOpacity>
              :
              null
              }
              <TouchableOpacity style={styles.btnCancel} onPress={()=>this.hide()}>
                  <Text style={styles.txtBtnCancel}>閉じる</Text>
              </TouchableOpacity>
  
            </View>
          </View>
        </Portal>
      );
    } else if(this.state.mode == 2){
      return(
        <Portal style={PortalStyle.portal}>
          <View style={PortalStyle.portalContainer}>
            <View style={PortalStyle.overlay} />
            <View style={[styles.container2]}>
              <Text >クリップボードにコピーしました</Text>
            </View>
          </View>
        </Portal>
      );

    }
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#ffffff', 
    height:'auto', 
    width:'77%',
    alignSelf:'center', 
    borderRadius:15,
    paddingTop:25,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 25
  },
  container2:{
    backgroundColor:'#ffffff', 
    height:'auto', 
    width:'auto',
    alignSelf:'center', 
    borderRadius:15,
    paddingTop:10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10
  },
  btnBlue:{
    marginBottom:15,
    width:'100%',
    padding:8,
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",

  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center'
  },
  btnCancel:{
    width:'45%',
    padding:2,
    height:30,
    marginTop:10,
    alignSelf:'center', 
    backgroundColor:'#f2f2f2',
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
    borderRadius:999,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#323232',
    textAlign:'center',
  },
  btnArea: {
    width:'100%',
    paddingTop:5,
    paddingBottom:8,
    paddingRight:10,
    paddingLeft:10,
  }
}); 