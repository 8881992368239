import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, Image, Dimensions  } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import { AppContext } from '../../AppContext';
import UserUtil from '../../utils/UserUtil';
import Constants from '../../constants/Constants';
import * as RootNavigation from '../../navigation/RootNavigation';

const windowWidth = Dimensions.get('window').width;

export default class PrivilegeCampaignPopup extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      partnerMsg: false,
      myProfile: { sex: 0 }
    }
    this.hideAction = null;
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      if(this.navigation==undefined) this.navigation = RootNavigation;

      const { appState } = this.context;
      const myProfile = await UserUtil.getUserProfileLocal(appState);
      if(myProfile){
        this.setState({ myProfile: myProfile });
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (hideAction)=>{
    if(hideAction!=undefined){
      this.hideAction = hideAction;
    }else{
      this.hideAction = null;
    }
    this.setState({ show: true});
  }

  showPartner = (hideAction)=>{
    if(hideAction!=undefined){
      this.hideAction = hideAction;
    }else{
      this.hideAction = null;
    }
    this.setState({ show: true, partnerMsg: true});
  }

  hide = ()=>{
    this.setState({ show: false, partnerMsg: false});
  }

  render() {
    if(!this.state.show) return <></>;

    const popupWidth = windowWidth * 0.77
    const newHeight = popupWidth * 1154 / 912

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          
          <View style={PortalStyle.overlay} />
          <View style={{width:'90%'}}>
              <TouchableOpacity style={{width:32, height:32,alignSelf: 'flex-end',marginBottom:10}} onPress={()=>{
                this.hide();
              }} >
                <Image resizeMode={'cover'} style={{width:32, height:32}} source={require('../../assets/images/icon/close.png')}/>
              </TouchableOpacity>
                
            </View>
          <View style={[styles.container,{width: popupWidth, height:newHeight}]}>

            <ImageBackground resizeMode={'cover'} 
              style={{width: popupWidth, height:newHeight, justifyContent: 'flex-end', alignItems: 'center'}} 
              imageStyle={{borderTopLeftRadius:15, borderTopRightRadius:15}} 
              source={require('../../assets/images/pappy-pop-1080-2024-11m.png')}
            >
              <View style={styles.btnArea}>

                <TouchableOpacity style={styles.btnCancel} onPress={()=>this.hide()}>
                  {/*<Text style={styles.txtBtnCancel}>キャンセル</Text>*/}
                  <Text style={styles.txtBtnCancel}>閉じる</Text>
                </TouchableOpacity>
              </View>
            </ImageBackground>

          </View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#ffffff', 
    height:'auto', 
    width:'77%',
    alignSelf:'center', 
    borderRadius:15,
  },
  btnBlue:{
    marginBottom:10,
    width:'100%',
    padding:8,
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center'
  },
  btnCancel:{
    width:'45%',
    padding:2,
    height:30,
    marginTop:10,
    marginBottom:10,
    alignSelf:'center', 
    backgroundColor:'#f2f2f2',
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
    borderRadius:999,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#323232',
    textAlign:'center',
  },
  btnArea: {
    width:'100%',
    paddingTop:5,
    paddingBottom:8,
    paddingRight:10,
    paddingLeft:10,
  }
});