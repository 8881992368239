import ApiPappy from '../../utils/ApiPappy';
import ApiConfig from '../../constants/ApiConfig';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';
import CommonFunction from '../../utils/CommonFunction';
import WebUtil from '../../utils/WebUtil';
import AppUtil from '../../utils/AppUtil';

/**
 * API: 03_API仕様\16_電話番号アクティベート
 */
export default class Activate16Api extends ApiPappy{
  //1.1.1.電話番号チェック
  static async CheckTel(values, showError=1){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Activate/Get/CheckTel/",
        { tel: values },
        true
      );
      if(__DEV__) console.log('/Activate/Get/CheckTel/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Activate/Get/CheckTel/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.1.SMSで認証コード送信
  static async SendVerificationCodeSMS(values, showError=1){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Activate/Post/SendVerificationCodeSMS/",
        { tel: values },
        true
      );
      if(__DEV__) console.log('/Activate/Post/SendVerificationCodeSMS/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Activate/Post/SendVerificationCodeSMS/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.2.アクティベート処理
  static async VerificationCode(values, showError=1){
    try{
      let webUUID = await WebUtil.getWebUUID();
      let adid = await AppUtil.getAdid();
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Activate/Put/VerificationCode/",
        {
          tel: values.tel, 
          token: values.token, 
          code: values.code, 
          web_uuid: webUUID,
          adid: adid
        },
        true
      );
      if(__DEV__) console.log('/Activate/Put/VerificationCode/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Activate/Put/VerificationCode/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.1.電話をかけて認証　電話番号とキー取得
  static async Call(values, showError=1){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Activate/Get/Call/",
        { tel: values },
        true
      );
      if(__DEV__) console.log('/Activate/Get/Call/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Activate/Get/Call/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
  //1.3.2.電話をかけて認証　アクティベート処理
  static async TelActivate(values, showError=1){
    try{
      let webUUID = await WebUtil.getWebUUID();
      let adid = await AppUtil.getAdid();
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Activate/Put/TelActivate/",
        {
          tel: values.tel, 
          token: values.token,
          web_uuid: webUUID,
          adid: adid
        },
        true
      );
      if(__DEV__) console.log('/Activate/Put/TelActivate/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField);
            console.log('/Activate/Put/TelActivate/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}