import React,{ Component } from 'react';
import { 
  Image, StyleSheet, Text, FlatList, View, ScrollView, TouchableOpacity, Platform, ImageBackground, Dimensions, Alert
} from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import ActivityIndicator from '../components/common/ActivityIndicator';
import Payment15Api from '../services/api/Payment15Api';
import Payment15Api_android from '../services/api/Payment15Api_android';
import UserUtil from '../utils/UserUtil';
import { AppContext } from '../AppContext';
import Popup from '../components/common/Popup';
import PortalStyle from '../styles/PortalStyles';
import ConfirmPopupStyle from '../styles/ConfirmPopupStyles';
import SMSWrapper from "../components/common/SMSWrapper";
import AgeWrapper from "../components/common/AgeWrapper";
import ListStyles from '../styles/ListStyles';
import ErrorCode from '../constants/ErrorCode';
import PopupWrapper from '../components/common/PopupWrapper';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';
import { getProductIdsSubs, launchBillingFlowSubs, queryPurchasesSubs, checkPurchasedItem, addEventListener, setMode, setProfileId } from '../modules/purchase';
import apiConfig from '../constants/ApiConfig';
import formStyles from '../styles/FormStyles';
import { MODE } from "@env"
import base64 from 'react-native-base64';

const { checkUndefined } = CommonFunction;
const windowWidth = Dimensions.get('window').width;

export default class PurchaseScreen_android extends Component {
  static contextType = AppContext;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: []
    };
    // setting previous route without bottom tab
    this.prevRoute = ['ChatRoom'];
    this.popup = React.createRef();
    this.popupComplete = React.createRef();
    this.loadingPopup = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;

      this._unsubscribe = this.navigation?.addListener('focus', async() => {
        this.setHeader();
        const myProfile = await UserUtil.getUserProfileLocal(this.context);
        if(myProfile.memberId!=undefined){
          CommonFunction.checkAccessTime();
        }
        this.getStoreDetails();
        CommonFunction.hideBottomTab(this.navigation);
      });
      if(this.subscription ==undefined){
        this.subscription = addEventListener(async({errorMessage: errorMessage, originalJson: originalJson, signature: signature, productId:productId, acknowledged:acknowledged }) => {

          if(errorMessage){
            this.loadingPopup.hidePopup();
            PopupWrapper.showMessage(errorMessage, 'warning',()=>{
              CommonFunction.hideOverlay();
            });
          }else{
            // ここで新規購入APIを送信する
            this.subscriptionGooglePlay(originalJson, signature)
          }
        });
      }

    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.subscription !=undefined){
      this.subscription.remove();
    }
    this.checkBottomTab();
  }  

  checkBottomTab=()=>{
    let showBottomTab = true;
    const routes = this.navigation?.getState()?.routes;
    if(routes!=undefined){
      const prevRoute = routes[routes.length - 1];
      if(this.prevRoute?.indexOf(prevRoute.name)!=-1) showBottomTab = false;
    }
    if(showBottomTab) {
      CommonFunction.showBottomTab(this.navigation);
    }
  }

  getStoreDetails = async ()=>{
    if(!this.state.isRefreshing){
      this.setState({ loading: true });
    }
    setMode(apiConfig.mode)
    const myProfile = await UserUtil.getUserProfileLocal(this.context);
    if(myProfile.memberId!=undefined){
      setProfileId(base64.encode(myProfile.memberId))
    }
    
    this.productDetailsList = await getProductIdsSubs()
    this.setState({  data: this.productDetailsList })
    this.setState({ loading: false});

  }

  subscriptionGooglePlay = async (receiptData, signature) =>{
    if(!receiptData) {
      return;
    }
    try{   
      const responseSubscriptionGooglePlay = await Payment15Api_android.subscriptionGooglePlay(receiptData, signature );

      if(!CommonFunction.checkResponseStatus(responseSubscriptionGooglePlay)) return;
      if(checkUndefined(responseSubscriptionGooglePlay) && responseSubscriptionGooglePlay?.status==1){
        Alert.alert('', 'プラン購入が完了しました', [
          {
            text: 'OK',
          },
        ]);
      }else{
        if(CommonFunction.checkIsInvalidErrorCode(responseSubscriptionGooglePlay)){
          const response = responseSubscriptionGooglePlay?.response;
          const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.loadingPopup.hidePopup();
    }
  }

  restoreGooglePlay = async (receiptData, signature) =>{

    if(!receiptData) {
      return;
    }
    try{
      const responseRestoreGooglePlay = await Payment15Api_android.restoreGooglePlay(receiptData, signature );

      if(!CommonFunction.checkResponseStatus(responseRestoreGooglePlay)) return;

      if(checkUndefined(responseRestoreGooglePlay) && responseRestoreGooglePlay?.status==1){
        // PopupWrapper.showMessage('リストアが完了しました。', 'check');
        Alert.alert('', 'リストアが完了しました', [
          {
            text: 'OK',
          },
        ]);
      }else{
        if(CommonFunction.checkIsInvalidErrorCode(responseRestoreGooglePlay)){
          const response = responseRestoreGooglePlay?.response;
          const errorCode = `${response?.errorGroup}-${response?.errorCode}`;
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.loadingPopup.hidePopup();
    }
  }

  getDataFromApi = async ()=>{
    try{
      const checkAccessTimeFlg = await CommonFunction.checkAccessTime();
      if(!checkAccessTimeFlg) return;

      const orderResponse = await Payment15Api.SubscriptionUrlTelecom();
      if(!CommonFunction.checkResponseStatus(orderResponse)) return;

      if(checkUndefined(orderResponse) && orderResponse?.status==1){
        let data = orderResponse?.response;
        let account = UserUtil.getAccountAppState(orderResponse, this.context);
        this.setState({
          account: account,
          data: data
        });
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false});
    }
  }

  setHeader = () =>{
    CommonFunction.setHeaderSetting(this.navigation, '有料会員登録', true);
  }
  
  renderItem = ({item, index}) => {
    let banner;
    let bannerStyle = styles.itemBlogRatePlan;
    var itemName = item.replace('-stage', '').replace('-prod', '')
    switch(itemName){
      case 'basic-membership-12month':
        banner = apiConfig.pappy_image_url + '/image/subscription/and/subscription04.png';
        break;
      case 'basic-membership-6month':
        banner = apiConfig.pappy_image_url + '/image/subscription/and/subscription03.png';
        break;
      case 'basic-membership-3month':
        banner = apiConfig.pappy_image_url + '/image/subscription/and/subscription02.png';
        break;
      case 'basic-membership-1month':
        banner = apiConfig.pappy_image_url + '/image/subscription/and/subscription01.png';
        bannerStyle = styles.itemBlogRatePlanOther;
        break;
      default:
        break;
    }
    
    if(banner){
      return (
        <View style={{flex:1, width:'100%', justifyContent:'center',alignSelf:'center',alignItems:'center',alignContent:'center'}}>
        <TouchableOpacity key={index.toString()} style={{width:'100%'}} onPress={()=>{
          this.handleOnPress(item);
        }}>
          <Image style={bannerStyle} source={{uri:banner}} resizeMode="contain"/>
        </TouchableOpacity>
        </View>
      );
    }
  }

  noItemDisplay = () => {
    return <></>;
  }

  createInputElement = (type='hidden',name,value)=>{
    let element = document.createElement('input');
    element.setAttribute("type", type);
    element.setAttribute("name", name);
    element.setAttribute("value", value);
    return element;
  }

  onItemBay = (productId)=>{
    launchBillingFlowSubs(productId)
  }

  handleOnPress = async(item)=>{
    if(this.isProcessing)  return;
    this.isProcessing = true;
    const checkSubscriptionStatResopnse = await Payment15Api.CheckSubscriptionStat(0);
    if(!CommonFunction.checkResponseStatus(checkSubscriptionStatResopnse)) return;

    if(checkSubscriptionStatResopnse?.status==1){
      var result = await checkPurchasedItem()

      if(!result.errorMessage){
        this.loadingPopup.showPopup();
        this.onItemBay(item)  
      } else{
        PopupWrapper.showMessage(result.errorMessage, 'warning',()=>{
          CommonFunction.hideOverlay();
        });  
      }
      
    }else{
      if(CommonFunction.checkIsInvalidErrorCode(checkSubscriptionStatResopnse)){
        if(checkSubscriptionStatResopnse.response!=undefined){
          const res = checkSubscriptionStatResopnse.response;
          const errorCode = `${res?.errorGroup}-${res?.errorCode}`;
          const errorMessage = res?.errorMessage;
          if(errorCode==ErrorCode.CheckSubscriptionStat.Activated){ // sms
            SMSWrapper.showSMS(this.navigation);
          }else if(errorCode==ErrorCode.CheckSubscriptionStat.AgeVerfication){ // age
            AgeWrapper.showAge(this.navigation);
          }else{
            if(errorMessage) PopupWrapper.showMessage(errorMessage, 'warning',()=>{
              CommonFunction.hideOverlay();
            });
          }
        }
      }
    }
    setTimeout(() => {
      this.isProcessing = false;
    }, 1000);  
  }

  render() {
    if(this.state.loading) return <ActivityIndicator/>;

    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <ScrollView style={ListStyles.scrollContainer} 
        ref={scroller => { this.scroller = scroller; }} 
        scrollToOverflowEnabled={true}
        scrollIndicatorInsets={{ right: 1 }}>
          <View style={styles.titlePage}>
              <View style={styles.titlePageTxt}>
                <Text style={styles.txtTitlePageBig}>有料会員登録</Text><Text style={styles.txtTitlePage}>をすると•••</Text>
                </View>
            </View>
          <View style={styles.blogInner}>
            <ImageBackground source={require('../assets/images/purchase/img01.png')} resizeMode="stretch" style={styles.imgBG3Banner}>
              <View style={styles.imgBG3BannerInside}>
                <Text style={styles.txtBG3Banner}>何人でもメッセージの{"\n"}やりとりが無制限で{"\n"}できるように！</Text>
              </View>
            </ImageBackground>
            <ImageBackground source={require('../assets/images/purchase/img02.png')} resizeMode="stretch" style={styles.imgBG3Banner}>
              <View style={styles.imgBG3BannerInside}>
                <Text style={styles.txtBG3Banner}>お相手の女性の{"\n"}人気度が{"\n"}一目でわかる！</Text>
              </View>
            </ImageBackground>
            <ImageBackground source={require('../assets/images/purchase/img03.png')} resizeMode="stretch" style={styles.imgBG3Banner}>
              <View style={styles.imgBG3BannerInside}>
                <Text style={styles.txtBG3Banner}>有料会員登録と{"\n"}同時にポイントが{"\n"}もらえる！</Text>
              </View>
            </ImageBackground>
            <ImageBackground source={require('../assets/images/purchase/img04.png')} resizeMode="stretch" style={styles.imgBG3BannerLast}>
              <View style={styles.imgBG3BannerInsideLast}>
                <Text style={styles.txtBG3BannerLast}><Text style={styles.txtBG3BannerLast1}>6</Text><Text style={styles.txtBG3BannerLast2}>ヶ月以上</Text><Text style={styles.txtBG3BannerLast3}>の</Text>{"\n"}プランがおすすめ</Text>
              </View>
            </ImageBackground>
            <View style={styles.blogRatePlan}>
              <Text style={styles.txtBlogRatePlan}>料金プランを選択してください</Text>
            </View>
            <View style={styles.blogRatePlanInside}> 
              <FlatList
                scrollEnabled={false} 
                contentContainerStyle={{ flexGrow: 1 }}
                data={this.productDetailsList}
                renderItem={this.renderItem}
                ListEmptyComponent={this.noItemDisplay}
                keyExtractor={(item,index) => item}
                inverted={true}
              />
              
            </View>
            <View style={styles.blogInside1}>
              <Text style={styles.txtBlogInside1Top}>\ 会員登録するとこんなメリットが！/</Text>
              <Text style={styles.txtBlogInside1Bottom}>有料会員ができること</Text>
            </View>
            <View style={styles.blogImgTitle01}>
              <Text style={styles.txtBlogInside1Title}>メッセージし放題！</Text>
            </View>
            <View style={styles.blogDesc01}>
              <Text style={styles.txtBlogDesc}><Text style={styles.txtOrange}>いつでも何人とでも</Text>女性との{"\n"}メッセージのやりとりが可能です。</Text>
            </View>
            <View style={styles.blogChat}>
              <Image style={styles.imgChat03} source={require('../assets/images/purchase/chat03.png')} resizeMode="contain"/>
            </View>
            <View style={styles.blogPlusMonthlyGifts}>
              <Image style={styles.imgBlogPlusMonthlyGifts} source={require('../assets/images/purchase/img09.png')} resizeMode="contain"/>
              <View style={styles.blogPlusMonthlyGiftsInside}>
                <Text style={styles.txtBlogPlusMonthlyGiftsInside}>毎月有料会員の方に50ポイントを{"\n"}プレゼント致します。</Text>
              </View>              
            </View>
            <View style={styles.blogRatePlan}>
              <Text style={styles.txtBlogRatePlan}>料金プランを選択してください</Text>
            </View>
            <View style={[styles.blogRatePlanInside, {paddingBottom:10}]}> 
              <FlatList
                scrollEnabled={false}
                contentContainerStyle={{ flexGrow: 1 }}
                data={this.productDetailsList}
                renderItem={this.renderItem}
                ListEmptyComponent={this.noItemDisplay}
                keyExtractor={(item,index) => item}
                inverted={true}
              />
              
            </View>
            <View style={styles.blogNote}>
              <View style={styles.blogNoteTitle}><Text style={styles.txtBlogNoteTitle}>有料会員の注意事項</Text></View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>お申し込みを起算日とし、12か月後（12ヶ月プラン）・6か月後（6ヶ月プラン）・3か月後（3ヶ月プラン）・1か月後（1ヶ月プラン）に自動更新されます</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>自動更新は期限が切れる24時間以内に自動的に課金される仕組みです</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>自動更新をご希望ではない場合は期限が切れる24時間前までに解約を完了させてください</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>次回更新日は、
                  <TouchableOpacity onPress={()=>{this.navigation.navigate('MemberStatus')}}>
                    <Text style={[styles.txtBlue, styles.txtUnderline]}>会員ステータス</Text>
                  </TouchableOpacity>
                  ページでご確認いただけます</Text>
                </View>
              </View>
              <View style={styles.blogNoteInside}>
                <View style={styles.blogNoteInsideLeft}>
                  <Text style={styles.txtBlogNote}>・</Text>
                </View>
                <View style={styles.blogNoteInsideRight}>
                  <Text style={styles.txtBlogNote}>更新日前に解約のお相手続きをされた場合、残りの期間については月割り・日割りでの返金はいたしません</Text>
                </View>
              </View>
            </View>
            <TouchableOpacity style={styles.blogFooter} onPress={()=>{this.navigation.navigate('Terms')}}>
              <Text style={[styles.txtSkyBlue, styles.txtUnderline, {textAlign:'center',}]}>利用規約</Text>
            </TouchableOpacity>
            <View style={[formStyles.buttonPanelSetting]}>
              <TouchableOpacity style={formStyles.submitBtn} onPress={async()=>{
                  this.loadingPopup.showPopup();
                  var result = await queryPurchasesSubs()
                  if(!result.errorMessage){
                    if(result.acknowledged == 'true' ){
                      // リストアAPI
                      this.restoreGooglePlay(result.originalJson, result.signature)
                    } else {
                      // 新規購入API
                      this.subscriptionGooglePlay(result.originalJson, result.signature)
                    }
                  } else{
                    this.loadingPopup.hidePopup();
                    PopupWrapper.showMessage(result.errorMessage, 'warning',()=>{
                      CommonFunction.hideOverlay();
                    });
                  }
                }
              }>
                <Text style={formStyles.loginText}>リストアする</Text>
                
              </TouchableOpacity>
              
            </View>
            <Text style={[{marginTop:10, marginBottom:20, fontSize:10.5, lineHeight:18,textAlign:'center',color: '#98a3a5'}]}>決済の内容がアプリに反映されない場合にリストアをお試しください。</Text>
          </View>         
        </ScrollView>
        <Popup ref={ref=>this.popup=ref} modal={true}>
          <View style={PortalStyle.overlay} onStartShouldSetResponder={() => {}} />
          <View style={ConfirmPopupStyle.popupContainer}>
            <View style={ConfirmPopupStyle.popupTextItem}>
              <Text style={ConfirmPopupStyle.popupText}>有料会員登録にはメールアドレスの登録が必要です。</Text>
            </View>
            <View style={ConfirmPopupStyle.btnContainer}>
              <TouchableOpacity style={[ConfirmPopupStyle.btnItem, ConfirmPopupStyle.borderRight]} onPress={()=>{
                this.popup?.hidePopup();
                this.navigation.navigate('SettingStack',{screen:'MailInput'});
              }}>
                <Text style={ConfirmPopupStyle.btnText}>OK</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[ConfirmPopupStyle.btnItem, ConfirmPopupStyle.borderLeft]} onPress={()=>{
                this.popup?.hidePopup();
              }}>
                <Text style={ConfirmPopupStyle.btnText}>今はしない</Text>
              </TouchableOpacity>  
            </View>              
        </View>
      </Popup>
      <Popup ref={ref=>this.popupComplete=ref} modal={true}>
          <View style={PortalStyle.overlay} onStartShouldSetResponder={() => {}} />
          <View style={ConfirmPopupStyle.popupContainer}>
            <View style={ConfirmPopupStyle.popupTextItem}>
              <Text style={ConfirmPopupStyle.popupText}>プラン購入が完了しました</Text>
            </View>
            <View style={ConfirmPopupStyle.btnContainer}>
              <TouchableOpacity style={[ConfirmPopupStyle.btnItem, ConfirmPopupStyle.borderRight]} onPress={()=>{
                this.popupComplete?.hidePopup();
              }}>
                <Text style={ConfirmPopupStyle.btnText}>OK</Text>
              </TouchableOpacity> 
            </View>              
        </View>
      </Popup>
      <Popup ref={ref=>this.loadingPopup=ref} modal={true}>
        <View style={PortalStyle.overlay} />
        <ActivityIndicator size={90} color={'rgba(255,255,255,0.85)'} />
      </Popup>
    </View>
  }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  titlePage:{
    paddingTop:15,
    paddingBottom:15,
    alignSelf:'center', 
  },
  titlePageTxt:{
    flexDirection:'row',
    textAlign:'center',
  },
  txtTitlePageBig:{
    fontSize:windowWidth<375? 23:27,
    fontWeight:'bold',
    lineHeight:38,
    color:'#5A70B6',
    letterSpacing:1,
  },
  txtTitlePage:{
    fontSize:windowWidth<375? 18:22,
    fontWeight:'bold',
    paddingTop:8,
    lineHeight:30,
    color:'#5A70B6',
    letterSpacing:1,
  },
  blogInner:{},
  imgBG3Banner:{
    width:'100%',
    height:windowWidth<375? 138:161,
  },
  imgBG3BannerInside:{
    paddingTop:windowWidth<375? 65:75,
    paddingLeft:windowWidth<375? 52:55,
    paddingRight:windowWidth<375? 138:150,
    textAlign:'center',
  },
  txtBG3Banner:{
    fontSize:windowWidth<375? 12:15,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 19:23,
    color:'#555555',
    letterSpacing:1,
    textAlign:'center',
  },
  imgBG3BannerLast:{    
    marginTop:26,
    width:'100%',
    height:windowWidth<375? 121:141,
  },
  imgBG3BannerInsideLast:{
    paddingTop:windowWidth<375? 52:60,
    paddingLeft:20,
  },
  txtBG3BannerLast:{
    fontSize:windowWidth<375? 16:19,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22:30,
    color:'#65bd39',
  },
  txtBG3BannerLast1:{
    fontSize:windowWidth<375? 26:31,
  },
  txtBG3BannerLast2:{
    fontSize:windowWidth<375? 23:27,
  },
  txtBG3BannerLast3:{
    fontSize:windowWidth<375? 20:23,
  },
  blogRatePlan: {
    marginTop:22,
    marginBottom:12,
    textAlign:'center',
  },
  txtBlogRatePlan: {
    fontSize:windowWidth<375? 16:20,
    fontWeight:'bold',
    lineHeight:22,
    color:'#555',
    textAlign:'center'
  },
  blogRatePlanInside:{
    backgroundColor:'#f0f5f6',
    paddingTop:30,
  },
  itemBlogRatePlanOther:{
    width:'100%',
    height:windowWidth<375? 51:59,
  },
  itemBlogRatePlan:{
    marginLeft:5,
    marginRight:5,
    width:'100%',
    height:windowWidth<375? 128:148,
  },
  blogInside1:{
    paddingTop:30,
    paddingBottom:15,
    paddingLeft:15,
    paddingRight:15,
    textAlign:'center',
  },
  txtBlogInside1Top:{
    fontSize:windowWidth<375? 12:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#445F8E',
    textAlign:'center',
  },
  txtBlogInside1Bottom:{
    fontSize:windowWidth<375? 23:27,
    fontWeight:'bold',
    lineHeight:38,
    color:'#5A70B6',
    letterSpacing:1,
    textAlign:'center',
  },
  blogImgTitle01:{
    marginBottom:10,
    textAlign:'center',
    backgroundColor:'#6FC5E5',
  },
  txtBlogInside1Title:{
    fontSize:windowWidth<375? 23:27,
    paddingTop:10,
    paddingBottom:10,
    fontWeight:'bold',
    lineHeight:38,
    color:'#FFFFFF',
    letterSpacing:1,
    textAlign:'center',
  },
  imgTitle01:{
    width:'100%',
    height:windowWidth<375? 49:58,
  },
  blogImgTitle02:{ },
  imgTitle02:{
    width:'100%',
    height:windowWidth<375? 69:81,
  },
  blogDesc01:{
    padding:15,
    textAlign:'center',
  },
  txtBlogDesc:{
    fontSize:windowWidth<375? 14:17,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22:26,
    color:'#555',
    textAlign:'center',
  },
  blogDesc02:{
    padding:30,
    textAlign:'center',
  },
  blogChat:{
    marginTop:20,
    marginBottom:0,
  },
  imgChat03:{
    width:'100%',
    height:windowWidth<375? 501:588,
  },
  blogImg05:{
    marginBottom:38,
  },
  img05:{
    width:'100%',
    height:windowWidth<375? 147:173,
  },
  blogImgBG3:{
    marginBottom:10,
  },
  imgBlogImgBG3:{
    width:'100%',
    height:windowWidth<375? 49:58,
  },
  imgBlogImgBG3Last:{
    height:windowWidth<375? 71:84,
  },
  imgBlogImgBG3Inside:{
    paddingTop:16,
    paddingBottom:16,
    paddingLeft:80,
    paddingRight:30,
  },
  txtBlogImgBG3:{
    fontSize:windowWidth<375? 14:17,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22:26,
    color:'#555',
  },
  blogPlusMonthlyGifts:{
    marginTop:30,
    marginBottom:30,
    paddingTop:30,
    paddingBottom:25,
    backgroundColor:'#fffce1',
  },
  imgBlogPlusMonthlyGifts:{
    width:'100%',
    height:windowWidth<375? 136:160,
  },
  blogPlusMonthlyGiftsInside:{
    paddingTop:16,
    paddingLeft:16,
    paddingRight:16,
    textAlign:'center',
  },
  txtBlogPlusMonthlyGiftsInside:{
    fontSize:windowWidth<375? 14:17,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 22:26,
    color:'#9a5f16',
    textAlign:'center',
  },
  blogNote:{
    paddingTop:30,
    paddingBottom:30,
    paddingLeft:16,
    paddingRight:16,
  },
  blogNoteTitle:{
    marginBottom:10,
  },
  txtBlogNoteTitle:{
    fontSize:windowWidth<375? 11:13,
    fontWeight:'bold',
    lineHeight:16,
    color:'#555',
  },
  blogNoteInside:{
    flexDirection:'row',
  },
  blogNoteInsideLeft:{
    flexDirection:'column',
    width:12,
  },
  blogNoteInsideRight:{
    flex:1,
    flexDirection:'column',
    paddingLeft:3,
  },
  txtBlogNote:{
    fontSize:windowWidth<375? 10:12,
    fontWeight:'normal',
    lineHeight:windowWidth<375? 17:20,
    color:'#555',
  },
  blogFooter:{
    paddingBottom:0,
    textAlign:'center',
  },
  txtOrange:{
    color:'#F19B38',
  },
  txtBlue:{
    color:'#004eae',
  },
  txtSkyBlue:{
    color:'#61bbd7',
  },
  txtUnderline:{
    textDecorationLine:'underline',
  }
});

