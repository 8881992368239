import React,{ Component } from 'react';
import { 
  StyleSheet, Text, View, ScrollView, Image, TouchableOpacity, Platform, Dimensions
} from 'react-native';
import CommonFunction from '../utils/CommonFunction';
import { AntDesign } from '@expo/vector-icons'; 
import MyPage13Api from '../services/api/MyPage13Api';
import UserUtil from '../utils/UserUtil';
import { AppContext } from '../AppContext';
import UserVerify from '../utils/UserVerify';
import Constants from '../constants/Constants';
import ActivityIndicator from '../components/common/ActivityIndicator';
import { RefreshControl } from 'react-native-web-refresh-control';
import ApiConfig from '../constants/ApiConfig';
import moment from 'moment';
import ListStyles from '../styles/ListStyles';
import HeaderOptions from '../constants/HeaderOptions';

const {checkUndefined} = CommonFunction;
const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight - HeaderOptions.bottomTabHeight;


export default class MemberStatusScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.title = '会員ステータス';
    this.state = {
      loading: true,
      isRefreshing: false,
      account: UserUtil.getDefaultAccountAppState(),
      data: { sex: 0, subscription:{ status: 0 } },
      myProfile: [],
      isTrial: false,
      alreadyCallApi: false
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      this.setHeader();
      //CommonFunction.checkAccessTime();

      // blur screen
      this._unsubscribeBlur = this.navigation.addListener('blur', () => {
        this.setState({alreadyCallApi: false, loading:true});
      });

      // focus screen
      this._unsubscribe = this.navigation.addListener('focus', () => {
        CommonFunction.showBottomTab(this.navigation);
        if(!this.state.alreadyCallApi){
          this.setState({loading: true},()=>{
            this.getDataFromApi();
          });
        }else{
          this.getDataFromApi();
        }
        
        const { appState } = this.context;
        if(appState?.account){
          this.setState({ account: appState.account});
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this._unsubscribe!=undefined){
      this._unsubscribe();
    }
    if(this._unsubscribeBlur!=undefined){
      this._unsubscribe();
    }
  }  

  setHeader = () =>{
    CommonFunction.setHeaderSetting(this.navigation, this.title);
  }

  checkSMS = ()=>{
    if(UserVerify.checkSMSAppState(this.context)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account.activated, 
      navigation: this.navigation,
      redirect: false,
      account: this.state.account
    });
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.context)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account.ageVerficationStatus,
      navigation: this.navigation
    });
  }

  getDataFromApi = async ()=>{
    try{
      const myProfile = await UserUtil.getUserProfileLocal(this.context.appState);
      const checkMemberStatResponse = await MyPage13Api.CheckMemberStat();
      
      //check login
      if(!CommonFunction.checkResponseStatus(checkMemberStatResponse)) return;

      if(checkUndefined(checkMemberStatResponse) && checkMemberStatResponse?.status==1){
        let data = checkMemberStatResponse?.response;
        let account = UserUtil.getAccountAppState(checkMemberStatResponse, this.context);

        // CheckMemberStatからデータ取得
        let isTrial = data.isTrial;

        this.setState({
          account: account,
          data: data,
          myProfile: myProfile,
          isTrial: isTrial,
          alreadyCallApi: true
        });
      } 
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({ loading: false, isRefreshing: false });
    }
  }
  
  onRefresh = ()=>{
    this.setState({isRefreshing: true}, ()=>{
      this.getDataFromApi();
    });
  }

  renderMenuItem = (item,index) => (
    <TouchableOpacity 
      key={index}
      style={styles.menuItem}
      onPress={ () => {this._execAction(item.action, item.param);} }
      >
      <Text style={styles.menuItemText}>{item.title}</Text>
      <AntDesign name={'right'} style={styles.rightIcon} />
    </TouchableOpacity>
  );

  handleOnPressCardInfo = async()=>{
    if(Platform.OS==='web'){
      // javascript submit form
      let form = document.createElement('form');
      form.id = "form_change_card_info";
      form.method = "POST";
      form.action = this.state.data.telecom.changeCardInfoUrl;
      let element1 = document.createElement('input');
      let element2 = document.createElement('input');
      let element3 = document.createElement('input');

      element1.setAttribute("type", "hidden");
      element1.setAttribute("name", "clientip");
      element1.setAttribute("value", this.state.data.telecom.clientip);

      element2.setAttribute("type", "hidden");
      element2.setAttribute("name", "sendid");
      element2.setAttribute("value", this.state.data.subscription.sendid);

      element3.setAttribute("type", "hidden");
      element3.setAttribute("name", "redirect_url");
      element3.setAttribute("value", ApiConfig.pappy_list_url);


      form.appendChild(element1);
      form.appendChild(element2);
      form.appendChild(element3);
      if(this.state.data?.cardInfoModify==2){
        let element4 = document.createElement('input');
        element4.setAttribute("type", 'hidden');
        element4.setAttribute("name", 'ordertype');
        element4.setAttribute("value", this.state.data?.telecom?.ordertype);
        form.appendChild(element4);
      }
      document.body.appendChild(form);
      form.submit();
    }
  }

  render() {
    const SMSInvalid = ()=>{
      return (
        <View style={styles.blogItem}>
          <View style={styles.checkItem}>
            <Image style={styles.imgCheckItem} source={require('../assets/images/icon/check.png')}/>
          </View>
          <View style={styles.titleItem}>
            <Text style={styles.titleTxt}>電話番号認証</Text>
            <View style={styles.titleStatus}>
              <Text style={[styles.txtStatus, styles.txtStatusRed]}>未完了</Text>
            </View>              
          </View>
          <View>
            <Text style={styles.txtItemDesc}>ご利用の携帯番号があなたのものであることを{"\n"}電話番号認証を使用して確認します。</Text>
            <TouchableOpacity onPress={()=>{
              this.navigation.navigate('Auth', {screen:'SmsInput'});
            }}>
              <Text style={[styles.txtItemDesc, styles.txtCBlue]}>電話番号認証をする</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    }

    const SMSValid = ()=>{
      return (
      <View style={styles.blogItem}>
        <View style={styles.checkItem}>
          <Image style={styles.imgCheckItem} source={require('../assets/images/icon/checked.png')}/>
        </View>
        <View style={styles.titleItem}>
          <Text style={styles.titleTxt}>電話番号認証</Text>
          <View style={styles.titleStatus}>
          <Text style={styles.txtStatus}>完了</Text>
          </View>              
        </View>
        <View>
          <Text style={styles.txtItemDesc}>ご利用の携帯番号があなたのものであることを{"\n"}電話番号認証を使用して確認します。</Text>
        </View>
      </View>
      );
    }

    const AgeInvalid = ()=>{
      return (
        <View style={styles.blogItem}>
          <View style={styles.checkItem}>
            <Image style={styles.imgCheckItem} source={require('../assets/images/icon/check.png')}/>
          </View>
          <View style={styles.titleItem}>
            <Text style={styles.titleTxt}>18歳以上の確認</Text>
            <View style={styles.titleStatus}>
              <Text style={[styles.txtStatus, styles.txtStatusRed]}>未完了</Text>
            </View>              
          </View>
          <View>
            <Text style={styles.txtItemDesc}>あなたが18歳以上であることを運転免許証等の{"\n"}公的証明書を元に確認します。</Text>
            <TouchableOpacity onPress={()=>{
              // validate SMS
              if(!this.checkSMS()) return;

              this.navigation.navigate('Auth', {screen:'Identification'});
            }}>
              <Text style={[styles.txtItemDesc, styles.txtCBlue]}>18歳以上の確認をする</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    }
    const AgeValid = ()=>{
      return (
        <View style={styles.blogItem}>
          <View style={styles.checkItem}>
            <Image style={styles.imgCheckItem} source={require('../assets/images/icon/checked.png')}/>
          </View>
          <View style={styles.titleItem}>
            <Text style={styles.titleTxt}>18歳以上の確認</Text>
            <View style={styles.titleStatus}>
              <Text style={styles.txtStatus}>完了</Text>
            </View>              
          </View>
          <View>
            <Text style={styles.txtItemDesc}>あなたが18歳以上であることを運転免許証等の{"\n"}公的証明書を元に確認します。</Text>
          </View>
        </View>
      );
    }

    const PaidMemberInvalid = ()=>{
      return (
        <View style={styles.blogItem}>
          <View style={styles.checkItem}>
            <Image style={styles.imgCheckItem} source={require('../assets/images/icon/check.png')}/>
          </View>
          <View style={styles.titleItem}>
            <Text style={styles.titleTxt}>有料会員</Text>
            <View style={styles.titleStatus}>
            <Text style={[styles.txtStatus, styles.txtStatusRed]}>未加入</Text>
            </View>
          </View>
          <View>
            <Text style={styles.txtItemDesc}>有料会員は、お相手へのメッセージ、お相手が受け取ったいいね数が閲覧できます。</Text>
            <TouchableOpacity onPress={()=>{
              // validate SMS
              if(!this.checkSMS()) return;
              // validate Age
              if(!this.checkAge()) return;

              // if(Platform.OS == 'android'){
              //   this.navigation.navigate('Purchase_android');
              // }else{
                this.navigation.navigate('Purchase');
              // }
            }}>
              <Text style={[styles.txtItemDesc, styles.txtCBlue]}>詳しい情報をみる</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    }

    const PaidMember = ()=>{
      let expire = '';
      // if(this.state.data?.subscription?.expire){
      //   expire = moment.unix(this.state.data.subscription.expire).format('YYYY/MM/DD');
      // }
      if(this.state.data?.paymentDate){
        expire = moment.unix(this.state.data.paymentDate).format('YYYY/MM/DD');
      }
      let planTitle = "有料会員";
      let planInfo = "有料会員は、お相手へのメッセージ、お相手が受け取ったいいね数が閲覧できます。";
      if (this.state.isTrial==true) {
        planTitle = "お試しプラン会員";
        planInfo = "お試しプラン会員は、お相手へのメッセージ、お相手が受け取ったいいね数が閲覧できます。";
      }

      return (
        <View style={styles.blogItem}>
          <View style={styles.checkItem}>
            <Image style={styles.imgCheckItem} source={require('../assets/images/icon/checked.png')}/>
          </View>
          <View style={styles.titleItem}>
            <Text style={styles.titleTxt}>{planTitle}</Text>
            <View style={styles.titleStatus}>
              <Text style={styles.txtStatus}>加入済</Text>
            </View>              
          </View>
          <View>
            <Text style={styles.txtItemDesc}>{planInfo}</Text>
          </View>
          <View style={{flexDirection:'row', justifyContent:'center', alignContent:'center', marginTop:10,}}>
            <View style={{alignItems:'flex-start', flex:1,}}>
              <Text style={[styles.txtItemDesc, {lineHeight:13,}]}>次回有料会員更新日</Text>
            </View>
            <View style={{alignItems:'flex-end',}}>
              <Text style={[styles.txtItemDesc, {lineHeight:13,}]}>{expire}</Text>
            </View>
          </View>
        </View>
      );
    }

    const PrivilegeMember = ()=>{
      let privilegeEndDate = '';
      if(this.state.data?.privilegeEndDate){
        privilegeEndDate = moment.unix(this.state.data.privilegeEndDate).format('YYYY/MM/DD');
      }


      let privilegeAddDate = '';
      privilegeAddDate =  this.state.data?.privilegeAddDate
      return (
        <View style={styles.blogItem2}>
          <View style={styles.checkItem}>
            <Image style={styles.imgCheckItem} source={require('../assets/images/icon/checked.png')}/>
          </View>
          <View style={styles.titleItem}>
            <Text style={styles.titleTxt}>キャンペーン会員</Text>
            <View style={styles.titleStatus}>
              <Text style={styles.txtStatus}>加入済</Text>
            </View>              
          </View>
          <View>
            <Text style={styles.txtItemDesc}>キャンペーン会員は、お相手へのメッセージ送受信、{"\n"}お相手が受け取ったいいね数の閲覧が無料でできます。</Text>
          </View>
          {/* <View style={{flexDirection:'row', justifyContent:'center', alignContent:'center', marginTop:10,}}>
            <View style={{alignItems:'flex-start', flex:1,}}>
              <Text style={[styles.txtItemDesc, {lineHeight:12,}]}>無料キャンペーン特典終了日</Text>
            </View>
            <View style={{alignItems:'flex-end',}}>
              <Text style={[styles.txtItemDesc, {lineHeight:12,}]}>{privilegeEndDate}</Text>
            </View>
          </View>
          {privilegeAddDate ? (
          <View style={{flexDirection:'row', justifyContent:'center', alignContent:'center', marginTop:10,}}>
            <View style={{alignItems:'flex-start', flex:1,}}>
              <Text style={[styles.txtItemDesc, {lineHeight:12,}]}>次回{this.state.data?.privilegeAddGood}いいね+{this.state.data?.privilegeAddPoint}Pt付与日</Text>
            </View>
            <View style={{alignItems:'flex-end',}}>
              <Text style={[styles.txtItemDesc, {lineHeight:12,}]}>{privilegeAddDate}</Text>
            </View>
          </View>
          ):null} */}
          <View style={[styles.privilegeInfo]}>
            <Text style={styles.textPrivilegeInfo}>無料キャンペーン特典が終了すると自動で無料会員になるため、解約などの手続きは一切必要ございません。ご利用状況によって特典終了日が異なりますのでご了承ください。</Text>
          </View>
        </View>
      );
    }

    const PaidPlan = ()=>{
      let addDate = '';
      if(this.state.data?.addDate){
        addDate = moment.unix(this.state.data.addDate).format('YYYY/MM/DD');
      }
      
      return(
        <View>
          <Text style={[styles.titleTxt, {marginBottom:20,}]}>次のポイント付与</Text>
          <Text style={[styles.txtItemDesc, {marginBottom:10, lineHeight:12,}]}>有料会員</Text>
          <View style={{marginBottom:25, paddingTop:24, paddingBottom:24, paddingLeft:12, paddingRight:12, borderWidth:1, borderColor:'#e7ebec', borderRadius:10}}>
            <View style={{flexDirection:'row', marginBottom:15, paddingBottom:15,  borderBottomWidth:1, borderBottomColor:'#e7ebec',  }}>
              <View style={{flex:1, justifyContent:'flex-start'}}>
                <Text style={styles.text14}>付与日</Text>
              </View>
              <View style={{flex:1, justifyContent:'flex-end', flexDirection:'row', paddingRight:5,}}>
                <Text style={[styles.text14, styles.textBold, {fontSize:15,}]}>{addDate}</Text>
              </View>
            </View>
            <View style={{flexDirection:'row'}}>
              <View style={{flex:1, justifyContent:'flex-start'}}>
                <Text style={styles.text14}>付与数</Text>
              </View>
              <View style={{flex:1, justifyContent:'flex-end', flexDirection:'row', paddingRight:5,}}>
                <Image style={styles.imgTxtBlogPointBalance2} source={require('../assets/images/mypage_point.png')}/>
                <Text style={[styles.text14, styles.textBold, {fontSize:15,}]}>{this.state.data.addPoint}</Text>
              </View>
            </View>
            <View style={{marginTop:35, marginLeft:4, marginRight:4, paddingTop:24, paddingBottom:24, paddingLeft:10, paddingRight:10, backgroundColor:'#eef0f1',}}>
              <Text style={[styles.text14, styles.textBold, {color:'#00c1df'}]}>ポイントの付与日について</Text>
              <View style={{marginTop:20, }}>
                <Text style={styles.text11}>有料会員登録から1ヶ月ごとに、<Text style={styles.textBold}>{this.state.data.addPoint}ポイント</Text>付与</Text>
              </View>                
            </View>
          </View>
        </View>
      );
    }

    const About = ()=>{
      return(
        <>
        <View style={{marginBottom:25}}>
          <Text style={[styles.titleTxt, {marginBottom:20,}]}>次回更新日について</Text>
          <View style={{marginBottom:15, paddingLeft:15,}}>
            <Text style={styles.text11}>※クレジットカード決済・あと払い（ペイディ）で登録の場合{"\n"}
            ご登録日の翌日を1日目とし、360日目（12ヶ月プラン）{"\n"}
            180日目（6ヶ月プラン）、90日目（3ヶ月プラン）{"\n"}
            30日目（1ヶ月プラン）に自動更新されます。</Text>
          </View>
          <View style={{marginBottom:15, paddingLeft:15,}}>
            <Text style={styles.text11}>※Apple ID決済/Google Play決済で登録の場合{"\n"}
            ご登録日を起算日とし、12か月後（12ヶ月プラン）{"\n"}
            6か月後（6ヶ月プラン）、3か月後（3ヶ月プラン）{"\n"}
            1か月後（1ヶ月プラン）に自動更新されます。{"\n"}
            {/* 更新日の詳細は「次回更新日について」をご参照してください。</Text> */}
            自動更新は期限が切れる24時間以内に自動的に課金される仕組みです。{"\n"}
            自動更新をご希望ではない場合は期限が切れる24時間前までに解約を完了させてください。</Text>
          </View>
          {/* <View style={{marginBottom:15, paddingLeft:15,}}>
            <Text style={styles.text11}>※退会をご希望の場合、ご登録中に全て有料サービス{"\n"}の解約が必要となります。ご注意ください。</Text>
          </View> */}
        </View>
        </>
      );
    }

    const MenuCardInfo = ()=>{
      return (
        <TouchableOpacity style={styles.menuItem} onPress={()=>{
          this.handleOnPressCardInfo();
        }}>
        <Text style={styles.menuItemText}>クレジットカード情報変更	</Text>
        <AntDesign name={'right'} style={styles.rightIcon} />
      </TouchableOpacity>
      )
    }

    const MenuCancel = ()=>{
      return (
        <TouchableOpacity style={styles.menuItem} onPress={()=>{
          this.navigation.navigate('CancelSubscription');
        }}>
        <Text style={styles.menuItemText}>有料会員の解約</Text>
        <AntDesign name={'right'} style={styles.rightIcon} />
      </TouchableOpacity>
      )
    }

    // const ButtonPaidMember = ()=>{
    //   return(
    //     <View style={{}}>
    //       <TouchableOpacity style={styles.btnBtnSendMsg}>
    //         <Text style={styles.txtBtnSendMsg}>リストアする</Text>
    //       </TouchableOpacity>
    //       <Text style={styles.txtComment}>決済の内容がアプリに反映されない場合にリストアをお試しください。</Text>  
    //     </View>
    //   );
    // }

    if(this.state.loading) return <ActivityIndicator/>;

    return (
      <View style={[ListStyles.container,{ paddingBottom: HeaderOptions.bottomTabHeight}]}>
        <ScrollView style={[ListStyles.scrollContainer,{ height: scrollHeight}]} 
        refreshControl={
          <RefreshControl
            refreshing={this.state.isRefreshing}
            onRefresh={this.onRefresh}
          />
        }>
          <View style={styles.blogInner}>
            {this.state.data?.sex==Constants.FEMALE ? 
            <>
            {this.state.account?.activated==0?<SMSInvalid /> : <SMSValid/> }
            {this.state.account?.ageVerficationStatus!=2?<AgeInvalid /> : <AgeValid/> }
            </>
            :
            <>
            {this.state.account?.activated==0?<SMSInvalid /> : <SMSValid/> }
            {this.state.account?.ageVerficationStatus!=2?<AgeInvalid /> : <AgeValid/> }

            {this.state.data?.privilegeEndDate ?
              <PrivilegeMember/>
            :
              (this.state.data?.subscription?.status==0?
              <PaidMemberInvalid /> 
              : 
              <>
              <PaidMember/> 
              <View style={{marginTop:30}}>
                <PaidPlan/>
                <About/>
                <View style={{borderTopWidth:1, borderTopColor:'#e7ebec'}}>
                  {(Platform.OS=='web' && (this.state.data?.cardInfoModify==1 || this.state.data?.cardInfoModify==2)) && (this.state.data?.subscription?.purchasePlatform==1) && <MenuCardInfo/> }
                  {/* {(Platform.OS=='web' && (this.state.data?.subscription?.status==1 && this.state.data?.subscription?.purchasePlatform==1)) && <MenuCardInfo/> } */}
                  <MenuCancel/>
                </View>
                {/* {Platform.OS!='web' && <ButtonPaidMember/> } */}
              </View>
              </>
              )
            }
            </>
            }
          </View>
      </ScrollView>
    </View>
    );
  }
}

const styles = StyleSheet.create({
  blogInner: {
    padding:24,
  },
  blogItem: {
    position:'relative',
    marginBottom:8,
    paddingTop:24,
    paddingBottom:20,
    paddingLeft:30,
    paddingRight:20,
    width:'100%',
    height:136,
    backgroundColor:'#fff',
    borderWidth:1,
    borderColor:'#e7ebec',
    borderRadius:18, 
  },
  blogItem2: {
    position:'relative',
    marginBottom:8,
    paddingTop:24,
    paddingBottom:20,
    paddingLeft:30,
    paddingRight:20,
    width:'100%',
    height:'auto',
    backgroundColor:'#fff',
    borderWidth:1,
    borderColor:'#e7ebec',
    borderRadius:18, 
  },
  checkItem:{
    position:'absolute',
    left:-15,
    top:'50%',
    ...Platform.select({
      ios: { transform:[{translateY:10}] },
      android: { transform:[{translateY:10}] },
      default: { marginTop:-16, },
    }),
    zIndex:10,
  },
  imgCheckItem:{
    width:32,
    height:32,
  },
  titleItem:{
    flexDirection:'row',
    marginBottom:15,
  },
  titleTxt:{
    fontSize:15,
    fontWeight:'bold',
    color:'#484848',
  },
  titleStatus:{
    position:'absolute',
    right:0,
  },
  txtStatus:{
    fontSize:15,
    fontWeight:'bold',
    color:'#10be6a',
  },
  txtStatusRed:{
    color:'#d4464a',
  },
  txtItemDesc:{
    fontSize:11.5,
    fontWeight:'bold',
    lineHeight:19,
    color:'#484848',
  },
  txtCBlue:{
    marginTop:7,
    color:'#00c1df',
  },
  imgTxtBlogPointBalance2: {
    marginRight:4,
    width:18,
    height:18,
    alignSelf:'center',
  },
  menuItem:{
    flex:1, 
    flexDirection:'row',
    padding:16,
    paddingLeft:0,
    paddingRight:0,
    position:'relative',
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
  },
  menuItemText:{
    fontSize:14,
    lineHeight:20,
  },
  rightIcon:{
    position:'absolute',
    top:'50%',
    right:0,
    fontSize:16,
    color:'#e6e6e6',
    ...Platform.select({
      ios: { transform:[{translateY:8}] },
      android: { transform:[{translateY:8}] },
      default: { transform:[{translateY:-8}] },
    }),
  },
  btnBtnSendMsg:{
    marginTop:10,
    marginBottom:12,
    marginLeft:'auto',
    marginRight:'auto',
    padding:15,
    width:'90%',
    height:50,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnSendMsg:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:20,
    color:'#fff',
    textAlign:'center',
  },
  text14: {
    fontSize:14,
    fontWeight:'normal',
    lineHeight:19,
    color:'#484848',
  },
  text11: {
    fontSize:12,
    fontWeight:'normal',
    lineHeight:18,
    color:'#484848',
  },
  textBold: {
    fontWeight:'bold',
  },
  txtComment: {
    fontSize:10,
    fontWeight:'normal',
    lineHeight:16,
    color:'#9aa5a7',
    textAlign:'center',
  },
  privilegeInfo:{
    width: '100%',
    backgroundColor:'#f2f2f2',
    marginTop:12,
    padding:10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius:10,
    borderBottomRightRadius:10
  },
  textPrivilegeInfo:{
    fontSize:10.3,
    fontWeight:'normal',
    lineHeight:18,
    color:'#484848',
  }
});
