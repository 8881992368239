import React, {memo} from 'react';
import { View, Image, StyleSheet } from 'react-native';
import RenderSearchListPanelTpl1 from '../../components/common/RenderSearchListPanelTpl1';
import RenderSearchListPanelTpl2 from '../../components/common/RenderSearchListPanelTpl2';
import RenderSearchListPanelTpl3 from '../../components/common/RenderSearchListPanelTpl3';
import RenderSearchListPanelTplNone from '../../components/common/RenderSearchListPanelTplNone';

const RenderSearchListPanel = memo(({item, index, onPress, itemLayoutHeight, isMale, isRecommend}) => {

    if(item.tpl==0) {
      return (
        <View style={[{flex:1, width: '100%', height: itemLayoutHeight*3+4, minHeight: itemLayoutHeight*3+4, alignItems: 'center', justifyContent: 'center', marginBottom:2}]}>           
          <RenderSearchListPanelTpl1 index={index} item={item} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} type={0}/>
        </View>
      )
    } else if (item.tpl==1) {
      return (
        <View style={[{flex:1, width: '100%', height: itemLayoutHeight*2+2, minHeight: itemLayoutHeight*2+2, alignItems: 'center', justifyContent: 'center', marginBottom:2}]}>           
          <RenderSearchListPanelTpl2 index={index} item={item} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} type={0}/>
        </View>
      )
    } else if (item.tpl==2) {
      return (
        <View style={[{flex:1, width: '100%', height: itemLayoutHeight*2+2, minHeight: itemLayoutHeight*2+2, alignItems: 'center', justifyContent: 'center', marginBottom:2}]}>           
          <RenderSearchListPanelTpl2 index={index} item={item} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} type={1}/>
        </View>
      )
    } else if (item.tpl==3) {
      return (
        <View style={[{flex:1, width: '100%', height: itemLayoutHeight*3+4, minHeight: itemLayoutHeight*3+4, alignItems: 'center', justifyContent: 'center', marginBottom:2}]}>           
          <RenderSearchListPanelTpl3 index={index} item={item} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} type={0}/>
        </View>
      )
    } else if (item.tpl==4) {
      return (
        <View style={[{flex:1, width: '100%', height: itemLayoutHeight*3+4, minHeight: itemLayoutHeight*3+4, alignItems: 'center', justifyContent: 'center', marginBottom:2}]}>           
          <RenderSearchListPanelTpl3 index={index} item={item} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} type={1}/>
        </View>
      )
    } else if (item.tpl==5) {
      return (
        <View style={[{flex:1, width: '100%', height: itemLayoutHeight, minHeight: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginBottom:2}]}>           
          <RenderSearchListPanelTplNone index={index} item={item} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} type={1}/>
        </View>
      )
    }
});

const styles = StyleSheet.create({
  listItem:{
    flex:1, 
  },
  listItemMale:{
    flex:1, 
  },
});

export default RenderSearchListPanel;