import React, {memo} from 'react';
import { View, StyleSheet } from 'react-native';
// プロフ一覧レイアウト変更：個別のユーザ表示（最小表示用）
import RenderSearchListPanelSmall from '../../components/common/RenderSearchListPanelSmall';

// プロフィール一覧表示用テンプレート（ユーザが1〜3人の時）
const RenderSearchListPanelTplNone =  memo(({item, index, onPress, itemLayoutHeight, isMale, isRecommend, type}) => {
    var listIndex = item.listIndex;
    var item1 = [];
    item1.push(item[0],item[1],item[2]);

    return (
      <View style={[{flex:1, width:'100%', height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginLeft:2}]}>
        <RenderSearchListPanelSmall index={index} item={item1} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} listIndex={listIndex}/>
      </View>
    )
});

const styles = StyleSheet.create({
  listItem:{
    flex:1, 
  },
  listItemMale:{
    flex:1, 
  },
});

export default RenderSearchListPanelTplNone;