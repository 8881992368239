import React,{ Component } from 'react';
import { 
  View, FlatList, StyleSheet, Text, TouchableOpacity, Platform, 
  SectionList,
  Dimensions
} from 'react-native';
import { Entypo, AntDesign } from '@expo/vector-icons'; 
import Popup from '../../components/common/Popup';
import PortalStyle from '../../styles/PortalStyles';
import { AppContext } from '../../AppContext';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import ProfileSettingApi from '../../services/api/ProfileSetting12Api';
import CommonFunction from '../../utils/CommonFunction';
import Profile from '../../constants/option/Profile.json';
import UserUtil from '../../utils/UserUtil';
import PopupWrapper from '../../components/common/PopupWrapper';
import Msg from '../../constants/option/Msg.json';
import HeadStyle from '../../styles/HeadStyles';
import LogoNoMargin from '../../components/common/LogoNoMargin';
import HeaderOptions from '../../constants/HeaderOptions';
import MeetAreaHelpPopup from '../../components/common/MeetAreaHelpPopup';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../../styles/SafeAreaStyles';
const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight - 70;

// Multiple Selected
export default class MeetPlaceSelectListScreen extends Component { 
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.dataSource = [];
    this.values = [];  
    this.preflist = [];
    this.selectItems =[];
    this.viewSelectItems =[];
    this.MeetPlaces = Profile.Profile?.area;
 
    this.MeetPlaces.forEach((val,index)=>{
      let pref = [];
      pref.pref = val?.pref;
      pref.prefName = val?.prefname;
      this.preflist.push(pref);
    });


    this.state = {
      userProfile: {},
      loading: true,
      dataSource: this.dataSource, // 地域リスト用　データソース
      preflist: this.preflist,　// 都道府県選択　データソース
      selectPref: this.selectPref, // 選択中の都道府県
      selectItems: [...this.selectItems],　//送信用　選択データ
      viewSelectItems: this.viewSelectItems,　//表示用　選択データ
      defaultSelectItems: {}, //比較用　API取得の地域リスト
      disabled : false,
      isShowAllArea: false,
      selectPrefId : 0,
      isInRange: false
    }
    this.maxSelected = 5;

    this.MeetAreaHelpPopupRef = React.createRef();
  }

  componentDidMount(){
    this._isMounted = true;
    if (this._isMounted) {
      const { navigation } = this.props;
      this.navigation = navigation;
      CommonFunction.hideBottomTab(this.navigation);
      this.setHeader();
      this.getDataFromApi();
    }
  }


  setHeader = ()=>{
    this.navigation.setOptions({
      header: () => (
        <View style={[HeadStyle.customHeader]}>
          <View style={{flex:1,justifyContent:'flex-start',flexDirection:'row'}}>
            <TouchableOpacity onPress={() => {
              if(this.navigation?.canGoBack()){
                this.navigation.goBack();
              }
            }}>
              <Entypo name={'chevron-thin-left'} style={HeadStyle.icBack} />
            </TouchableOpacity>
            <LogoNoMargin/>
          </View>
          <View style={{flex:1, flexDirection:'row',width:'100%',justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center'}}>
            <Text style={HeadStyle.headerTitlePage} numberOfLines={1}>会える場所</Text>
          </View>
          <View style={{flex:1,height:'100%',justifyContent:'center',alignContent:'flex-end', alignItems:'flex-end', alignSelf:'center'}}>  
            {/* <TouchableOpacity onPress={()=>{
              // ヘルプポップアップ表示
              if(this.MeetAreaHelpPopupRef.show)
                this.MeetAreaHelpPopupRef?.show(); 
            }}>
              <View style={{flexDirection: "row", alignItems: "center", flex: 1,marginRight:3}}>
                <AntDesign name="questioncircle" style={[styles.questIc]}/> 
                <Text style={{fontSize:12,lineHeight: 16, fontWeight:'bold',alignContent:'center', color:'#484848' }}>会える場所とは</Text>
              </View>
            </TouchableOpacity> */}
          </View>
        </View>
      )
    });
  }

  getDataFromApi = async ()=>{
    if(!this.state.isRefreshing && !this.state.alreadyCallApi){
      this.setState({ loading: true });
    }

    try{
      // call Api
      const userProfileResponse = await ProfileSettingApi.ProfileGetCheck(this.context);
      
      //check login
      if(!CommonFunction.checkResponseStatus(userProfileResponse)) return;

      if(userProfileResponse!=undefined && userProfileResponse?.status==1){
        let userProfile = userProfileResponse?.userProfile;
        this.defaultSelectItems = [];
        // convert response from api to values in form
        if(Object.keys(userProfile).length > 0){

          let meetAreas = userProfile.meetArea.list;
          let tmpSelectItems = [];
          for(var i=0; i<meetAreas.length;i++){
            this.MeetPlaces.forEach((val,index)=>{
              let listData =val?.arealist;
              listData.forEach((data,index)=>{
                if (meetAreas[i].id == data.id) {
                  let tmp = [];
                  tmp.pref = val?.pref;
                  tmp.prefName = val?.prefname;
                  tmp.id = meetAreas[i].id;
                  tmp.value = meetAreas[i].label;
                  tmp.isSelect = true;
                  tmp.isLastSection = false;
                  tmp.sort = data.sort;
                  tmp.visibility = data.visibility;
                  if(tmp.visibility == 1){
                    tmpSelectItems.push(tmp); 
                    this.defaultSelectItems.push(tmp);
                  }
                }
              });
            });
          }
          if(tmpSelectItems.length > 0){
            this.selectItems = [...tmpSelectItems];
          }
          UserUtil.setAppStateUserProfile(this.context, userProfile);
          this.setState({
            selectItems:[...this.selectItems],
            userProfile: userProfile, 
            loading: false, 
            isShowAllArea: false,
            selectPrefId: userProfile.currentPref?.id
          });
          this.listRload(userProfile.currentPref?.id);
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      this.setState({loading: false, isRefreshing: false});
    }
  }



  listRload(selectData){
    let selectCrrentAreas = [];
    let data = '';
    this.selectPref = selectData;
    this.dataSource = [];
    if(this.MeetPlaces){
      this.MeetPlaces.forEach((val,index)=>{
        if(val?.pref == this.selectPref){
          this.selectPref = val?.pref;
          let listData =val?.arealist;
          listData.forEach((data,index)=>{
            let tmp = [];
            tmp.pref = val?.pref;
            tmp.prefName = val?.prefname;
            tmp.id = data.id;
            tmp.value = data.value;
            tmp.isSelect = false;
            tmp.isLastSection = false;
            tmp.sort = data.sort;
            tmp.visibility = data.visibility;
            if(tmp.visibility == 1){
              this.dataSource.push(tmp);
            }
              
          });
        }
      });
    }
    this.dataSource.sort(this.compareFuncSort)
    this.selectItems.sort(this.compareFunc);
    this.selectItems = this.compareFuncCrrent(this.selectItems);
    let dataSource = [];
    let tmpSelectItems = [];
    this.viewSelectItems = [...this.selectItems];
    this.viewSelectItems?.forEach((val,index)=>{
      if(val){
        let tmp = [];
        tmp.pref = val?.pref;
        tmp.prefName = val?.prefName;
        tmp.id = val?.id;
        tmp.value = val?.value;
        tmp.isSelect = val?.isSelect;
        tmp.isLastSection = false;
        tmp.sort = val?.sort;
        tmp.visibility = val?.visibility;
        if(dataSource.length == 0){
          tmp.isFirstSection = true;
        } else{
          tmp.isFirstSection = false;
        }
        if(tmp.id){
          if(tmp.pref == this.selectPref){
            tmpSelectItems.push(tmp);
          }else{
            dataSource.push(tmp);
          }
          if(selectData == tmp.pref){
            selectCrrentAreas.push(tmp)
          }
        }
          
      }
    });

    if(tmpSelectItems && tmpSelectItems.length > 0){
      tmpSelectItems?.forEach((val,index)=>{
        if(val){
          let tmp = [];
          tmp.pref = val?.pref;
          tmp.prefName = val?.prefName;
          tmp.id = val?.id;
          tmp.value = val?.value;
          tmp.isSelect = val?.isSelect;
          tmp.isLastSection = true;
          tmp.sort = val?.sort;
          tmp.visibility = val?.visibility;
          if(dataSource.length == 0){
            tmp.isFirstSection = true;
          } else{
            tmp.isFirstSection = false;
          }
          dataSource.push(tmp);
        }
      });
    }

    let length = this.state.isShowAllArea?this.dataSource?.length : 8;
    
    let selLength = this.state.isShowAllArea? 0 : selectCrrentAreas.length;

    let addCnt = 0;
    const isInRange = (this.dataSource?.length <= 8);
    this.setState({isInRange: isInRange});

    for(let i = 0; i < length; i++ ){
      let val = this.dataSource[i];
      if(val){
        const checkIndex = this.selectItems?.findIndex(
          item => val.id === item.id
        );
        if(checkIndex == -1){
          let tmp = [];
          tmp.pref = val?.pref;
          tmp.prefName = val?.prefName;
          tmp.id = val?.id;
          tmp.value = val?.value;
          tmp.isSelect = false;
          tmp.isLastSection = false;
          tmp.sort = val?.sort;
          tmp.visibility = val?.visibility;
          if(!tmpSelectItems || tmpSelectItems.length == 0)
            tmp.isLastSection = true;
          if(dataSource.length == 0){
            tmp.isFirstSection = true;
          } else{
            tmp.isFirstSection = false;
          }
          if(tmp.id){
            if(this.state.isShowAllArea){
              dataSource.push(tmp);
            }else{
              if(addCnt < length - selLength){
                dataSource.push(tmp);
                addCnt++;
              }
            }
          }
        }
      }
    }
    this.setState({dataSource : dataSource,
      selectPref : this.selectPref,
      selectItems: this.selectItems
    });
  }

  checkMaxSelected = () =>{
    return new Promise(resolve => {
      let selectList = this.state.selectItems?.filter(function (item) {
        return item.isSelect == true;
      });
      
      resolve(selectList.length);
    });
  }

  selectItem = (data) => {
    let selectCrrentAreas = [];
    const checkItemsIndex = this.selectItems?.findIndex(
      item => data.item.id === item.id
    );
    if(checkItemsIndex ==-1){
      data.item.isSelect = true;
      this.selectItems.push(data.item);     
    }else{
      data.item.isSelect = !data.item.isSelect;
      this.selectItems.splice(checkItemsIndex, 1);
    }
      let dataSource = [];
      let tmpSelectItems = [];
      this.selectItems.sort(this.compareFunc);
      this.selectItems= this.compareFuncCrrent(this.selectItems);

      this.setState({ selectItems:  [...this.selectItems], disabled: this.checkData([...this.selectItems])});

      this.viewSelectItems = [...this.selectItems];
      this.viewSelectItems?.forEach((val,index)=>{
        if(val){
          let tmp = [];
          tmp.pref = val?.pref;
          tmp.prefName = val?.prefName;
          tmp.id = val?.id;
          tmp.value = val?.value;
          tmp.isSelect = val?.isSelect;
          if(dataSource.length == 0){
            tmp.isFirstSection = true;
          } else{
            tmp.isFirstSection = false;
          }
          tmp.isLastSection = false;
          if(tmp.pref == this.selectPref){
            tmpSelectItems.push(tmp);
          }else{
            dataSource.push(tmp);
          }
          if(this.state?.selectPrefId == tmp.pref){
            selectCrrentAreas.push(tmp);
          }
        }
      });

      let tmpCurrentSelectItems = [];
      if(tmpSelectItems && tmpSelectItems.length > 0){
        tmpSelectItems?.forEach((val,index)=>{
          if(val){
            let tmp = [];
            tmp.pref = val?.pref;
            tmp.prefName = val?.prefName;
            tmp.id = val?.id;
            tmp.value = val?.value;
            tmp.isSelect = val?.isSelect;
            tmp.isLastSection = true;
            if(dataSource.length == 0){
              tmp.isFirstSection = true;
            } else{
              tmp.isFirstSection = false;
            }
            dataSource.push(tmp);
            if(tmp.pref == this.state?.selectPrefId){
              tmpCurrentSelectItems.push(tmp);
            }
          }
        });
      }

      let length = this.state.isShowAllArea?this.dataSource?.length : 8;

      let selLength = selectCrrentAreas.length

      const isInRange = (this.dataSource?.length <= 8);
      this.setState({isInRange: isInRange});
      
      let addCnt = 0;

      for(let i = 0; i < this.dataSource?.length; i++ ){
        let val = this.dataSource[i];
        if(val){
          const checkIndex = this.selectItems?.findIndex(
            item => val.id === item.id
          );
          if(checkIndex == -1){

            let tmp = [];
            tmp.pref = val?.pref;
            tmp.prefName = val?.prefName;
            tmp.id = val?.id;
            tmp.value = val?.value;
            tmp.isSelect = false;
            tmp.isLastSection = false;
            if(!tmpSelectItems || tmpSelectItems.length == 0)
              tmp.isLastSection = true;
            if(dataSource.length == 0){
              tmp.isFirstSection = true;
            } else{
              tmp.isFirstSection = false;
            }
            if(tmp.id){
              if(this.state.isShowAllArea){
                dataSource.push(tmp);
              }else{
                if(selectCrrentAreas.length < 1){
                  if(i < length)
                    dataSource.push(tmp);
                } else{
                  if(addCnt < (length - selLength)){
                    dataSource.push(tmp);
                    addCnt++;
                  }
                }
              }
            }
              
          }
        }
      }
      this.setState({
        dataSource: dataSource,
        selectItems: this.selectItems
      });
  };

  checkData = (sel)=>{
    let def = [...this.defaultSelectItems];
    let defString = '';
    for(var i=0; i<def.length;i++){
      if(defString == ''){
        defString += def[i].id;
      } else{
        defString += '-' + def[i].id;
      }
    }
    let selString = '';
    for(var i=0; i<sel.length;i++){
      if(selString == ''){
        selString += sel[i].id;
      } else{
        selString += '-' + sel[i].id;
      }
      
    }

    if(selString == defString){
      return false;
    }
    return true;
  }

  compareFunc(a, b) {
    a = a.pref;
    b = b.pref;
    return a - b;
  }

  compareFuncSort(a, b) {
    a = a.sort;
    b = b.sort;
    return a - b;
  }

  compareFuncId(a, b) {
    a = a.id;
    b = b.id;
    return a - b;
  }

  compareFuncCrrent(list) {
    let tmpList =[];
    let compList =[];
    list?.forEach((val,index)=>{
      if(val.pref == this.state?.userProfile?.currentPref?.id){
        compList.push(val);
      }else{
        tmpList.push(val);

      }
    });
    if(tmpList && tmpList.length > 0){
      tmpList?.forEach((val,index)=>{
        compList.push(val);
      });
    }
 
    return compList;
  }

  handleSubmit = async () => {
    try{
      CommonFunction.overLay();
      let postValues = {};

      let values = this.state.selectItems;
      let array = [];
      values.forEach(function(val) {
        array.push(val.id);
      });
      postValues.meetArea = (array.length>0) ? array.join('\t') : 0;
      // call Api
      const responseProfilePutProfile =  await ProfileSettingApi.ProfilePutProfile(postValues);
      const success = responseProfilePutProfile?.status;
      if(success==1){
        PopupWrapper.showMessage(Msg.Update.Success);
        setTimeout(() => {
          if(this.navigation?.canGoBack()){
            this.navigation.goBack(); 
          }
        }, 1000);
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }finally{
      CommonFunction.hideOverlay();
    }
  }

  handleCheck(id) {
    let $return = false;
    if(this.state.selectItems){
      const checkIndex = this.state.selectItems.findIndex(
        item => id === item.id
      );
      if(checkIndex >=0){
        $return = this.state.selectItems[checkIndex].isSelect;
      }
    }
    return $return;
  }

  makeSectionData = (data) => {
    let sectionList = [];
    if(data!=undefined && data?.length>0){
      data.map((chatData) => {      
        let title =chatData.prefName
        const checkIndex = sectionList?.findIndex(
          item => title === item.title
        );
        if(checkIndex==-1){
          let value = {title: title, data: [chatData]}
          if(chatData.isLastSection){
            value.isLastSection = true;
          } else{
            value.isLastSection = false;
          }
          if(chatData.isFirstSection){
            value.isFirstSection = true;
          } else{
            value.isFirstSection = false;
          }
          sectionList.push(value);
        }else{
          sectionList[checkIndex].data.push(chatData);
        }
      });
    }

    return sectionList;
  }

  renderItem = (data) => {
    let selected = this.handleCheck(data.item.id);
    let value = data.item.value;

    return (
      <View style={[styles.sectionInside, selected?styles.selectedList:styles.notSelectedList]}>
        <TouchableOpacity onPress={()=>{            
          if(selected){
            this.selectItem(data);
          }else{
            if(this.maxSelected>0 && data.item.id!=0){
              this.checkMaxSelected().then((response)=>{
                if(response < this.maxSelected){
                  this.selectItem(data);
                }else{
                  // over limit
                  this.popup?.showPopup();
                }
              }, this);
            }else{
              this.selectItem(data);
            }
          }
          }} style={[styles.sectionInsideBorder, {flexDirection:'row', width:'100%'}]}>
          <View style={styles.formLabel}>
            <Text style={styles.label}>{value}</Text>
          </View>
          <View style={styles.formSelected}>
          {selected?
            <View style={styles.circleSelectCheck}><Entypo name="check" style={styles.icSelectCheck}/></View>
          :
            null
          }
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  renderPrefItem = (data) => {
    return (
      <TouchableOpacity style={{borderBottomWidth: 1, borderBottomColor: '#F3F3F3'}}onPress={()=>{
        this.setState({
          isShowAllArea: false,
          selectPrefId: data?.item.pref 
        });
        this.popupPref?.hidePopup();
        setTimeout(() => {
          this.listRload(data?.item.pref);
        });
      }} >
        <Text style={styles.popupItem}>{data.item.prefName}</Text>
      </TouchableOpacity>
      
    );
  }

  renderFooter(){
    if(!this.state?.isShowAllArea && !this.state?.isInRange){
      return (
        <View style={[styles.sectionInside, {marginTop: 10}]}>
          <TouchableOpacity style={[{width:'100%',padding:2,height:55,alignSelf:'center',alignItems:"center",justifyContent:"center",letterSpacing:2,}]} onPress={()=>{
            this.setState({
              isShowAllArea: true
            });
            setTimeout(() => {
              this.listRload(this.state.selectPrefId);
            });
          }}>
            <Text style={styles.label}>さらに表示する</Text>
          </TouchableOpacity>
        </View>
      )
    } else {
      return null;
    }

  };

  render() {
    if(this.state.loading) return <ActivityIndicator />

    const insets = this.context?.appState?.insets;
    return (
      <SafeAreaInsetsContext.Consumer>
        {(insets) =>
        <View style={[styles.container,,SafeAreaStyles(insets).container]}>
          <MeetAreaHelpPopup ref={ref=>this.MeetAreaHelpPopupRef=ref} />
          <View style={Platform.OS!=='web' ?{height:'100%'}: {}}>
            <SectionList
              style={[Platform.OS==='web' ?{height:scrollHeight, backgroundColor:'#EEF1F2'}: {backgroundColor:'#EEF1F2'}]}
              contentContainerStyle={Platform.OS!=='web'?{flexGrow:1}:{}}
              data={this.state.dataSource}
              extraData={this.state.dataSource}
              renderItem={item => this.renderItem(item)}
              ref={(ref)=>this.scrollRef=ref}
              onScroll={(event) => {
                this.scrollY = event.nativeEvent.contentOffset.y;
              }}
              renderSectionHeader={({section}) => {
                if(section.data?.length>0){
                  return (
                    <View style={{width: '100%', height:55, backgroundColor: '#EEF1F2', justifyContent:'center'}}>
                      {section.isFirstSection?
                        <Text style={{fontSize: 12,position: 'absolute', right:5,top: 5}}>※5つまで複数選択可能</Text>
                      :
                        null
                      }
                      
                      <Text style={[styles.sectionHeader,{position: 'absolute', left:0,bottom: 0}]}>{section.title}</Text>
                      {section.isLastSection?
                        <TouchableOpacity style={[styles.btnPreview,{position: 'absolute', right:5,bottom: 5}]} onPress={()=>this.popupPref?.showPopup()} >
                          <Text style={{fontSize:14, fontWeight:'bold', color:'#32bedb', textAlign:'center'}}>都道府県を変更する</Text>
                        </TouchableOpacity>
                      :
                        null
                      }
                    </View>
                  )
                }
              }}
              sections={this.makeSectionData(this.state.dataSource)}
              keyExtractor={(item, index) => item.toString()+index}
              initialNumToRender={this.state.dataSource?.length}
              ListFooterComponent={this.renderFooter()}
            />
            <View style={styles.footer}>
              <TouchableOpacity disabled={!this.state.disabled} style={[styles.submitBtn, {opacity:(this.state.disabled) ?1:0.4}]} onPress={()=>this.handleSubmit()}>
                <Text style={styles.loginText}>保存する</Text>
              </TouchableOpacity>
            </View>
          </View>
          <Popup ref={ref=>this.popup=ref} modal={true}>
            <View style={PortalStyle.overlay} 
              onStartShouldSetResponder={() => {
            }} />
            <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'65%', backgroundColor:'#fefefe', borderRadius: 12,}}>
              <Text style={{fontSize:14, fontWeight:'normal', color:'#484848', lineHeight:24,}}>地域は{this.maxSelected}つまで選択可能です</Text>
              <TouchableOpacity onPress={()=>this.popup?.hidePopup()} style={[styles.btnGray, {marginTop:20,}]}>
                <Text style={styles.txtBtnGray}>OK</Text>
              </TouchableOpacity>
            </View>
          </Popup>

          <Popup ref={ref=>this.popup2=ref} modal={true}>
            <View style={PortalStyle.overlay} 
              onStartShouldSetResponder={() => {
             
            }} />
            <View style={{justifyContent:'center', alignItems:'center', alignSelf:'center', alignContent:'center', padding: 20, width:'65%', backgroundColor:'#fefefe', borderRadius: 12,}}>
              <Text style={{fontSize:14, fontWeight:'normal', color:'#484848', lineHeight:24,}}>地域は1つ以上選択してください</Text>
              <TouchableOpacity onPress={()=>this.popup2?.hidePopup()} style={[styles.btnGray, {marginTop:20,}]}>
                <Text style={styles.txtBtnGray}>OK</Text>
              </TouchableOpacity>
            </View>
          </Popup>
          <Popup ref={ref=>this.popupPref=ref} modal={true}>
            <View style={PortalStyle.overlay} 
              onStartShouldSetResponder={() => {
                this.popupPref?.hidePopup();
            }} />
            <View style={{justifyContent:'center', alignSelf:'center', alignContent:'center', padding: 0, height: '65%', width:'80%', backgroundColor:'#fefefe', borderRadius: 12,}}>
              <FlatList
                data={this.state.preflist}
                renderItem={item => this.renderPrefItem(item)}
                keyExtractor={item => item.pref}
                initialNumToRender={this.state.preflist?.length}
              />
              <View style={{width: '100%',backgroundColor:'#fff', height: 60, justifyContent: 'center', alignItems: 'center', paddingBottom: 5, paddingTop: 5,borderRadius: 12}}>
                <TouchableOpacity style={styles.btnCancel} onPress={()=>this.popupPref?.hidePopup()}>
                  <Text style={styles.txtBtnCancel}>閉じる</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Popup>
        </View>
      }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#ffffff',
    flex:1,
    width:'100%',
    ...Platform.select({
      ios: {},
      android: {},
      web:{
        marginTop: HeaderOptions.headerTopMenuHeight
    }})
  },
  section:{
    marginTop: 20,
  },
  sectionInside:{
    backgroundColor:'#fff',    
    flexDirection:'row', 
    alignSelf:'stretch',
    height:50,
    borderBottomWidth:.55,
    borderBottomColor:'#e6e6e6',
    ...Platform.select({
      android:{
        borderBottomWidth:.7
      }
    })
  },
  sectionInsideBorder:{
    marginLeft:16,
    paddingRight:16,
  },
  formLabel:{
    flex: 1,  
    alignSelf:'stretch', 
    alignItems:'flex-start',   
  },
  formSelected:{
    alignItems:'flex-end',
    justifyContent:'center',
    maxWidth:190,
    fontSize:14,
    lineHeight:19,
    color:'#5cc8ca',
    marginRight:16
  },
  label:{
    fontSize: 14,
    lineHeight: 19,
    marginTop: 16,
    marginRight: 8,
    marginBottom: 16,
    color:'#484848'
  },
  checkIcon:{
      width:20,
      height:20
  },
  selectedList:{
    backgroundColor:'#fff'
  },
  notSelectedList:{
    backgroundColor:'#fff'
  },
  circleSelectCheck: {
    display:'flex',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    width:21,
    height:21,
    backgroundColor:'#5cc8ca',
    borderRadius:9999,
  },
  icSelectCheck: {
    fontSize:14, 
    color:'#fff'
  },
  btnGray:{
    paddingLeft:8,
    paddingRight:8,
    width:'70%',
    height:30,
    backgroundColor:'#ebebeb',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnGray:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:30,
    color:'#666666',
    textAlign:'center'
  },
  sectionHeader:{
    paddingTop:5,
    paddingBottom:5,
    paddingLeft:12,
    paddingRight:12,
    backgroundColor: '#EEF1F2',
    fontSize:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#484848',
    textAlign: 'right' 
  },

  popupItem:{
    fontSize:16,
    paddingTop:10,
    paddingBottom:10,
    paddingLeft:12,
    paddingRight:12,
    color:'#505050'
  },

  btnPreview:{
    right:5, 
    paddingLeft:7, 
    paddingRight:7, 
    width: '40%',
    height: 23,
    justifyContent:'center',
    backgroundColor:'#fff', 
    borderWidth:1, 
    borderColor: '#32bedb',
    borderRadius:999,
    ...Platform.select({
      android:{
        paddingBottom:3,
      }
    })
  },
  submitBtn:{
    width:"80%",
    margin: 0,
    backgroundColor:"#5cc8ca",
    borderRadius:25,
    height:45,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  loginText:{
    color:"white",
    fontWeight:"bold",
    fontSize: 16
  },
  btnCancel:{
    width:'45%',
    padding:2,
    height:30,
    marginTop:15,
    marginBottom:15,
    alignSelf:'center', 
    backgroundColor:'#f2f2f2',
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
    borderRadius:999,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#323232',
    textAlign:'center',
  },
  footer:{

    position:'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor:'#EEF1F2',
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 15, 
    paddingTop: 10
  },
  questIc:{ 
    alignSelf:'center',
    marginRight:2, 
    height: 16,
    fontSize:15,
    textAlign: 'center',
    lineHeight:15,
    color:'#484848',
  },
});
