import { StyleSheet, Text, View, Image, TouchableOpacity, Platform} from 'react-native';
import Moment from 'moment';
import Constants from '../../constants/Constants';
import OnlineStatus from '../common/OnlineStatus';
import FavoriteStatus from '../common/FavoriteStatus';

export default function ChatUserRowFormat(props) {
  const {item} = props;
  let onlineId = 0;
  let favorite = 0;
  if(item.online!=undefined) onlineId = item.online?.id;
  if(item.favorite!=undefined) favorite = item.favorite;

  let memoVis = false;
  let text = '';
  if (item.memo.length>0) {
    memoVis = true;
    text = item.memo.replaceAll("\n"," ");
  }

  return (
    <View style={styles.containerBase}>

      <View style={styles.profileContainer}>
        <TouchableOpacity onPress={()=>{
          if(item.leaveFlag != 0){
            return;
          }
          if(props.checkSMS!=undefined){
            if(!props.checkSMS()) return;
          }
          
          if(props.setViewIndex!=undefined){
            props.setViewIndex(props.index);
          }
          if(props.setParentState!=undefined){
            props.setParentState();
          }
          props.navigation?.navigate('Profile',{memberId:item.memberId, imageUrl:item.mainImageUrl, bottomTab:1});
          }}>
          <Image style={styles.myProfileThumbnail} source={{uri : item.tmMainImageUrl}} />
        </TouchableOpacity>
        <View style={styles.rightProfile}>
          <View style={styles.rightRow}>
            {item?.replyStat==0 ? 
            <View style={styles.notReply}><Text style={styles.txtNotReply}>未返信</Text></View>
            :<View style={{height:4}}></View>}
            {onlineId==Constants.onlineStatus.online?
            <View style={styles.talk}><Text style={styles.txtTalk}>今話せるかも</Text></View>
            :<View style={{height:4}}></View>}
            <Text style={styles.showTime}>{Moment(item.receiveDate).format('MM/DD HH:mm')}</Text>
          </View>
          
          <View style={[styles.rightRow, {marginTop:4}]}>
            <View style={{alignSelf:'center'}}><OnlineStatus online={onlineId} /></View>
            <Text style={styles.profileDesc} numberOfLines={1}>{item.nickName} {item.age} {item.currentPref}</Text>
            <View style={{alignSelf:'center'}}><FavoriteStatus favorite={favorite} /></View>
          </View> 
                
          <Text numberOfLines={1} style={styles.msg}>{item.msgText}</Text>
        </View>
        {item?.unreadCount>0 && (<View style={styles.badge}><Text style={styles.txtBadge}>{item.unreadCount}</Text></View>)}
      </View>
      {memoVis?
      <View style={[styles.memoContainer, {height: 30}]}>
        <View style={styles.flexRowCenter}>
          <View>
            <Image style={styles.icMemo} source={require('../../assets/images/icon/memo-icon.png')}/>
          </View>
          <View style={styles.memTextArea}>
            <Text numberOfLines={1} style={styles.memoText}>{text}</Text>
          </View>
        </View>
      </View>    
      :null}
    </View>
  );
}

const styles = StyleSheet.create({
  containerBase: {
    flexDirection: 'column',
    backgroundColor:'#ffffff',
    borderBottomColor:'#e6e6e6',
    borderBottomWidth:1,
  },
  memoContainer: {
    flexDirection:'row',
    paddingRight:15,
    paddingBottom:10,
    paddingLeft:15, 
    width:'100%',
    alignSelf:'center',
    alignItems:'center',
    alignContent:'center',
    backgroundColor:'#fff',
    elevation:1,
    zIndex:1,
  },
  flexRowCenter:{
    width:'100%',
    height:26,
    marginBottom:8,
    flexDirection:'row',
    alignItems:'center',
    backgroundColor:'#f2f2f2',
  },
  icMemo:{
    width:20, 
    height:18,
    marginLeft:5,
    marginBottom:0,
  },
  memoText:{
    marginBottom:5,
    fontSize:12,
    lineHeight:24,
    color:"#333",
    textAlign:'left',
  },
  profileContainer: {
    flexDirection:'row',
    flex:1,
    paddingTop:12,
    paddingRight:16,
    paddingBottom:13,
    paddingLeft:16,
    // backgroundColor:'#ffffff',
    // borderBottomColor:'#e6e6e6',
    // borderBottomWidth:1,
  },
  myProfileThumbnail:{
    marginRight:12,
    width:75,
    height:75,
    borderRadius:999,
  },
  rightProfile:{
    flex:1,
    marginTop:6,
  },
  rightRow: {
    position:'relative',
    width:'100%',
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  },
  talk: {
    justifyContent:'flex-start',
    paddingRight:8,
    paddingLeft:8,
    backgroundColor:'#23db24',
    borderRadius:60,
  },
  txtTalk: {
    color:'#fff',
    fontSize:12,
    lineHeight:19
  },
  showTime: {
    position:'absolute',
    top:-5,
    right:0,
    fontSize:12,
    lineHeight:14,
    color:'#9aa5a7',
    textAlign:'right',
  },  
  online_time:{
    fontSize: 11,
    textAlign: 'left',
    paddingTop: 5,
    marginLeft: 10,
  },
  msg: {
    marginTop:2,
    fontSize:12,
    fontWeight:'normal',
    color:'#9aa5a7',
    lineHeight:19
  },
  profileDesc: {
    fontWeight:'bold',
    lineHeight:22,
    color:'#484848',
  },
  badge: {
    position:'absolute',
    top:34,
    right:16,
    width:24,
    height:24,
    backgroundColor:'#ff8d63',
    borderRadius:999,
  },
  txtBadge: {
    color:'#fff',
    fontSize:15,
    fontWeight:'normal',
    lineHeight:24,
    textAlign:'center'
  },
  notReply: {
    justifyContent:'flex-start',
    marginRight:4,
    paddingRight:8,
    paddingLeft:8,
    backgroundColor:'#ff5eb5',
    borderRadius:60,
  },
  txtNotReply: {
    color:'#fff',
    fontSize:12,
    lineHeight:19,
  },
  memTextArea: {
    flex:1,
    paddingLeft:10,
    paddingRight:10,
    ...Platform.select({
      web:{
        paddingTop:4,
      }
    })
  }
});
