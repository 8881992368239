import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet, Dimensions, ImageBackground } from 'react-native';
import styles from '../../styles/FormStyles';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import { AppContext } from '../../AppContext';
import Constants from '../../constants/Constants';
import StorageHelper2 from '../../utils/StorageHelper2';
import * as RootNavigation from '../../navigation/RootNavigation';

const windowWidth = Dimensions.get('window').width; 

export default class SMSVerify2 extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      sex: Constants.FEMALE
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
    this.navigation = this.props.navigation;
    if(this.navigation==undefined) this.navigation = RootNavigation;

      let sex = await this.getSex();
      this.setState({ sex: sex});
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }
  
  getSex = async()=>{
    const { appState } = this.context;
    let userProfile = appState?.userProfile;
    if(!userProfile || userProfile==undefined){
      userProfile = await StorageHelper2.getObjectData('userProfile');
    }
    return userProfile?.sex;
  }

  show = async(navigation, redirect=true)=>{
    if(navigation!=undefined)
      this.navigation = navigation;

    if(redirect!=undefined){
      this.redirect = redirect;
    }else{
      this.redirect = true;
    }

    let sex = await this.getSex();
    this.setState({ show: true, sex: sex});
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  isShow = ()=>{
    return this.state.show;
  }

  render() {
    if(!this.state.show) return null;

    const imageSource = this.state?.sex==Constants.MALE?require('../../assets/images/bg-sms-popup-m.png'):require('../../assets/images/bg-sms-popup-w.png');
    const popupWidth = windowWidth * 0.8
    const newHeight = popupWidth * 707 / 866

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} 
          onStartShouldSetResponder={() => {
            //this.hide();
          }} />
          <View style={PortalStyle.blocPopup}>
            <View style={[PortalStyle.blocPopupInner, {paddingTop:15, paddingRight:0, paddingBottom:15, paddingLeft:0, width:'80%',}]}>
              <View style={styles.popupInside}>
                <View style={{flexDirection:'column', justifyContent:'center', alignItems:'flex-start', marginBottom:10, width:'100%',}}>
                  <ImageBackground resizeMode={'cover'} 
                    style={{width: popupWidth, height:newHeight}} 
                    imageStyle={{borderTopLeftRadius:15, borderTopRightRadius:15}} 
                    source={imageSource}
                  >
                  </ImageBackground>
                </View>
                <View style={{flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center', marginBottom:5, width:'100%',}}>
                  <Text style={{fontSize:windowWidth<375? 10:13, fontWeight:'normal', lineHeight:windowWidth<375? 20:24, color:'#484848'}}>＼ かんたん10秒で認証完了 ／</Text>
                  </View>
                <View style={{flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', alignSelf:'center', width:'100%',}}>
                  <TouchableOpacity style={inlineStyles.modalButtonBlue} onPress={()=>{ 
                    this.hide();
                    this.navigation.navigate('Auth',{ screen:'SmsInput'});
                  }}>
                    <Text style={inlineStyles.textModalButtonBlue}>電話番号認証をする</Text>
                    
                    <Text style={[inlineStyles.txtBtnBlue2, {position: 'absolute',right:5}]}>{'>'}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={inlineStyles.btnCancel} onPress={()=>{ 
                    this.hide();
                  }}>
                    <Text style={inlineStyles.txtBtnCancel}>閉じる</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Portal>
    );
  }
}

const inlineStyles = StyleSheet.create({
  modalButtonBlue: {
    padding:8,
    width:'70%',
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  textModalButtonBlue: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center',
  },
  modalButtonWhite: {
    marginTop:10,
    padding:8,
    width:'70%',
    height:42,
    justifyContent:"center",
    alignContent:'center',
    alignItems:"center",
  },
  textModalButtonWhite: {
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#98a3a5',
    textAlign:'center',
  },
  itemCheck:{
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    width:'100%',
  },
  icCheck:{
    marginTop:windowWidth<375? 4:5,
    marginLeft:2,
    marginRight:5,
    width:windowWidth<375? 9:10,
    height:windowWidth<375? 9:10,
  },
  txtCheck:{
    fontSize:windowWidth<375? 10:13,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 18:20,
    color:'#484848',
  },
  btnCancel:{
    width:'50%',
    marginTop:10,
    padding:8,
    height:35,
    alignItems:"center",
    justifyContent:"center",
    backgroundColor:'#f2f2f2',
    borderRadius:999,
    letterSpacing:2,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:16,
    color:'#383838',
    textAlign:'center',
  },
  txtBtnBlue:{
    fontSize:15,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center'
  },
  txtBtnBlue2:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'right'
  },
});