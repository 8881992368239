import React, { Component } from "react";
import { 
  StyleSheet, Text, View, TouchableOpacity, Image, Dimensions, Platform, StatusBar, ScrollView 
} from "react-native";
import SwipeCards from "react-native-swipe-cards-deck";
import { Card } from '../../components/data/UserCardFormat';
import { AppContext } from '../../AppContext';
import ActivityIndicator from "../../components/common/ActivityIndicator";
import NoItemsBox from '../../components/common/NoItemsBox';
import { RefreshControl } from 'react-native-web-refresh-control';
import UserUtil from "../../utils/UserUtil";
import IineApi from '../../services/api/Iine10Api';
import Constants from "../../constants/Constants";
import PopupWrapper from "../../components/common/PopupWrapper";
import UserVerify from "../../utils/UserVerify";
import CommonFunction from "../../utils/CommonFunction";
import Badge14Api from "../../services/api/Badge14Api";
import MatchComplete from "../../components/common/MatchComplete";
import HeaderOptions from "../../constants/HeaderOptions";

const screenWidth = Dimensions.get('window').width;
const thumbnailHeight = screenWidth;
const containerHeight = thumbnailHeight;
let swipeCardHeight = containerHeight;
const windowHeight = Dimensions.get('window').height;
if(windowHeight <= 570) {
  swipeCardHeight = swipeCardHeight - 60;
}else{
  swipeCardHeight = swipeCardHeight - 20;
}
const swipeCardMarginBottom = 30;
const cardContainerHeight = 100;

let tabBarHeight = 37;
const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight - HeaderOptions.bottomTabHeight - tabBarHeight;
let scrollmini = false;
if (scrollmini < 455) {
  scrollmini = true;
}

export default class OtherIined extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor() {
    super();

    this.page = 1;
    this.nextVal = 0;
    this.swipeCardRef = React.createRef();
    this.MatchCompleteRef = React.createRef();
    this.matchKeyname = 'matchComplete';
    this.countMatchComplete = Constants.countMatchComplete;
    this.matchList = [];
    this.iineType = 0; //0 = from other iine, 1 = my iine
    this.state = {
      account: UserUtil.getDefaultAccountAppState(),
      good: this.getInitialGoods(),
      loading: true,
      isRefreshing: false,
      buttonArea: true,
      badgeList: {},
      itemHeights: 1
    }
    this.actionEnabled = React.createRef();
  }
  
  async componentDidMount() {
    this._isMounted = true;
    if(this._isMounted){
      this.getListFromApi(this.page);
      
      //this.setBadge();
      //await this.checkRemoveSearchList();
      // if use await in componentDidMount, focus listener not fire when first mount
      this._unsubscribe = this.props.navigation?.addListener('focus', async() => {

        CommonFunction.checkAccessTime();
        // if back from hide or block
        await this.checkRemoveSearchList();
        if(this.nextVal>0 && this.state.good?.list?.length==0){
          this.page = this.page + 1;
          this.getListFromApi(this.page);
        }
        this.actionEnabled.current = false;
      });
      const { appState } = this.context;
      if(appState.account){
        this.setState({ account: appState.account});
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    if(this._unsubscribe!=undefined){
      this._unsubscribe();
    }
  }

  getInitialGoods = ()=>{
    return { unreadCount: 0, count: 0, nextVal: 0, list: [] }
  }

  checkSMS = ()=>{
    if(!this._isMounted) return;

    if(UserVerify.checkSMSAppState(this.context)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account.activated, 
      navigation: this.props.navigation,
      account: this.state.account, 
    });
  }

  setBadge = async()=>{
    // set badge
    let badgeResponse = await Badge14Api.GetBadge();
    let badgeList = badgeResponse?.response;
    if(badgeList!=undefined){
      badgeList.unreadCount = this.state.good?.unreadCount;
      this.setState({badgeList: badgeList});
      if(this.props.setBadge!=undefined){
        this.props?.setBadge(badgeList);
      }
      if(this.props.setTopBadge!=undefined){
        this.props?.setTopBadge(badgeList);
      }
    }
  }

  decreaseBadge = async()=>{
    let { badgeList } = this.state;
    let unreadCount = parseInt(badgeList.unreadCount);
    let good = parseInt(badgeList.good);
    if(!isNaN(unreadCount) && unreadCount > 0){
      unreadCount -= 1;
    } 
      
    if(!isNaN(good) && good > 0) {
      good -=1;
    }

    badgeList.unreadCount = unreadCount;
    badgeList.good = good;
    this.setState({ badgeList: badgeList});

    if(this.props.setBadge!=undefined) {
      this.props?.setBadge({
        good: badgeList.good,
        notification: badgeList.notification,
        footmark: badgeList.footmark,
        message: badgeList.message,
        contact: badgeList.contact,
      });
    }
    if(this.props.setTopBadge!=undefined) {
      this.props?.setTopBadge({
        unreadCount: badgeList.unreadCount
      });
    }
  }

  checkRemoveSearchList = async()=>{
    let { appState, setAppState} = this.context;
    let { good } = this.state;
    if(appState.setChatHideMemberId || appState.matchList || this.matchList?.length>0){
      this.setState({loading: true, good: this.getInitialGoods()},()=>{
        let filterData = good;
        if(appState.setChatHideMemberId){
          if(filterData.list!=undefined && filterData.list?.length > 0){
            filterData.list = filterData.list?.filter(function (item) {
              return item.memberId != appState.setChatHideMemberId;
            });
          }
          this.decreaseBadge();
        }

        if(appState.matchList && Array.isArray(appState.matchList)){
          appState.matchList?.forEach((val,index)=>{
            if(filterData.list!=undefined && filterData.list?.length > 0){
              filterData.list = filterData.list?.filter(function (item) {
                return item.memberId != val;
              });
              this.decreaseBadge();
            }
          });
        }

        if(this.matchList && Array.isArray(this.matchList)){
          this.matchList?.forEach((val,index)=>{
            if(filterData.list!=undefined && filterData.list?.length > 0){
              filterData.list = filterData.list?.filter(function (item) {
                return item.memberId != val;
              });
            }
          });
          this.matchList = [];
        }

        appState.setChatHideMemberId = null;
        appState.matchList = null;
        setAppState(appState);
        this.setState({good: filterData, loading: false});
      });
    }
  }

  async getListFromApi(page){
    if(this._isMounted){
      let parentState = this.props?.parentState();
      if(parentState!=undefined && parentState.type==this.iineType){ // unmount tab
        if(parentState?.page!=undefined){
          this.page = parentState.page;
        }
        this.setState(parentState);
        this.props.parentState({}); // clear parent state
      }else{ // get new data
        if(!this.state.isRefreshing){
          this.setState({ loading: true });
        }

        try{
          let params = {
            page: page,
            type: this.iineType
          }

          const goodGetListResponse = await IineApi.getList(params);
          //check response
          if(!CommonFunction.checkResponseStatus(goodGetListResponse)) return;

          if(goodGetListResponse !=undefined && goodGetListResponse?.status==1){
            let goodList = goodGetListResponse.response?.good;
            let account = UserUtil.getAccountAppState(goodGetListResponse, this.context);
            let { good } = this.state;
            let buttonArea = false;
            if(goodList!=undefined){
              if(goodList.count > 0){ // have data
                good.list = goodList?.list;
                good.nextVal = goodList?.nextVal;
                good.count = goodList?.count;
                good.unreadCount = goodList?.unreadCount;
              }else{
                // no data
                good = this.getInitialGoods();
              }

              this.nextVal = goodList.nextVal;
            }
            if(good.list?.length >0){
              buttonArea = true;
            }
            this.setState({
              account: account, 
              good: good,
              buttonArea: buttonArea
            });

            if(page==1) this.setBadge();

            let otherTabRef = this.props?.otherTabRef();
            if(otherTabRef){
              if(typeof otherTabRef?.setState=='function') otherTabRef?.setState({account: account});
            }
          }
        }catch(e){
          if(__DEV__) console.log(e);
        }finally{
          this.setState({
            loading: false,
            isRefreshing: false
          });
        }
      }
    }
  };

  hideButton = ()=>{
    this.setState({buttonArea: false});
  }

  renderNoMoreCards = () =>{
    if(this.state.loading || this.state.isRefreshing) return <></>;

    if(this.nextVal > 0){
      this.page = this.page + 1
      this.getListFromApi(this.page);
      return <ActivityIndicator/>;
    }else{
      return (
        <NoItemsBox function={this.hideButton} />
      );
    }
  }

  getCurrentCard = () => {
    if(this.swipeCardRef.current!=undefined){
      const currentCard = this.swipeCardRef?.current?.state?.card;
      return currentCard;
    }
    return {};
  }

  swipe = (action='yup')=>{
    if(this.swipeCardRef.current!=undefined){
      if(action==='nope'){
        this.swipeCardRef?.current?.swipeNope();
      }else{
        this.swipeCardRef?.current?.swipeYup();
      }
    }
  }

  setSwipeAction = (action='yup')=>{
    setTimeout(function() {
      this.swipe(action);
    }.bind(this), 300);
  }

  skipAction = async(targetMemberId)=>{
    if(!targetMemberId) return;

    try{
      const parameter = { targetMemberId: targetMemberId };
      const skipResponse = await IineApi.postSkip(parameter);
      if(!CommonFunction.checkResponseStatus(skipResponse)) return;

      if(skipResponse!=undefined && skipResponse?.status==1){
        // decrease badge
        this.decreaseBadge();
        // for hide list
        this.setMatchList(targetMemberId);
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  swipeYup = ()=>{
    this.swipe('yup');
  }

  showPopupComplete = (yup=false)=>{
    PopupWrapper.setOptions({timeout:500});
    if(yup===true){
      PopupWrapper.showMessage(Constants.MsgIine,'check', this.swipeYup);
    }else{
      PopupWrapper.showMessage(Constants.MsgIine);
    }
  }

  iineAction = async(targetMemberId, yup=false)=>{
    if(!targetMemberId) return false;
    
    try{
      const parameter = {
        targetMemberId: targetMemberId, 
        messageType: 1, 
      };
      const responseSendGood = await IineApi.sendGood(parameter,1);
      if(!CommonFunction.checkResponseStatus(responseSendGood)) return;

      if(responseSendGood!=undefined && responseSendGood?.status==1){ // success
        // decrease badge
        this.decreaseBadge();
        // for hide list
        this.setMatchList(targetMemberId);

        const response = responseSendGood?.response;
        const matching = response?.matching;
        if(matching==1){ // matching
          await CommonFunction.countMatchComplete(this.matchKeyname);
          let matchComplete = await CommonFunction.getMatchComplete(this.matchKeyname);
          // show popup congratulations
          if(matchComplete ==0){
            const options = {
              verified: this.state.account?.ageVerficationStatus, 
              partnerVerified: response?.verified, 
              partnerImageUrl: response?.partnerImageUrl, 
              ownImageUrl: response?.ownImageUrl,
              receiveMessageFlg: response?.receiveMessageFlg,
              sendMessageFlg: response?.sendMessageFlg,
              targetMemberId: targetMemberId,
              matching: matching,
              disableCallBack: !yup,
              activated: this.state.account?.activated,
            };
            this.MatchCompleteRef.setOptions(options);
            this.MatchCompleteRef.show();
          }else{
            //this.showPopupComplete(yup);
            if(yup) this.swipeYup();
          }
        }else{
          //this.showPopupComplete(yup);
          if(yup) this.swipeYup();
        }
        return true;
      }else{
        return false;
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  async skip(){
    // validate SMS
    // if(!this.checkSMS()) return false;
    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 2000)

    CommonFunction.overLay();
    const currentCard = this.getCurrentCard();
    const targetMemberId = currentCard?.memberId;
    if(targetMemberId){        
      this.skipAction(targetMemberId);
    }
    this.swipe('nope');
    setTimeout(() => {
      CommonFunction.hideOverlay();
    }, 700);
  }

  async iine(){
    // validate SMS
    // if(!this.checkSMS()) return false;
    if(this.actionEnabled.current) return;
    this.actionEnabled.current = true;
    setTimeout(() => {
      this.actionEnabled.current = false
    }, 2000)

    CommonFunction.overLay();
    const currentCard = this.getCurrentCard();
    const targetMemberId = currentCard?.memberId;
    let isYup = false;
    if(targetMemberId){       
      isYup = await this.iineAction(targetMemberId, true);
    }
    if(isYup===false) {
      setTimeout(() => {
        this.swipe('yup');
        CommonFunction.hideOverlay();
      }, 1500);
    }else{
      CommonFunction.hideOverlay();
    }
  }

  onRefresh = async()=>{
    this.page = 1;
    this.setState({
      isRefreshing: true,
      good: this.getInitialGoods()
    },()=>{
      this.getListFromApi(this.page);
    });
  
  }

  handleYup (card) {
    // validate SMS
    // if(!this.checkSMS()) return false;

    // iine
    const memberId = card?.memberId;
    if(memberId){
      this.iineAction(memberId);
    }
    return true;
  }
  
  handleNope (card) {
    // validate SMS
    // if(!this.checkSMS()) return false;

    // skip
    const memberId = card?.memberId;
    if(memberId){
      this.skipAction(memberId);
    }
    return true;
  }
  
  handleMaybe (card) {
    return true;
  }

  handleClick = async(cardData)=>{
    // validate SMS
    // if(!this.checkSMS()) return false;

    const currentCard = this.getCurrentCard();
    if(cardData.memberId!=currentCard.memberId) return;
    
    try{
      await UserUtil.setsearchResult(this.context, this.state.good?.list);
      this.setParentState();
      this.props.navigation?.navigate("Profile",{memberId: cardData.memberId, type:this.iineType});
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  setParentState = ()=>{
    // other tab reference
    let otherTabRef = this.props?.otherTabRef();
    if(otherTabRef){
      let otherTabState = otherTabRef?.state;
      if(otherTabState!=undefined){
        if(otherTabRef.page!=undefined){
          otherTabState.page = otherTabRef.page;
        }
        if(otherTabRef.iineType!=undefined){
          otherTabState.type = otherTabRef.iineType;
        }
        if(otherTabRef.no_more!=undefined){
          otherTabState.no_more = otherTabRef.no_more;
        }
        otherTabRef?.props?.parentState(otherTabState);
      }
    }
  }

  setMatchList = (memberId)=>{
    this.matchList.push(memberId);
    let { appState, setAppState } = this.context;
    appState.matchList = this.matchList;
    setAppState(appState);
  }

  render(){
    if(this.state.loading===true) return <ActivityIndicator/>
    
    return (
      <View style={styles.container}>
        <MatchComplete ref={ref=>{this.MatchCompleteRef=ref}} navigation={this.props.navigation} hideCallback={()=>{this.swipe('yup')}} />
        {this.state.buttonArea===false?
        <ScrollView 
        style={styles.scrollContainer}
        contentContainerStyle={
          Platform.OS!=='web'?{ 
            flexGrow:1 
          }:{}
        }
        refreshControl={
          <RefreshControl
            refreshing={this.state.isRefreshing}
            onRefresh={this.onRefresh}
            long={true}
          />
        }>
          <View style={{flex:1, height:'100%'}}>
            {/* <NoItemsBox msg='あなたにいいねしたお相手が表示されます' /> */}
            <View style={[styles.textContainer,styles.topTxt,{marginTop:0,marginBottom:0}]}>
              <Text style={styles.profText}>あなたに「いいね！」をしている</Text>
              <Text style={styles.profText}>新着のお相手はいません</Text>
            </View>
            <View style={[styles.textContainer,{marginTop:20,marginBottom:10,alignItems:'center',justifyContent:'center'}]}>
              <Image style={styles.noneCasesImg} source={require('../../assets/images/good_none_cases_image.png')}/>
            </View>
          </View>
        </ScrollView>
        :
        <View style={[{flex:1,width:'100%', justifyContent:'center', alignItems:'center', alignSelf:'center'},this.state.itemHeights<=0 && styles.topContainer]} 
          onLayout={object => {
            const height = object.nativeEvent.layout.height;
            if(height > 0){
              const itemHeights = height - (swipeCardHeight + cardContainerHeight + swipeCardMarginBottom);
              this.setState({itemHeights: itemHeights});
            }
          }}>
          <View style={[styles.swipeContainer,{height:this.state.good?.list?.length>0?swipeCardHeight:'auto',marginBottom:swipeCardMarginBottom}]}>
            <SwipeCards
              ref={this.swipeCardRef}
              cards={this.state.good?.list}
              renderCard={(cardData) => (
                <TouchableOpacity activeOpacity={1} onPress={()=>{
                  this.handleClick(cardData);
                }}>
                  <Card {...cardData} bottomTab={true} swipeCardHeight={swipeCardHeight} />
                </TouchableOpacity>
              )}
              extraData={this.state.good?.list}
              keyExtractor={(cardData) => String(cardData?.memberId)}
              renderNoMoreCards={() => this.renderNoMoreCards()}
              actions={{
                nope: {show:false, onAction: this.handleNope.bind(this)},
                yup: {show:false, onAction: this.handleYup.bind(this)},
                maybe: {show:false, onAction: this.handleMaybe.bind(this)}
              }}
              stack={true}
              stackDepth={3}
              stackOffsetY={22}
              stackOffsetX={0}
              dragY={false}
              smoothTransition={true}
            />
          </View>
          
          {this.state.buttonArea &&
            <View style={[styles.cardContainer, this.state.itemHeights<=0 && styles.cardContainerBottom]}>
              <TouchableOpacity style={styles.skipBtn} onPress={()=> this.skip() }>
                <Image style={styles.skipImg} source={require('../../assets/images/icon/icon-skip.png')}/>
                <Text style={styles.skipText}>スキップ</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.likeBtn} onPress={()=> this.iine() }>
                <Image style={styles.likeImg} source={require('../../assets/images/icon/icon-heart.png')}/>
                <Text style={styles.likeText}>ありがとう</Text>
              </TouchableOpacity>
            </View>
          }
        </View>
      }
      </View>
    )
  }
}

const calcMarginTopBtn = ()=>{
  if(windowHeight >= 570){
    let marginTop = windowHeight-570;
    if(marginTop>60) marginTop = 60;
    return marginTop;
  }
  return 0;
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    justifyContent:'center',
    backgroundColor:"#ffffff",
    width:'100%',
    height:'100%',
    marginTop:0,
    ...Platform.select({
      web:{
        paddingBottom: HeaderOptions.bottomTabHeight
      }
    })
  },
  scrollContainer:{
    flex: 1,
    width: '100%', 
    height: scrollmini==true? scrollHeight : '100%',
    backgroundColor: '#ffffff'
  },
  swipeContainer: {
    backgroundColor: "#ffffff",
    width:'100%',
    height: swipeCardHeight,
    //alignItems: "center",
    //paddingTop:30
  },
  cardContainer:{
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-around',
    width:'90%', 
    ...Platform.select({
      ios:{
        height:cardContainerHeight,
        marginTop: 0
        //marginTop: calcMarginTopBtn(),
      },
      android:{
        width:'75%',
        height:cardContainerHeight,
        marginTop: 0
        //marginTop: calcMarginTopBtn(),
      },
      web:{
        width:'75%',
        height:cardContainerHeight,
        marginTop: 0
        //marginTop: calcMarginTopBtn(),
      }
    })
  },
  skipBtn:{
    alignItems:"center",
    justifyContent:"center",
    margin:5,
    width:80,
    height:80,
    backgroundColor:"#FFF",
    borderRadius:9999,
    shadowColor:"#9ea5a7",
    shadowOffset:{
      width:0,
      height:0,
    },
    shadowOpacity:0.80,
    shadowRadius:4,
    elevation:10,
  },
  skipImg:{
    marginBottom:8,
    width:36,
    height:26,
  },
  skipText:{
    fontSize:10,
    fontWeight:'bold',
    lineHeight:10,
    color:'#9ca4a6',
    textAlign:'center',
  },
  likeBtn:{
    alignItems:'center',
    justifyContent:'center',
    margin:5,
    width:80,
    height:80,
    backgroundColor:'#5cc8ca',
    borderRadius:9999,
    shadowColor:'#9ea5a7',
    shadowOffset:{
      width:0,
      height:0,
    },
    shadowOpacity:0.80,
    shadowRadius:4,
    elevation:10,
  },
  likeImg:{
    marginBottom:5,
    width:34,
    height:33,
  },
  likeText:{
    fontSize:10,
    fontWeight:'bold',
    lineHeight:10,
    color:"#ffffff",
    textAlign:'center',
  },
  btnBlue:{
    padding:8,
    paddingLeft: 15,
    paddingRight: 15,
    width: 268,
    height:55,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:22,
    color:'#fff',
    textAlign:'center',
    letterSpacing:1,
  },
  textContainer:{
    ...Platform.select({
      web:{
        flex:1,
      }
    }),
    width:'100%',
    textAlign: 'center',
    justifyContent:'center',
  },
  profText:{
    color:'#303030',
    fontSize:15, 
    fontWeight:'bold',
    letterSpacing:1,
    textAlign:'center',
  },
  topContainer:{
    justifyContent:'flex-start',
    marginTop:20
  },
  cardContainerBottom:{
    ...Platform.select({
      ios:{},
      android:{},
      web:{
        position:'absolute',
        bottom:0,
      }
    })
  },
  noneCasesImg:{
    width: 176,
    height: 188,
  },
  noneCasesText:{
    marginTop:25,
    width: 284,
    height: 43,
  },
  topTxt:{
    ...Platform.select({
      android:{ 
        height:90, 
        justifyContent:'flex-end'
      },
      ios:{ 
        height:90, 
        justifyContent:'flex-end'
      },
    })
  },
  bottomBtn:{
    marginTop:55,
    marginBottom:20,
    flexDirection:'row', 
    justifyContent:'center',
    ...Platform.select({
      android:{ 
        marginTop:10,
      },
      ios:{ 
        marginTop:10,
      },
    })
  }
});