import { StyleSheet, Text, View, Image, TouchableOpacity,TouchableWithoutFeedback, Platform } from 'react-native';
import OnlineStatus from '../common/OnlineStatus';
import { AntDesign } from '@expo/vector-icons'; 
//import IineUtil from '../../utils/IineUtil';
import Iine10Api from '../../services/api/Iine10Api';
import CommonFunction from '../../utils/CommonFunction';
import PopupWrapper from '../common/PopupWrapper';
import Constants from '../../constants/Constants';
import React, { useRef } from 'react';

export default function SkipUserRowFormat(props) {
  const { item } = props;
  let mainImageUrl = '';
  let onlineId = 0;
  // if(item.mainImageUrl!=undefined) mainImageUrl = item.mainImageUrl;  
  if(item.mainImageUrl!=undefined) mainImageUrl = CommonFunction.urlConver(item.mainImageUrl);

  if(item.online!=undefined) onlineId = item.online?.id;

  const actionEnabled = useRef(false);

  return (
    <TouchableWithoutFeedback onPress={props.onPress}>
      <View style={styles.profileContainer}>
        <Image style={styles.myProfileThumbnail} source={{uri : mainImageUrl}} />
        <View style={styles.rightProfile}>          
          <View style={{flexDirection:'row'}}>
            <View style={{alignSelf:'center'}}><OnlineStatus online={onlineId} /></View>
            <Text style={styles.profileDesc}>{item?.name} {item?.age} {item?.currentPref}</Text>
          </View>        
          <TouchableOpacity style={styles.btnIine} onPress={async()=>{
            let parameter = {
              targetMemberId: item?.memberId, 
              messageType: 1, 
            };
            if(!actionEnabled.current) {
              actionEnabled.current = true;
              setTimeout(() => {
                actionEnabled.current = false
              }, 2000)
              try{
                CommonFunction.overLay();
                const responseSendGood = await Iine10Api.sendGood(parameter, 1, 1);
                if(responseSendGood?.status==1){ // success
                  PopupWrapper.setOptions({timeout:500});
                  PopupWrapper.showMessage(Constants.MsgIine,'check',()=>{
                    CommonFunction.hideOverlay();
                  });
                  if(props.removeData!=undefined){
                    props.removeData(item.memberId);
                  }
                }else{
                  CommonFunction.hideOverlay();
                }
              }catch(e){
                CommonFunction.hideOverlay();
                if(__DEV__) console.log(e);
              }finally{
                //CommonFunction.hideOverlay();
                actionEnabled.current = false;
              }
            }
          }}>
            <AntDesign name="heart" size={16} color="#ffffff" />
            <Text style={styles.txtBtnIine}>いいねありがとう</Text>
          </TouchableOpacity>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  profileContainer: {
    flexDirection:'row',
    flex:1,
    paddingTop:10,
    paddingRight:16,
    paddingBottom:10,
    paddingLeft:16,
    backgroundColor:'#ffffff',
    borderBottomColor:'#e6e6e6',
    borderBottomWidth:1,
  },
  myProfileThumbnail:{
    marginRight:12,
    width:60,
    height:60,
    borderRadius:10,
  },
  rightProfile:{
    width: '75%',
  },
  rightRow: {
    position:'relative',
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
  },
  talk: {
    justifyContent:'flex-start',
    paddingRight:8,
    paddingLeft:8,
    backgroundColor:'#23db24',
    borderRadius:60,
  },
  txtTalk: {
    color:'#fff',
    fontSize:12,
    lineHeight:19
  },
  showTime: {
    position:'absolute',
    top:0,
    right:0,
    fontSize:10,
    lineHeight:14,
    color:'#9aa5a7',
    textAlign:'right',
  },  
  online_time:{
    fontSize: 11,
    textAlign: 'left',
    paddingTop: 5,
    marginLeft: 10,
  },
  msg: {
    fontSize:12,
    fontWeight:'normal',
    color:'#9aa5a7',
    lineHeight:19
  },
  profileDesc: {
    color:'#484848',
    fontWeight:'bold',
    lineHeight:20,
    height:20
  },
  btnIine:{
    flexDirection:'row',
    width:170,
    padding:8,
    height:30,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
    marginTop:5,
    ...Platform.select({
      android:{
        padding:0,
      },
      ios:{
        padding:0,
      }
    })
  },
  txtBtnIine:{
    marginLeft:10,
    color:'#ffffff',
    fontWeight:'bold',
    justifyContent:'center'
  }
});
