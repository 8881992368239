import ApiPappy from '../../utils/ApiPappy';
import ApiConfig from '../../constants/ApiConfig';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';
import CommonFunction from '../../utils/CommonFunction';


export default class MemoApi extends ApiPappy{

  static async PutMemo(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/Memo/Put/Memo/",
        {
          targetMemberId: (values?.targetMemberId!=undefined)?values.targetMemberId:"",
          memo: (values?.memo!=undefined)?values.memo:"",
        },
        true
      );
      if(__DEV__) console.log('/Memo/Put/Memo/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/Memo/Put/Memo/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}