import * as React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Dimensions, Platform} from 'react-native';
//import { FontAwesome, EvilIcons } from '@expo/vector-icons';
import OnlineStatus from '../common/OnlineStatus';
import moment from 'moment';
import Constants from '../../constants/Constants';

const windowWidth = Dimensions.get('window').width;

export const checkValue = (val)=>{
  if(Constants.NOTSETTEXT.indexOf(val)==-1 && val!='' && val!=undefined){
    return true;
  }
  return false;
}

export default function FootStepUserRowFormat(props) {
  const { item } = props;
  let onlineId = 0;
  let time = '';
  if(item?.online!=undefined) onlineId = item.online?.id;
  if(item?.footmarkDateTime?.unix !=undefined){
    time = moment.unix(item.footmarkDateTime.unix).format('HH:mm');
  }
  let msg = props?.msg;
  if(msg) msg = msg.replaceAll("\n","");

  var memoVis = false;
  var text = item.memo;
  if (text.length>0) {
    memoVis = true;
    text = text.replaceAll("\n"," ");
  }

  return (

    <View style={styles.containerBase}>



      <View style={styles.profileList}>
        <View style={styles.timeDetail}>
          <Text style={styles.txtTime}>{time}</Text>
        </View>

        <View style={styles.profileItems}>
          <View style={styles.profileContainer}>
            <Image style={styles.myProfileThumbnail} source={{uri : props.img}} />
            {item?.newFlag==1?
            <View style={styles.newUser}><Text style={styles.txtNewUser}>NEW</Text></View>
            : null
            }
          </View>
          <View style={styles.rightProfile}>
            <View style={styles.profileDesc}>
              <OnlineStatus online={onlineId} />
              <Text style={styles.txtProfileName}>{props?.nickname}</Text> 
            </View>
            <View style={styles.profileDesc}>
              <Text style={styles.txtProfileDesc}>{props?.age}</Text>
              <Text style={styles.txtProfileDesc}>{props?.area}</Text>
              {checkValue(item.occupation)?
              <Text style={styles.txtProfileDesc}>{item?.occupation}</Text>
              :null}
            </View>
            <View style={styles.profileMsg}>
              <Text numberOfLines={2} style={styles.txtMsg}>{msg}</Text>
            </View>
            <View style={styles.btnLike}>
              <Text style={styles.txtBtn}>プロフィールを見る</Text>
            </View>
          </View>
        </View>
      </View>

      {memoVis?
      <View style={[styles.memoContainer, {height: 30}]}>
        <View style={styles.flexRowCenter}>
          <View>
            <Image style={styles.icMemo} source={require('../../assets/images/icon/memo-icon.png')}/>
          </View>
          <View style={styles.memTextArea}>
            <Text numberOfLines={1} style={styles.memoText}>{text}</Text>
          </View>
        </View>
      </View>    
      :null}
    </View>
  );
}

let style = {
  containerBase: {
    flexDirection: 'column',
    backgroundColor:'#ffffff',
    borderBottomColor:'#e6e6e6',
    borderBottomWidth:1,
  },
  memoContainer: {
    flexDirection:'row',
    paddingTop:6,
    paddingRight:15,
    paddingBottom:10,
    paddingLeft:15, 
    width:'100%',
    alignSelf:'center',
    alignItems:'center',
    alignContent:'center',
    backgroundColor:'#fff',
    elevation:1,
    zIndex:1,
  },
  flexRowCenter:{
    width:'98%',
    height:26,
    marginBottom:8,
    flexDirection:'row',
    alignItems:'center',
    backgroundColor:'#f2f2f2',
  },
  icMemo:{
    width:20, 
    height:18,
    marginLeft:5,
    marginBottom:0,
  },
  memoText:{
    marginBottom:5,
    fontSize:12,
    lineHeight:24,
    color:"#333",
    textAlign:'left',
  },
  profileList:{
    flexDirection:'column',
    paddingTop:windowWidth<375? 6 : 8,
    paddingBottom:windowWidth<375? 8 : 10,
    paddingLeft:windowWidth<375? 20 : 22,
    paddingRight:windowWidth<375? 20 : 22,
    // borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
  },
  profileItems:{
    flexDirection:'row',
  },
  profileContainer: {
    flexDirection:'row',
    paddingRight:windowWidth<375? 12 : 14,
  },
  myProfileThumbnail:{
    width:windowWidth<375? 69 : 80,
    height:windowWidth<375? 69 : 80,
    borderRadius:5,
  },
  rightProfile:{
    flex:2,
  },
  profileDesc: {
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
  },
  onlineStatus:{
    marginRight:5,
    fontSize:8,
    color:'#24eb25',
  },
  txtProfileName:{
    marginRight:10,
    fontSize:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#262626',
  },
  txtProfileDesc:{
    marginRight:10,
    fontSize:16,
    fontWeight:'bold',
    lineHeight:20,
    color:'#484848',
  },
  profileMsg:{
    marginTop:5,
    marginBottom:5,
  },
  txtMsg:{
    fontSize:windowWidth<375? 13 : 16,
    fontWeight:'normal',
    color:'#484848',
    lineHeight:windowWidth<375? 16 : 19
  },
  btnSendMessage:{
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    width:'100%',
    height:38,
    backgroundColor:'#ff8d63',
    borderRadius:8,
    textAlign:'center',
  },
  btnLike:{
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    width:'100%',
    height:windowWidth<375? 34 : 38,
    backgroundColor:'#5cc8ca',
    borderRadius:8,
    textAlign:'center',
  },
  icBtnSendMessage:{
    marginRight:8,
    fontSize:28,
    lineHeight:20,
    color:'#ffffff',
    textAlign:'center',
  },
  icBtn:{
    marginRight:8,
    fontSize:16,
    lineHeight:20,
    color:'#ffffff',
    textAlign:'center',
  },
  txtBtn:{
    fontSize:windowWidth<375? 12 : 15,
    fontWeight:'bold',
    color:'#ffffff',
    textAlign:'center',
  },
  newUser:{
    position:'absolute', 
    top:-12,
    left:-8, 
    width:28, 
    height:28, 
    backgroundColor:'rgba(255, 141, 99, 0.9)', 
    opacity:0.8,    
    borderRadius:999, 
    justifyContent:'center',
    textAlign: 'center',
  },
  txtNewUser:{
    fontSize:8,
    fontWeight:'bold',
    color:'#fff',
    textAlign:'center',
  },
  timeDetail:{
    alignSelf:'flex-end',
    marginBottom:4,
  },
  txtTime: {
    fontSize:windowWidth<375? 9 : 10,
    fontWeight:'bold',
    lineHeight:windowWidth<375? 9 : 10,
    color:'#9ca4a6',
  },
  memTextArea: {
    flex:1,
    paddingLeft:10,
    paddingRight:10,
    ...Platform.select({
      web:{
        paddingTop:4,
      }
    })
  }
};

if(windowWidth>375 && windowWidth<=414){
  style.profileList = {
    ...style.profileList,
    paddingTop:10,
    paddingBottom:11,
    paddingLeft:26,
    paddingRight:26,
  };
  style.profileContainer = {
    ...style.profileContainer,
    paddingRight:17,
  };
  style.myProfileThumbnail = {
    ...style.myProfileThumbnail,
    width:88,
    height:88,
  };
  style.btnLike = {
    ...style.btnLike,
    height:43,
  };
  style.txtBtn = {
    ...style.txtBtn,
    fontSize:16,
  };
  style.txtTime = {
    ...style.txtTime,
    fontSize:11,
    lineHeight:11,
  };
}

const styles = StyleSheet.create(style);