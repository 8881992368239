import React, {memo} from 'react';
import { View, StyleSheet } from 'react-native';
// プロフ一覧レイアウト変更：個別のユーザ表示（最小表示用）
import UserColumnSmall from '../data/UserColumnSmall';
import UserColumnLarge from '../data/UserColumnLarge';

// プロフィール一覧表示用テンプレート（大：1人、小2人）
const RenderSearchListPanelTpl2 = memo(
  ({item, index, onPress, itemLayoutHeight, isMale, isRecommend, type}) => {
    var listIndex = item.listIndex;
    var lItem = item[0];
    var item2 = [];
    item2.push(item[1],item[2]);    

    return (
      <View style={[{flex:1, width:'100%', height: itemLayoutHeight*2+2, alignItems: 'center', justifyContent: 'center', marginLeft:2}]}>
        {type==0?
        <View style={[{flexDirection:'row', width:'100%', height: itemLayoutHeight*2+2, alignItems: 'center', justifyContent: 'center'}]}>
          <View style={[{display: 'flex', width: itemLayoutHeight*2+2, height: itemLayoutHeight*2+2, alignItems: 'center', justifyContent: 'center', marginRight:2}]}>
            <UserColumnLarge index={index} item={lItem} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight*2+2} isRecommend={isRecommend} listIndex={listIndex}/>
          </View>

          <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight*2+2, alignItems: 'center', justifyContent: 'center'}]}>
            {item2[0]?
              <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginBottom:2}]}>
                <UserColumnSmall index={index} item={item2[0]} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} listIndex={listIndex}/>
              </View>
              :
              <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginBottom:2}]}>
              </View>
              }
            {item2[1]?
              <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginBottom:0}]}>
                <UserColumnSmall index={index} item={item2[1]} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} listIndex={listIndex}/>
              </View>
              :
              <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginBottom:0}]}>
              </View>
              }
          </View>
        </View>
        :
        <View style={[{flexDirection:'row', width:'100%', height: itemLayoutHeight*2+2, alignItems: 'center', justifyContent: 'center'}]}>
          <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight*2+2, alignItems: 'center', justifyContent: 'center', marginRight:2}]}>
            {item2[0]?
              <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginBottom:2}]}>
                <UserColumnSmall index={index} item={item2[0]} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} listIndex={listIndex}/>
              </View>
              :
              <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginBottom:2}]}>
              </View>
              }
            {item2[1]?
              <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginBottom:0}]}>
                <UserColumnSmall index={index} item={item2[1]} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} listIndex={listIndex}/>
              </View>
              :
              <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginBottom:0}]}>
              </View>
              }
          </View>

          <View style={[{display: 'flex', width: itemLayoutHeight*2+2, height: itemLayoutHeight*2+2, alignItems: 'center', justifyContent: 'center'}]}>
            <UserColumnLarge index={index} item={lItem} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight*2+2} isRecommend={isRecommend} listIndex={listIndex}/>
          </View>
        </View>
        }
      </View>
    )
});

const styles = StyleSheet.create({
  listItem:{
    flex:1, 
  },
  listItemMale:{
    flex:1, 
  },
});

export default RenderSearchListPanelTpl2;