import { StyleSheet, Platform, StatusBar, Dimensions } from 'react-native';
import HeaderOptions from '../constants/HeaderOptions';

const mainImgHeight = 380;
let { width, height } = Dimensions.get('window');
if(Platform.OS==='web'){
  if(height < document.documentElement?.clientHeight)
    height = document.documentElement.clientHeight;
}
let scrollHeight = height - HeaderOptions.headerTopMenuHeight;

export default StyleSheet.create({
  container: {
    flex:1,
    backgroundColor:'#ffffff',
    marginTop:0,
  },
  thumbList:{
    marginTop:14,
    //paddingLeft:14,
    width:'100%',
    height:102,
  },
  separator: {
    marginRight:10,
    height:102,
    borderColor:'#eeeeee',
    borderWidth:1,  
    borderRadius:4,
  },
  thumbImg:{
    width: 100,
    height: 100,
    borderRadius:4,
  },
  prevIcon:{
    position:'absolute',
    top: 150,
    left: 0,
    backgroundColor: 'transparent',
    zIndex: 300,
    paddingLeft:10,
    paddingRight:20,
    paddingTop:20,
    paddingBottom:20,
  },
  nextIcon:{
    position: 'absolute',
    top: 150,
    right: 0,
    backgroundColor: 'transparent',
    zIndex: 300,
    paddingLeft:20,
    paddingRight:10,
    paddingTop:20,
    paddingBottom:20,
  },
  scrollContainer: {
    backgroundColor: '#ffffff',
    //paddingBottom: 120
    ...Platform.select({
      ios:{
        flexGrow: 1
      },
      android:{
        flexGrow: 1
      },
      default:{
        height: scrollHeight
      }
    })
  },
  myProfileImg:{
    height: mainImgHeight
  },
  bottomTab:{
    height: 120,
    alignItems:'center',
    justifyContent:'center',
    alignSelf:'center',
    flexDirection:'row',
    backgroundColor:'rgba(255,255,255,0)',
    bottom: 0,
    ...Platform.select({
      ios: {
        position:'absolute',
        bottom: 25
      },
      android: {
        position:'absolute',
        bottom: 25
      },
      default:{
        position:'fixed'
      }
    }),
  },
  grid:{
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#9aa5a7',
    textAlign: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowRadius: 3.84,
    elevation: 5,
    shadowOpacity: 0.25,
    paddingLeft: 10,
    paddingRight: 10,
    height: 80,
    width: 80,
    borderRadius:9999,
  },
  msgBtn:{
    backgroundColor:'#ff8d63',
  },
  iineBtn:{
    backgroundColor: '#5cc8ca',
  },
  textIcon:{
    fontSize: 11,
    fontWeight:'bold'
  },
  whiteColor:{
    color: '#FFFFFF'
  },
  skipBtn:{
    alignItems:"center",
    justifyContent:"center",
    margin:5,
    width:80,
    height:80,
    backgroundColor:"#FFF",
    borderRadius:9999,
    shadowColor:"#9ea5a7",
    shadowOffset:{
      width:0,
      height:0,
    },
    shadowOpacity:0.80,
    shadowRadius:4,
    elevation:10,
  },
  skipImg:{
    marginBottom:8,
    width:36,
    height:26,
  },
  skipText:{
    fontSize:10,
    fontWeight:'bold',
    lineHeight:10,
    color:"#9ca4a6",
  },
  likeBtn:{
    alignItems:"center",
    justifyContent:"center",
    margin:5,
    width:80,
    height:80,
    backgroundColor:"#5cc8ca",
    borderRadius:9999,
    shadowColor:"#9ea5a7",
    shadowOffset:{
      width:0,
      height:0,
    },
    shadowOpacity:0.80,
    shadowRadius:4,
    elevation:10,
  },
  likeImg:{
    marginBottom:5,
    width:34,
    height:33,
  },
  likeText:{
    fontSize:10,
    fontWeight:'bold',
    lineHeight:10,
    color:"#ffffff"
  },
  section:{
    backgroundColor: '#ffffff',
    justifyContent:'center',
    alignContent:'center',
  },
  h3:{
    fontSize: 16,
    paddingTop: 12,
    paddingBottom:12,
    color:'#484848',
    fontWeight:"700",
    margin:0
  },
  skipIcon:{
    width: 30,
    height: 30,
    marginBottom: 5,
    alignSelf:'center'
  },
  inside:{
    paddingTop:4, 
    paddingRight: 16, 
    paddingBottom: 20, 
    paddingLeft: 16, 
    borderTopWidth:20, 
    borderTopColor:'rgba(238, 241, 242, 1)'
  },
  insideBG:{
    paddingTop:24,
    paddingBottom:10,
    paddingLeft:8,
    paddingRight:8,
    backgroundColor:'#eef0f1',
  },
  newUser:{
    position:'absolute', 
    left:0, 
    top:0,
    width:50,
    height:50,
  },
  newUserText:{
    color:'#fff', 
    fontSize:8, 
    fontWeight:'bold',
    textAlign:'center',
  },  
  icShowPopup:{
    marginRight:16,
    fontSize:30,
    color:'#484848',
  },
  textareaBtnBottom:{
    marginBottom:18,
    paddingRight:8,
    paddingLeft:16,
    width:'100%',
    backgroundColor:'#f2f2f2',
    borderRadius:8,
  },
  textAreaInput:{
    padding:16,
    paddingLeft:0,
    width:'100%',
    ...Platform.select({
      ios: { },
      android: { textAlignVertical:'top', },
      default: { },
    }),
  },
  btnSendMsg:{
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    width:'100%'      
  },
  btnBtnSendMsg:{
    width:'100%',
    padding:8,
    height:45,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnSendMsg:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:22,
    color:'#fff',
    textAlign:'center'
  },
  blogIcClose:{
    position:'absolute',
    right:10,
    alignItems:'flex-end',
    alignContent:'flex-end',
    width:'100%',
    zIndex:1,
  },
  maskUp: {
    position:'absolute',
    backgroundColor:'#ffffff',
    width:'100%',
    height:'100%',
    left:0,
    top:0,
    zIndex:1,
    elevation:1
  },
  newUserImg: {
    width:50,
    height:50,
  },
  blogMsgTemplate:{
    position:'relative',
    padding:10,
    width:'100%',
  },
  btnIcEdit:{
    position:'absolute',
    top:7,
    right:10,
    zIndex:1101,
  },
  icEdit:{
    fontSize:24,
    lineHeight:30,
    color:'#484848',
  },
  blogTxtTitleMsg:{
    flex:1,
    marginBottom:10,
  },
  txtTitleMsg:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:20,
    color:'#484848',
  },
  blogTxtDescMsg:{
    flexDirection:'row',
    marginBottom:10,
    marginRight:30,
    paddingTop:8,
    paddingBottom:8,
    paddingRight:12,
    paddingLeft:12,
    width:245,
    //width:260,
    height:60,
    backgroundColor:'#34bedb',
    borderRadius:8,
  },
  txtDescMsg:{
    fontSize:14,
    fontWeight:'normal',
    lineHeight:22,
    color:"#fff",
  },
  imgCheckMsg:{
    position:'absolute',
    bottom:-4,
    right:-10,
    width:30,
    height:30,
    zIndex:1101,
  },
  blogBGMatch: {
    width:'100%',
    height:width<375? 96 : 112,
    zIndex:1,
    elevation:1
  },
  imgBGMatch: {
    width:'100%',
    height:width<375? 106 : 124,
  },
  blogBGMatchInside: {
    padding:width<375? 12 : 15,
    width:'100%',
    height:width<375? 96 : 112,
  },
  txtBlogBGMatch:{
    fontSize:width<375? 12 : 14,
    fontWeight:'normal',
    lineHeight:width<375? 19 : 21,
    color:'#fff',
  },
  label:{
    marginTop:11,
    marginRight:8,
    marginBottom:11,
    color: '#484848',
    fontSize:14,
    lineHeight: 22
  },
  text:{
    margin:8,
    fontSize:14,
    maxWidth:180,
  },
  infoLabel:{
    flex:1,  
    alignSelf:'stretch', 
    alignItems:'flex-start'
  },
  infoText:{
    maxWidth:200,
    alignItems:'flex-end'
  },
  infoList:{
    flexDirection:'row', 
    alignSelf:'stretch', 
    borderBottomWidth:.55, 
    borderBottomColor:'#e6e6e6'
  },
  scrollContentContainer:{
    ...Platform.select({
      ios:{
        paddingBottom:120,
        flexGrow:1
      },
      android:{
        paddingBottom:120,
        flexGrow:1
      },
      default:{
        paddingBottom:120,
      }
    })
  },
});