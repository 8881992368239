import React, { Component, memo } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Platform, Dimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Constants from '../../constants/Constants';
import OnlineStatus from '../common/OnlineStatus';
import { AppContext } from '../../AppContext';
import { Image } from 'expo-image';

const isWeb = Platform.OS === 'web';
const windowWidth = Dimensions.get('window').width;

export default class UserColumnNew extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(){
    super();
    this.state = { 
      loading: true
    };
  }

  async componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      const { index, item, onPress, isMale, itemLayoutHeight, isRecommend } = this.props;
      this.index = index;
      this.item = item;
      this.isMale = isMale;
      this.onPress = onPress;
      this.itemLayoutHeight = itemLayoutHeight;
      this.isRecommend = isRecommend;
      if(this.itemLayoutHeight==undefined){
        this.itemLayoutHeight = isMale?250:170;
      }
      this.setState({loading: false});
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  render(){
    if(this.state.loading) return <></>;
    
    // area
    let area = this.item?.currentPref;
    if(Constants.NOTSETTEXT.indexOf(area) != -1) area = '';
    
    // tweet
    let tweet = '';
    if(this.item?.sex==Constants.MEN){
      if(Constants.NOTSETTEXT.indexOf(this.item?.income) == -1) tweet = this.item?.income;
      if(!isWeb){
        tweet = (this.item?.tweet!='') ? this.item?.tweet:this.item?.introduction;
      }
    }else if(this.item?.sex==Constants.WOMEN){
      tweet = (this.item?.tweet!='') ? this.item?.tweet:this.item?.introduction;
    }

    // online
    let online = 0;
    if(this.item?.online!=undefined) online = this.item.online?.id;
    
    let imgPriority = 'normal';
    if(this.index%42<=12) imgPriority = 'high';

    return (
      <TouchableOpacity style={styles.container} onPress={this.onPress}>
        <View style={styles.ThumbnailProfilebase}>
          {this.item.tmMainImageUrl?
          <Image 
            source={{uri : this.item.tmMainImageUrl}}
            contentFit={'cover'} 
            style= {[styles.ThumbnailProfileImg,{ height:this.itemLayoutHeight}]}
            responsivePolicy="live"
            priority={imgPriority}
          />:null}
          <BgGradient isMale={this.isMale} />
        </View>
        {this.item.matchMeetArea.length > 0?
          <View style={styles.itemMeetAreaBase}>
            <Text style={styles.itemMeetAreaText}>  {this.item.matchMeetArea}  </Text>
          </View>
        :null}
        <View style={this.isMale?styles.itemProfileMale:styles.itemProfile}>
          <View style={styles.profileItem}>
            {this.isRecommend ? null:
            <OnlineStatus online={online} />
            }
            <Text style={this.isMale?styles.fontMediumMale:styles.fontMedium}>{this.item?.age} {area}</Text>
          </View>
          <View style={this.isMale?styles.descProfileMale:styles.descProfile}><Text numberOfLines={1} style={this.isMale?styles.txtDescProfileMale:styles.txtDescProfile}>{tweet}</Text></View>
        </View>
      </TouchableOpacity>
    );
  }
}

const BgGradient = memo(
  ({isMale}) => {
    return (
    <LinearGradient 
      colors={['transparent','rgba(0,0,0,0.8)']} 
      style={[styles.bgLinearGradient,{height:isMale?60:50}]}
    />
    )
  },
  (prevProps, nextProps) => {
    return prevProps.isMale === nextProps.isMale;
  },
)

const styles = StyleSheet.create({
  container:{
    flex:1, 
    width:'100%', 
    height:'100%', 
    alignItems: 'center', 
    justifyContent: 'center'
  },
  ThumbnailProfile: {
    flexDirection: 'column',
    marginLeft:2,
    marginRight:2,
  },
  bgLinearGradient:{
    position:'absolute',
    left:0,
    right:0,
    bottom:0,
    width:'100%',
    height:50,
    zIndex:1,
    borderBottomLeftRadius:10,
    borderBottomRightRadius:10,
  },
  bgLinearGradientMale:{
    position:'absolute',
    left:0,
    right:0,
    bottom:0,
    width:'100%',
    height:60,
    zIndex:1,
    borderBottomLeftRadius:10,
    borderBottomRightRadius:10,
  },
  itemProfile:{
    position:'absolute',
    bottom:0,
    padding:5,
    width:'100%',
    zIndex:10,  
  },
  itemProfileMale:{
    position:'absolute',
    bottom:0,
    paddingLeft:8,
    paddingRight:8,
    paddingTop:12,
    paddingBottom:7,
    width:'100%',
    zIndex:10, 
  },
  fontMedium: {
    fontSize: 12,
    lineHeight:20,
    alignSelf: 'flex-start',
    fontWeight:"600",
    color: '#fff',
    letterSpacing:-.2,
  },
  fontMediumMale: {
    fontSize: 13,
    lineHeight:20,
    alignSelf: 'flex-start',
    fontWeight:"600",
    color: '#fff',
    letterSpacing:-.2,
  },
  ThumbnailTrialbase:{
    width: '100%', 
    height: 250, 
    borderRadius:10,
    backgroundColor: '#ffffff', 
    borderColor:'#e10063',
    borderWidth:2,
  },
  ThumbnailTrialImg1:{
    width: '99%',
    height: 90, 
    marginTop: 2,
    marginLeft: 2,
    alignSelf: 'center'
  },
  ThumbnailTrialImg2:{
    width: windowWidth<375? 125:150,
    height: windowWidth<375? 61:74, 
    margin: 8,
    alignSelf: 'center'
  },
  ThumbnailTrialImg3:{
    width: windowWidth<375? 140:165,
    height: windowWidth<375? 120:140,
    marginTop: 8,
    alignSelf: 'center'
  },
  ThumbnailProfilebase:{
    width: '100%', 
    borderRadius:10  
  },
  ThumbnailProfileImg:{
    width: '100%', 
    height: 170, 
    borderRadius:10
  },
  profileItem: {
    flexDirection:'row',
    justifyContent:'flex-start',
    alignItems:'center',
    alignSelf:'stretch',
  },
  descProfile:{
    paddingLeft:2,
    height:12,
  },
  descProfileMale:{
    paddingLeft:2,
    height:16,
  },
  txtDescProfile:{
    fontSize:10,
    lineHeight:12,
    color:'#fff',
    letterSpacing:-.2,
  },
  txtDescProfileMale:{
    fontSize:11,
    lineHeight:16,
    color:'#fff',
    letterSpacing:-.2,
  },
  itemMeetAreaBase: {
    position:'absolute',
    backgroundColor: '#2B5D94',
    lineHeight:16,
    right:5,
    top:5,
    alignItems:'flex-end',
    borderRadius:999,
  },
  itemMeetAreaText: {
    fontSize: 11,
    lineHeight:16,
    alignSelf: 'flex-end',
    fontWeight:"300",
    color: '#fff',
    alignItems:'flex-end',
  },
});
