import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, Dimensions  } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import { AppContext } from '../../AppContext';
import * as RootNavigation from '../../navigation/RootNavigation';

const windowWidth = Dimensions.get('window').width;

export default class CampaignEndPopup extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
    this.hideAction = null;
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      if(this.navigation==undefined) this.navigation = RootNavigation;
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = ()=>{
    this.setState({ show: true});
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  gotoSettingImg = ()=>{
    this.hide();
    this.navigation?.navigate('Auth', {screen:'Purchase'});
  }

  render() {
    if(!this.state.show) return <></>;

    const popupWidth = windowWidth * 0.77;
    const newHeight = popupWidth * 728 /912;

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
            <View style={PortalStyle.overlay} />
            <View style={styles.container}>
                <ImageBackground resizeMode={'cover'} 
                    style={{width: popupWidth, height:newHeight, justifyContent: 'flex-end', alignItems: 'center'}} 
                    source={require('../../assets/images/campaign-end.png')}
                ></ImageBackground>
                <TouchableOpacity style={styles.btnBlue} onPress={this.gotoSettingImg}>
                    <Text style={styles.txtBtnBlue}>有料会員プランを見る</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnCancel} onPress={()=>this.hide()}>
                    <Text style={styles.txtBtnCancel}>閉じる</Text>
                </TouchableOpacity>
          </View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#ffffff', 
    height:'auto', 
    width:'77%',
    alignSelf:'center', 
    paddingTop:20,
    borderRadius:15
  },
  btnBlue:{
    paddingLeft:8,
    paddingRight:8,
    marginTop:10,
    width:'70%',
    height:35,
    alignSelf:'center', 
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center'
  },
  btnCancel:{
    width:'45%',
    padding:2,
    height:30,
    marginTop:15,
    marginBottom:15,
    alignSelf:'center', 
    backgroundColor:'#f2f2f2',
    alignItems:"center",
    justifyContent:"center",
    letterSpacing:2,
    borderRadius:999,
  },
  txtBtnCancel:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#323232',
    textAlign:'center',
  },
});