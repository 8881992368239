import React, {memo} from 'react';
import { View, Image, StyleSheet } from 'react-native';
// プロフ一覧レイアウト変更：個別のユーザ表示（最小表示用）
import UserColumnSmall from '../data/UserColumnSmall';

// プロフィール一覧表示用テンプレート（小：横3人）
const RenderSearchListPanelSmall = memo(({item, index, onPress, itemLayoutHeight, isMale, isRecommend, listIndex}) => {

    return (
      <View style={[{flex:1, width:'100%', height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center'}]}>
        <View style={[{flexDirection:'row', width:itemLayoutHeight*3+4, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center'}]}>

          {item[0]?
            <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginRight:2}]}>
              <UserColumnSmall index={index} item={item[0]} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} listIndex={listIndex}/>
            </View>
          :
            <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center'}]}>
            </View>
          }
          {item[1]?
            <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center', marginRight:2}]}>
              <UserColumnSmall index={index} item={item[1]} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} listIndex={listIndex}/>
            </View>
          :
            <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center'}]}>
            </View>
          }
          {item[2]?
            <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center'}]}>
              <UserColumnSmall index={index} item={item[2]} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight} isRecommend={isRecommend} listIndex={listIndex}/>
            </View>
          :
            <View style={[{display: 'flex', width: itemLayoutHeight, height: itemLayoutHeight, alignItems: 'center', justifyContent: 'center'}]}>
            </View>
          }

        </View>
      </View>  
    )
});


const styles = StyleSheet.create({
  listItem:{
    flex:1, 
  },
  listItemMale:{
    flex:1, 
  },
});

export default RenderSearchListPanelSmall;