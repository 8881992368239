import React, { Fragment, Component } from 'react';
import {Dimensions, View, Text, TouchableOpacity, StyleSheet, Image, TextInput, Platform, ScrollView } from 'react-native';
import PortalStyle from '../../styles/PortalStyles';
import { Portal } from 'react-native-paper';
import { Formik } from 'formik'
import PopupWrapper from '../../components/common/PopupWrapper';
import CommonFunction  from '../../utils/CommonFunction';
import MemoApi from "../../services/api/Memo25Api";

const windowWidth = Dimensions.get('window').width;
const inputAccessoryViewID = "inputAccessoryView1";

export default class MemoInput extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
    this.initialValues = {
      inputText: '',
    }
    this.formik = React.createRef();
    this.targetMemberId = 0;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = (param)=>{
    this.name = param.name;
    this.image = param.image;
    this.targetMemberId = param.targetMemberId;
    this.initialValues.inputText = param.memoText;

    this.setState({ show: true });
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  cancelMemoInput = async()=>{
    this.hide();
  }

  handleSubmit = async(values)=>{
    let inputMemoText = values.inputText;
    const count = (Array.from(inputMemoText).length);
    if (count >100) {
      PopupWrapper.showMessage('入力可能な文字数を超えています。','check',()=>{
        CommonFunction.hideOverlay();
      });
      return;
    }

    var chText = inputMemoText.replaceAll("\n","");
    chText = chText.replaceAll(" ","");
    chText = chText.replaceAll("　","");
    if (chText.length == 0) {
      inputMemoText = "";
    }

    const apiParams = {targetMemberId: this.targetMemberId, memo: inputMemoText};
    const putMemoResponse = await MemoApi.PutMemo(apiParams);
    if(!CommonFunction.checkResponseStatus(putMemoResponse)) return;
    if(putMemoResponse?.status==1){
      PopupWrapper.showMessage('メモを保存しました。','check',()=>{
        CommonFunction.hideOverlay();
        const params = {memoText: inputMemoText};
        this.props.memoUpdata(params);
        this.hide();
      });
    }
  }

  render() {
    if(!this.state.show) return null;

    return (
      <Portal style={PortalStyle.portal}>
        <View style={PortalStyle.portalContainer}>
          <View style={PortalStyle.overlay} />
          <View style={styles.container}>
            <View style={styles.imageArea}>
              <Image style={styles.thumbAvatar} source={{uri : this.image}} />
            </View>
          <ScrollView style={{width:'100%', height:'100%'}}>
            <View style={styles.textArea}>
              <View style={{bottom: 0, width: '100%', height: 56}}>
                <Text numberOfLines={1} style={{fontSize: 19, fontWeight: 'bold', color: '#000000', textAlign: 'center', lineHeight: 28}}>
                  {this.name}さんの自分用メモ
                </Text>
                <Text numberOfLines={1} style={{fontSize:windowWidth<375? 12 : 13, color: '#333', textAlign: 'center', lineHeight: 20}}>
                  メモの内容はお相手には表示されません
                </Text>
              </View>
            </View>
            <Formik
              innerRef={ref => this.Formik=ref}
              initialValues={this.initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={values => this.handleSubmit(values) }
            >
            {({ values, handleChange, errors, setFieldTouched, touched, dirty, isValid, handleSubmit }) => (
              <Fragment>
                  <View style={styles.nameInputArea}>
                    <View style={styles.inputTextAreaBorder}>
                      <TextInput 
                          style={[styles.inputTextArea, {fontSize:windowWidth<375? 13 : 14}]}
                          onChangeText={handleChange('inputText')}
                          value={values.inputText} 
                          multiline={true} 
                          placeholder="メモを記入する &#13;&#10;（例:「平日夜が都合良い」「和食好き」など" 
                          placeholderTextColor="#9aa5a7"
                          inputAccessoryViewID={inputAccessoryViewID}
                      />
                    </View>
                    <Text style={{width: '90%', fontSize:12, color: '#333', textAlign: 'right', alignSelf:'center', lineHeight:20}}>※最大100文字まで</Text>
                  </View>
                  <View style={styles.btnArea}>
                    <View style={{paddingTop: 0}}>
                      <TouchableOpacity 
                        style={styles.btnBlue} 
                        onPress={handleSubmit}
                      >
                        <Text style={styles.txtBtnBlue}>決定</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{paddingTop: 13}}>
                      <TouchableOpacity style={styles.btnCancel} onPress={()=>{
                        this.cancelMemoInput();
                      }}>
                        <Text style={styles.txtBtnCancel}>キャンセル</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
              </Fragment>
            )}          
            </Formik>
            </ScrollView>
          </View>
        </View>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor:'#ffffff', 
    marginTop:32,
    height:490, 
    width:'90%',
    alignSelf:'center', 
    borderRadius:8,
  },
  btnBlue:{
    width:'55%',
    padding:8,
    height:42,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignSelf:'center', 
    shadowColor: "#10a4c3",
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:14,
    fontWeight:'bold',
    lineHeight:26,
    color:'#fff',
    textAlign:'center',
  },
  btnCancel:{
    width:'35%',
    padding:4,
    borderRadius:999,
    backgroundColor:'#eef1f2',
    height:26,
    alignSelf:'center', 
  },
  txtBtnCancel:{
    fontSize:12,
    lineHeight:18,
    color:'#333',
    textAlign:'center',
  },
  textArea: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  imageArea: {
    width:64,
    height:64,
    marginTop:-32,
    itemAlign:'center',
    alignSelf:'center',
  },
  thumbAvatar:{
    width:64,
    height:64,
    borderRadius:9999,
  },
  favorireSettingArea: {
    flexDirection: 'row',
    width:254,
    height:90,
    itemAlign:'center',
    alignSelf:'center',
  },
  btnAnameInputArearea: {
    width:254,
    height:85,
    itemAlign:'center',
  },
  btnArea: {
    itemAlign:'center',
    paddingTop:20,
  },
  checkImg:{
    marginTop:15,
    marginLeft:6,
    width:28,
    height:27,
  },
  inputTextAreaBorder:{
    flexDirection: 'row',
    width:'90%', 
    height:240,
    alignSelf:'center',
    backgroundColor:"#F2F2F2",
    borderColor:'#eef1f2',
    borderWidth:1,
    borderRadius:5,
  },
  inputTextArea:{
    width:'98%',
    height:220,
    fontSize:18,
    paddingTop:15,
    paddingBottom:15,
    paddingLeft:20,
    paddingRight:10,
    color:"#333",
    lineHeight:20,
    ...Platform.select({
      ios: { },
      android: { textAlignVertical:'top', },
      default: { },
    }),
  },
});