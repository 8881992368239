import React, {memo} from 'react';
import { View, StyleSheet } from 'react-native';
// プロフ一覧レイアウト変更：個別のユーザ表示（最小表示用）
import UserColumnLarge from '../data/UserColumnLarge';

// プロフィール一覧表示用テンプレート（大：1人）
const RenderSearchListPanelTpl1 = memo(({item, index, onPress, itemLayoutHeight, isMale, isRecommend, type}) => {
    var listIndex = item.listIndex;
    var lItem = item[0];

    return (
      <View style={[{flex:1, width:'100%', height: itemLayoutHeight*3+4, alignItems: 'center', justifyContent: 'center', marginLeft:2}]}>
        <View style={[{flexDirection:'row', width:'100%', height: itemLayoutHeight*3+4, alignItems: 'center', justifyContent: 'center'}]}>
          <View style={[{display: 'flex', width: itemLayoutHeight*3+4, height: itemLayoutHeight*3+4, alignItems: 'center', justifyContent: 'center', marginRight:2}]}>
            <UserColumnLarge index={index} item={lItem} onPress={onPress} isMale={isMale} itemLayoutHeight={itemLayoutHeight*3+4} isRecommend={isRecommend} listIndex={listIndex}/>
          </View>
        </View>
      </View>
    )
});

const styles = StyleSheet.create({
  listItem:{
    flex:1, 
  },
  listItemMale:{
    flex:1, 
  },
});

export default RenderSearchListPanelTpl1;