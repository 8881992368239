import { Platform, Image } from 'react-native';
import SearchOrder from './SearchOrder';
import Search03Api from '../../services/api/Search03Api';
import CommonFunction from '../../utils/CommonFunction';
import StorageHelper from '../../utils/StorageHelper2';
import UserUtil from '../../utils/UserUtil';
import Constants from '../../constants/Constants';

export default class OrderByRecommend extends SearchOrder {
  getSearchParams = ()=>{
    return {
      count: 42,
      page: 1,
      currentPref: 0
    };
  }

  async getListFromApi(page){
    if (this._isMounted) {
      this.sortOrder = 2;

      if(!this.state.isRefreshing){
        this.setState({ loading: true });
      }

      const userProfileAppState = this.appContext.appState?.userProfile;
      const userProfile = await CommonFunction.getProfile();
      if(userProfileAppState?.memberId!=userProfile?.memberId){
        await this.setColumn(userProfile);
        this.appContext.appState.userProfile = userProfile;
        this.appContext?.setAppState(this.appContext.appState);
      }

      let searchInfo = await CommonFunction.getSearchHistory(userProfile?.memberId);
      this.searchParams = this.getSearchParams();
      this.searchParams.page = page;
      if(searchInfo!=null){
        this.searchParams.currentPref = CommonFunction.makeTabData(searchInfo.adds);
      }
      
      try{
        const getListResponse = await Search03Api.SuggetGetSearchList(this.searchParams);
        //check response status
        if(!CommonFunction.checkResponseStatus(getListResponse,false)) return;
        
        if(getListResponse!=undefined && getListResponse?.status==1){
          CommonFunction.setAccessTime();

          StorageHelper.storeData('ikuyoStatus', Number(getListResponse.ikuyoStatus));
          if(this.props.setIkuyoStatus!=undefined){
            this.props?.setIkuyoStatus(Number(getListResponse.ikuyoStatus));
          }
          let searchMale = false;
          if(userProfile?.sex==Constants.FEMALE){
            searchMale = true;
          }
          const account = UserUtil.getAccountAppState(getListResponse, this.appContext);          
          const profileList = getListResponse?.response?.profileList;
          let data = this.state.data;
          if(this.state.isRefreshing) data = [];
          const imageSize = getListResponse?.response?.thumbnailSize;
          let longRefreshControl = true;
          if(profileList!=undefined){
            if(profileList?.count > 0){
              let oriCount = data.length;
              if (!searchMale) {
                data = await this.setMaleSerchListPanelData(data, profileList, imageSize);
                for (var i=oriCount; i<data.length; i++) {
                  const items = data[i];
                  for(var l=0; l<items.length; l++) {
                    const cacheUrl = items[l].tmMainImageUrl;
                    if (i<=3) {
                      await Image.prefetch(cacheUrl);
                    } else {
                      Image.prefetch(cacheUrl);
                    }
                  }
                }
              } else {
                data = data.concat(profileList?.list);
                CommonFunction.thumbnailUrlConver(data);
                for (var i=oriCount; i<data.length; i++) {
                  const cacheUrl = data[i].tmMainImageUrl;
                  if(cacheUrl){
                    if(i<=10){
                      await Image.prefetch(cacheUrl);
                    }else{
                      Image.prefetch(cacheUrl);
                    }
                  }
                }
              }
              this.setListIndex(data,oriCount);
            }
            this.no_more = (profileList?.nextVal == 0)?1:0;
            if(data?.length >0){
              longRefreshControl = false;
            }
          }
          this.setState({
            account: account,
            data: data,
            sortOrder: this.sortOrder,
            longRefreshControl: longRefreshControl
          });

          this.setPrivilegeFlag(getListResponse?.response?.privilegeFlag)
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.loadComplete(); 
      }
    }
  }
}
