import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import Constants from '../../constants/Constants';

export default class OnlineStatus extends Component {
  constructor(){
    super();
  }

  render(){
    let { online } = this.props;

    let onlineStyle = {};
    if(online==Constants.onlineStatus.online){
      onlineStyle = styles.greenStatus;
    }else if(online==Constants.onlineStatus.online12Hr){
      onlineStyle = styles.yellowStatus;
    }else if(online==Constants.onlineStatus.online24Hr){
      onlineStyle = styles.orangeStatus;
    }else if(online==Constants.onlineStatus.online3Day){
      onlineStyle = styles.redStatus;
    }
    if(online==Constants.onlineStatus.onlineOver3Day){
      return <></>;
    }
    if(online==Constants.onlineStatus.noDisplay){
      return <></>;
    }

    return (
      <FontAwesome name="circle" style={[styles.nowStatus, onlineStyle]} />        
    );
  }
}

const styles = StyleSheet.create({
  nowStatus: {
    marginRight: 4,
    marginTop: -2,
    fontSize: 13,
    color: Constants.onlineStatus.onlineOver3DayColor,
  },
  greenStatus: {
    color: Constants.onlineStatus.onlineColor,
  },
  yellowStatus: {
    color: Constants.onlineStatus.online12HrColor,
  },
  orangeStatus: {
    color: Constants.onlineStatus.online24HrColor,
  },
  redStatus: {
    color: Constants.onlineStatus.online3DayColor
  }
});
