import React from 'react';
import { 
  Image, StyleSheet, Text, View, TouchableOpacity, FlatList, Animated , Easing, Platform, Dimensions
} from 'react-native';
import { Entypo, Ionicons } from '@expo/vector-icons';
import DatePicker from 'rmc-date-picker/lib/DatePicker';
import PopPicker from 'rmc-date-picker/lib/Popup';
import PopupStyles from 'rmc-picker/lib/PopupStyles';
import ActivityIndicator from '../components/common/ActivityIndicator'
import Constants from '../constants/Constants';
import CommonFunction from '../utils/CommonFunction';
import HeaderOptions from '../constants/HeaderOptions';
import HeadStyle from '../styles/HeadStyles';
import LoadMore from '../components/screens/LoadMore';
import CalendarApi from '../services/api/Calendar11Api';
import UserUtil from "../utils/UserUtil";
import { RefreshControl } from 'react-native-web-refresh-control';
import OnlineStatus from '../components/common/OnlineStatus';
import Moment from 'moment';
import Storage from '../utils/StorageHelper2';
import PopupWrapper from "../components/common/PopupWrapper";
import UserVerify from '../utils/UserVerify';
import Msg from '../constants/option/Msg.json';
import Badge14Api from '../services/api/Badge14Api';
import RightPopup from '../components/common/RightPopup';
import MeetSearchScreenPopup from './meet-search/MeetSearchScreenPopup';
import GoodNoPoint from '../components/common/GoodNoPoint';
import GoodUsePoint from '../components/common/GoodUsePoint';
import ErrorCode from '../constants/ErrorCode';
import Logo from '../components/common/Logo';
import ExpoConstants from 'expo-constants';
import AgeVerify2 from '../components/common/AgeVerify2';
import SMSVerify2 from '../components/common/SMSVerrify2';

const screenHeight = Dimensions.get('window').height;
let BottomCalendarButton = 70;
if(Platform.OS!='web') BottomCalendarButton = 30;
const isWeb = Platform.OS === 'web';

export default class MeetScreen extends LoadMore {
  constructor(props) {
    super(props);
    this.minDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(this.minDate.getDate()+29);
    this.lockScroll = false;
    this.offset = 0;
    this.prevDirection = '';
    this.date_select=[];
    this.date_select[0]=this.minDate.getFullYear();
    this.date_select[1]=this.minDate.getMonth();
    this.date_select[2]=this.minDate.getDate();
    this.rightPopupRef = [];
    this.currentData = {};

    this.state = {
      ...this.state,
      selectDate: null,
      CalendarButtonBottom: new Animated.Value(BottomCalendarButton),
      sendList:[],
      maskUp: false,
      maskUpStateData: false,
      scrollHeight: 0
    }

    this.initParameter = {
      meetDate: this.minDate,
      adds: [],
      addsTxt: '',
      age: [],
      dateHope: []
    }
    this.itemLayoutHeight = 135;
    this.NoPointRef = React.createRef();
    this.UsePointRef = React.createRef();
    this.GoodNoPointRef = React.createRef();
    this.GoodUsePointRef = React.createRef();
  }

  checkSMS = ()=>{
    if (!this._isMounted) return;

    if(UserVerify.checkSMSAppState(this.appContext)) return true;

    return UserVerify.checkSMS({
      activated: this.state.account?.activated, 
      navigation: this.props.navigation,
      account: this.state.account
    });
  }

  checkSMSMessage = ()=>{
    if(UserVerify.checkSMSAppState(this.appContext)) return true;
    if(this.state.account?.activated==Constants.ActivatedStatus.notApprove){
      CommonFunction.checkAccessTime().then(response=>{
        if(response===true){
          this.SMSVerrify2Ref?.show();
        }
      });
      return false;
    }

    if(this.state.account?.level==Constants.Level.restrict){
      PopupWrapper.showModalMessage(Msg.AccountRestricted, '');
      return false;
    }
    
    return true;
  }

  checkAge = ()=>{
    if(UserVerify.checkAgeAppState(this.appContext)) return true;

    return UserVerify.checkAge({
      ageVerficationStatus: this.state.account?.ageVerficationStatus,
      navigation: this.props.navigation,
      checkSMS: this.checkSMS
    });
  }

  checkAgeMessage = ()=>{
    if(UserVerify.checkAgeAppState(this.appContext)) return true;

    if(this.state.account?.ageVerficationStatus!=Constants.AgeVerficationStatus.approve){
      CommonFunction.checkAccessTime().then(response=>{
        if(response===true){
          this.AgeVerify2Ref?.show();
        }
      });
      return false;
    }
    return true;
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this._isMounted) {
      CommonFunction.checkAccessTime();
      this.setHeader();  
      this.myProfile = await UserUtil.getUserProfileLocal(this.appContext?.appState);
      this.initialSearch();
    
      // show bottom tab
      this.showBottomTab();

      // focus from search page, if use await in componentDidMount, focus listener not fire when first mount
      this._unsubscribe = this.props.navigation?.addListener('focus', async() => {
        CommonFunction.checkAccessTime();
        // show bottom tab
        this.showBottomTab();

        await this.checkRemoveSearchList();
        this.scrollToIndex();
      });
    }
    
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if(this._unsubscribe!=undefined){
      this._unsubscribe();
    }
  }
  
  showBottomTab = ()=>{
    CommonFunction.showBottomTab(this.props.navigation);
  }

  scrollToIndex = ()=>{
    if(this.state.data?.length<=0) return;
    
    let { appState, setAppState } = this.appContext;
    const viewIndex = appState?.viewIndex;

    if(viewIndex){
      let maxIndex = this.state.data?.length - 1;
      if(maxIndex <0) maxIndex = 0;
      
      let gotoIndex = viewIndex;
      if(gotoIndex > maxIndex) gotoIndex = maxIndex;

      if(this.indexSetChatHideMemberId!=undefined && this.indexSetChatHideMemberId!=null){
        if(gotoIndex >= this.indexSetChatHideMemberId) gotoIndex = gotoIndex - 1; 
      }

      if(gotoIndex>=0 && maxIndex>0){
        try{
          this.setState({maskUp: true}, ()=>{
            setTimeout(() => {
            this.scrollRef?.scrollToIndex({ animated: false, index: gotoIndex, viewPosition: 0 });
            this.setState({maskUp: false});
            }, 350);
          });
        }catch(e){
          if(__DEV__) console.log(e);
          this.setState({maskUp: false});
        }
      }

      this.indexSetChatHideMemberId = null;
      appState.viewIndex = null;
      setAppState(appState);
    }
  }

  checkRemoveSearchList = async()=>{
    let { appState, setAppState} = this.appContext;
    if(appState.setChatHideMemberId){
      this.setState({maskUpStateData: true},()=>{
        const data = this.state.data;
        const filterData = data?.filter(function (item) {
          return item.memberId != appState.setChatHideMemberId;
        });
        const findIndex = data?.findIndex(
          items => appState.setChatHideMemberId == items.memberId
        );
    
        if(findIndex!=-1){
          this.indexSetChatHideMemberId = findIndex;
        }

        appState.setChatHideMemberId = null;
        appState.searchResult = filterData;
        setAppState(appState);
        this.setState({data: filterData, maskUpStateData: false});
      });
    }
  }

  initialSearch = async()=>{
    let searchInfo = await this.initialSearchInfoParams();
    this.setState({ searchInfo: searchInfo},()=>{
      this.page = 1;
      this.getListFromApi(this.page);
    });
  }
  
  setBadge = async()=>{
    let badgeResponse = await Badge14Api.GetBadge();
    if(badgeResponse!=undefined && badgeResponse?.status==1){
      let account = UserUtil.getAccountAppState(badgeResponse, this.appContext);
      if(account){
        this.setState({ account: account });
      }
      let badgeList = badgeResponse?.response;
      if(this.props.setBadge!=undefined){
        this.props?.setBadge(badgeList);
      }
    }
  }

  loadComplete = ()=>{
    this.setState({
      loading: false,
      isRefreshing: false
    });
  }

  async getListFromApi(page){
    if(this._isMounted){
      if(this.state.searchInfo==undefined) return;

      if(!this.state.isRefreshing){
        this.setState({ loading: true });
      }

      let searchInfo = this.state.searchInfo;
      let meetDate  = this.minDate;
      let dateHope  = '', adds = '', ageFrom = '', ageTo = '';
      
      if(searchInfo?.meetDate!=undefined){
        meetDate = searchInfo.meetDate;
      }
      if(searchInfo?.adds!=undefined){
        adds = CommonFunction.makeTabData(searchInfo.adds);
      }
      if(searchInfo?.dateHope!=undefined){
        dateHope = CommonFunction.makeTabData(searchInfo.dateHope);
      }

      if(searchInfo.age!=undefined && Array.isArray(searchInfo.age) && searchInfo.age.length>0){
        ageFrom = searchInfo.age[0];
        ageTo = searchInfo.age[1];
      }

      try{
        const params = {
          page: page, 
          searchMonth: Moment(meetDate).format('M'),
          searchDate: Moment(meetDate).format('D'),
          searchPref: adds,
          ageFrom: ageFrom,
          ageTo: ageTo,
          dateHope: dateHope,
        };

        const getListResponse = await CalendarApi.GetList(params);
        //check login
        if(!CommonFunction.checkResponseStatus(getListResponse)) return;

        if(getListResponse!=undefined && getListResponse?.status==1){
          // set badge
          this.setBadge();

          const account = UserUtil.getAccountAppState(getListResponse, this.appContext);
          let aeruList = getListResponse?.response?.aeru;
          let { data } = this.state;
          let longRefreshControl = true;
          if(aeruList!=undefined){
            let list = aeruList?.list;
            CommonFunction.thumbnailUrlConver(list);
            if(list==undefined) list = [];
            if(page > 1){
              data = data.concat(list)
            }else{
              data = list;
              this.setAnimation('up');
            }
            
            this.no_more = (aeruList?.nextVal == 0)?1:0;
            if(data?.length>0) longRefreshControl = false;
          }
          this.setState({ 
            account: account, 
            data: data,
            longRefreshControl: longRefreshControl,
            loading: false,
            isRefreshing: false
          });
        }else{
          this.loadComplete();
        }
      }catch(e){
        if(__DEV__) console.log(e);
        this.loadComplete();
      }finally{
      }
    }
  }

  convertDateObject(date){
    if(date!=undefined && Moment(date).isValid()){
      return new Date(date);
    }

    return new Date();
  }

  initialSearchInfoParams = async()=>{
    let values = await Storage.getObjectData('searchMeet');
    
    if(!values || values==undefined){ // no search history
      values = this.initParameter;

      if(this.myProfile?.currentPref!=undefined){
        const currentPref = this.myProfile.currentPref;
        values.adds = [{id: currentPref?.id, value: currentPref?.label}];
        values.addsTxt = currentPref?.label;
      }
    }else{
      if(values.meetDate!=undefined){
        values.meetDate = this.convertDateObject(values.meetDate);
        if(Moment(Moment(values.meetDate).format('YYYY-MM-DD')).isBefore(Moment(new Date()).format("YYYY-MM-DD"))){
          values.meetDate = this.minDate;
        }
      }
    }

    return values;
  }

  setHeader = () =>{
    this.props.navigation?.setOptions({
      header:()=>(        
        <View style={[HeaderOptions.HeaderIine, HeadStyle.appHeaderTop, { flexDirection:'row'}]}>
          <View style={{height:'100%',justifyContent:'center'}}><Logo/></View>
          <View style={[HeaderOptions.HeaderIineTitle,{height:'100%'}]}>
            <Text style={HeadStyle.headerTitlePage}>会える日{this.myProfile?.sex==Constants.MALE && <Text>（出張検索）</Text>}</Text>
          </View>
        </View>
      )
    });
  }

  onDateChange = (date) => {
    let { searchInfo } = this.state;
    searchInfo.meetDate = date;
    this.setState({ searchInfo: searchInfo }, ()=>{
      this.page = 1;
      this.getListFromApi(this.page);
      Storage.storeObjectData('searchMeet', searchInfo);
    });
  }

  onDatePickerChange = (date) => {
    if(this.date_select[1]!=date[1]){
      let minDateOfMonth = new Date(date[0],date[1],1);
      let minDateTxt = Moment(this.minDate).format('YYYY-MM-DD');
      let currentDateTxt = Moment(minDateOfMonth).format('YYYY-MM-DD');
      if(Moment(minDateTxt).isAfter(currentDateTxt)){
        minDateOfMonth=this.minDate;
      }
      this.date_select=date;
      this.setState({ selectDate: minDateOfMonth });
    }
  }

  displayDate = (date) => {
    if(date==undefined) date = this.minDate;

    const month = Moment(date)?.format("MM");
    const day = Moment(date)?.format("DD");
    return `${month}月 ${day}日`;
  }

  setAnimation(direction) {
    this.lockScroll = true;
    Animated.timing(this.state.CalendarButtonBottom, {
      duration: 300,
      easing: Easing.back(),
      toValue: direction=='down' ? -100 : BottomCalendarButton,
	    useNativeDriver: false
    }).start(({ finished }) => {
      if(finished){
        this.lockScroll = false;
      }
    });
  };

  handleScroll = (event) => {
    if(this.state.data?.length==0 || this.state.isRefreshing) return;
    if(this.state.data?.length < Math.ceil(this.state?.scrollHeight/this.itemLayoutHeight)) return;

    let currentOffset = event.nativeEvent.contentOffset.y;
    let position = currentOffset - this.offset;
    let direction = '';
    let downPosition = 20;
    let upPosition = -20;
    if(Platform.OS!=='web'){
      downPosition = 40;
      upPosition = -40;
    }

    if(position > downPosition){
      direction = 'down';
    }else if(position < upPosition){
      direction = 'up';
    }

    if(direction!=''){
      if(!this.lockScroll && this.prevDirection!=direction) {
        this.prevDirection = direction;
        this.setAnimation(direction);
      }
    }
    this.offset = currentOffset;
  }

  sendMeet = async(data, forceSubtract=false) => {
    // validate SMS
    // if(!this.checkSMS()) return;
    // validate Age
    if(!this.checkAgeMessage()) return;

    this.currentData = data;
    const {item} = data;
    const aeruPref = item.pref?.map(element => element.id)?.join(",");
    const messageType = 5; //会いたい

    try{
      CommonFunction.overLay();
      let params = {
        targetMemberId: item.memberId,
        aeruId: item.aeruId,
        aeruPref: aeruPref,
        messageType: messageType
      };
      if(forceSubtract===true) params.forceSubtract = 1;
      
      const response = await CalendarApi.PostSendMeet(params, 0);
      if(!CommonFunction.checkResponseStatus(response)) return;

      if(response!=undefined && response?.status==1){
        PopupWrapper.showMessage(Msg.Meet.Success,'check',()=>{
          CommonFunction.hideOverlay();
        });

        let { sendList } = this.state;
        if(!Array.isArray(sendList)) sendList = [];
        sendList?.push(item.memberId);
        this.setState({sendList: sendList});
      }else{
        CommonFunction.hideOverlay();
        if(JSON.stringify(response)=='{}'){
          return;
        }

        if(CommonFunction.checkIsInvalidErrorCode(response)){
          const res = response?.response;
          const errorCode = `${res?.errorGroup}-${res?.errorCode}`;
          let errorMessage = res?.errorMessage;
          let showError = false;
          if(forceSubtract===false){
            if(errorCode==ErrorCode.CalendarPostSendMeet.NoIine){
              this.GoodUsePointRef.setMessage(errorMessage);
              this.GoodUsePointRef.show();
            }else if(errorCode==ErrorCode.CalendarPostSendMeet.NoPoint){
              this.GoodNoPointRef.setMessage(errorMessage);
              this.GoodNoPointRef.show();
            }else if(errorCode==ErrorCode.CalendarPostSendMeet.NoMember){
              errorMessage = 'お相手が見つかりませんでした。一時停止あるいは退会された可能性があります。';
              showError = true;
            }else{
              showError = true;
            }
          }else{
            showError = true;
          }
          if(showError===true && errorMessage) PopupWrapper.showMessage(errorMessage,'warning');
        }
      }
    }catch(e){
      CommonFunction.hideOverlay();
      if(__DEV__) console.log(e);
    }finally{
      //CommonFunction.hideOverlay();
    }
  } 

  sendForceSubtractMeet = ()=>{
    if(this.currentData){
      this.sendMeet(this.currentData, true);
    }
  }

  getRightPopupRef = (key)=>{
    return this.rightPopupRef[key];
  }

  gotoProfile = async(index, item)=>{
    let {appState, setAppState} = this.appContext;
    appState.viewIndex = index;
    setAppState(appState);

    const uniqueId = [];
    const uniqueList = this.state.data?.filter(element => {
      const isDuplicate = uniqueId?.includes(element.memberId);
      if (!isDuplicate) {
        uniqueId.push(element.memberId);
        return true;
      }
      return false;
    });

    await UserUtil.setsearchResult(this.appContext, uniqueList);
    this.props.navigation.navigate('Profile',{memberId: item.memberId});
  }

  renderItem = (data) => {    
    let {item, index} = data;
    let tweet_intro = item?.tweet;
    let income = item?.income;
    if(!isWeb) income = '';
    if(tweet_intro==undefined || tweet_intro=="") tweet_intro = item?.introduction;
    if(tweet_intro){
      tweet_intro = tweet_intro.replaceAll("\n","");
    }
    if(Constants.NOTSETTEXT.indexOf(income)!=-1) income = '';
   
    const pref = item.pref?.map(element => element.label)?.join(",");
    const memberId = this.state?.sendList?.find(element => element == item.memberId);
    let enableBtn = (memberId || item?.aitaiFlag==1)?false: true;

    return (
      <View style={styles.insideContainer}>
        <TouchableOpacity onPress={()=>{this.gotoProfile(index, item)}}>
          <View style={styles.item}>
            <View style={styles.itemThumb}>
              <TouchableOpacity onPress={() => {
                this.gotoProfile(index, item);
              }}>
                <Image source={{uri : item.tmMainImageUrl}} resizeMode={'cover'} style={styles.itemThumbImg} />
              </TouchableOpacity>
            </View>                    
            <View style={styles.itemDetail}>              
                <View style={styles.area}>
                  <Ionicons name="location-outline" style={[styles.icLocation,{lineHeight:20}]} color="#9aa5a7" />
                  <Text style={{height:20, fontSize:12, fontWeight:'normal', color:'#9aa5a7',lineHeight:20}}>{pref}</Text>
                </View>
                <View style={styles.oneWords}>
                  <Text numberOfLines={2} style={{fontSize:12, fontWeight:'normal', lineHeight:19, color:'#454545'}}>{tweet_intro}</Text>
                </View>
                <View style={styles.login}>
                  <OnlineStatus online={item.online?.id} />
                  <Text style={{marginRight:4, fontSize:12, color:'#484848'}}>{item.age}</Text>
                  {this.myProfile?.sex==Constants.FEMALE &&
                    <Text style={{fontSize:12, color:'#484848'}}>{income}</Text>
                  }
                </View>              
            </View>                    
            <View style={styles.itemBtn}>
              {enableBtn?
              <TouchableOpacity style={styles.btn} onPress={()=>{this.sendMeet(data)}}>
                <Image style={{width:14, height:14, marginRight:4}} source={require('../assets/images/icon/send-orange.png')}/>
                <Text style={{fontSize:11, fontWeight:'bold', lineHeight:16, color:'#fe8d64', }}>会いたい</Text>
              </TouchableOpacity>
              :
              <View style={[styles.btn, {borderColor:'#cfcfcf'}]}>
                <Text style={{fontSize:11, fontWeight:'bold', lineHeight:16, color:'#cfcfcf', }}>会いたい済</Text>
              </View>
              }
            </View>          
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  noItemDisplay = () => {
    if(this.state.isRefreshing) return <></>;

    return (
      <View style={{flex:1, height:'100%'}}>
        <View style={{paddingTop: 25, paddingLeft: 15, paddingRight: 15, paddingBottom: 80, textAlign:'center',}}>
          <Text style={{fontSize:13, fontWeight:'normal', lineHeight:24, color:'#9aa4a6', textAlign:'center'}}>絞り込みの条件に合うお相手がいません。{"\n"}条件を変えて検索してみましょう。</Text>
        </View>
        <View style={{paddingLeft:80, paddingRight:80,}}>
          <RightPopup ref={ref=>this.rightPopupRef.MeetSearchScreenPopup2=ref} 
            component={<MeetSearchScreenPopup {...this.props} getRightPopupRef={this.getRightPopupRef} callback={this.initialSearch} refname="MeetSearchScreenPopup2"/>}>
            <View style={styles.btnBlue}>
              <Text style={styles.txtBtnBlue}>絞り込みを変更する</Text>
            </View>
          </RightPopup>
        </View>
      </View>
    );
  }

  getItemLayout = (data, index) => {
    const length = this.itemLayoutHeight;// row height
    const offset = length * index;
    return {
      length: length,
      offset: offset,
      index
    }
  }

  render() {
    const datePicker = (
      <DatePicker
        rootNativeProps={{'data-xx': 'yy'}}
        minDate={this.minDate}
        maxDate={this.maxDate}
        defaultDate={this.minDate}
        date={this.state.selectDate}
        mode={'date'}
        formatMonth={ (month, date) => {return (month+1).toString().padStart(2, '0')+'月';}}
        formatDay={ (day, date) => {return day.toString().padStart(2, '0')+'日';}}
        onValueChange={this.onDatePickerChange}
        itemStyle={{
          ...Platform.select({
            android:{
              fontSize:18,
              height:40,
              fontWeight: 'normal'
            },
            ios:{
              fontSize: 18
            }
          })
        }}
      />
    );
    
    let addsTxt = this.state.searchInfo?.addsTxt;
    if(Constants.NOTSETTEXT.indexOf(addsTxt)!=-1) addsTxt = '';
    
    let showLoading = false;
    if(this.state.loading && this.page === 1) showLoading = true;
    if(this.state.maskUpStateData===true) showLoading = true;
    let length = this.state.data?.length;
    if(length<15) length = 15;
    
    return (
      <View style={styles.container}>
        <GoodNoPoint ref={ref=>{this.GoodNoPointRef=ref}} navigation={this.props.navigation} />
        <GoodUsePoint ref={ref=>{this.GoodUsePointRef=ref}} navigation={this.props.navigation} sendForceSubtractGood={this.sendForceSubtractMeet} />
        <AgeVerify2 ref={ref=>this.AgeVerify2Ref=ref} checkSMS={this.checkSMSMessage} />
        <SMSVerify2 ref={ref=>this.SMSVerrify2Ref=ref} />
        <View style={styles.sortWrapper} onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          if(this.state.scrollHeight==0 && height>0){
            const scrollHeight = screenHeight - height - HeaderOptions.headerTopMenuHeight - HeaderOptions.bottomTabHeight;
            this.setState({scrollHeight: scrollHeight});
          }
        }}>
          <View style={[styles.sortWrapperRow,{marginBottom:5, ...Platform.select({
            ios: {
            marginTop:0 
            },
            android: {
              marginTop:0 
            }})
          }]}>
            <View style={styles.sortRowStart}>
              <PopPicker
                datePicker={datePicker}
                styles={PopupStyles}
                style={{fontFamily:'Segoe UI,roboto,sans-serif', fontSize:16}}
                transitionName="rmc-picker-popup-slide-fade"
                maskTransitionName="rmc-picker-popup-fade"
                title="会いたい日を選択"
                date={this.state.selectDate==null ? this.state.searchInfo?.meetDate : this.state.selectDate}
                okText="完了"
                dismissText="キャンセル"
                onChange={this.onDateChange}
              >
                {(Platform.OS==='android' || Platform.OS==='ios')?
                <TouchableOpacity>
                  <View style={styles.inputArea}> 
                    <Text style={styles.dateInput}>{this.displayDate(this.state.searchInfo?.meetDate)}</Text>
                    <Entypo name="chevron-down" style={styles.icArrowDown} />
                  </View>
                </TouchableOpacity>
                :
                <View style={styles.inputArea}> 
                  <Text style={styles.dateInput}>{this.displayDate(this.state.searchInfo?.meetDate)}</Text>
                  <Entypo name="chevron-down" style={styles.icArrowDown} />
                </View>
                }
              </PopPicker>
            </View> 
            <View style={styles.sortRowEnd}>
              {this.myProfile?.sex ==Constants.MALE ?
                <Text style={styles.otherCanmeet}>に出会える女性</Text>
              :
                <Text style={styles.otherCanmeet}>に出会える男性</Text>
              }
            </View>
          </View>

          <View style={styles.sortWrapperRow}>
            <View style={[styles.sortRowStart,styles.locationDesc]}>
              <Ionicons name="location-outline" style={[styles.icLocation,{lineHeight:30}]} color="#484848" />
              <Text numberOfLines={1} style={styles.txtLocation}>地域 : {addsTxt}</Text>
            </View>
            <View style={styles.sortRowEnd}>
              <RightPopup ref={ref=>this.rightPopupRef.MeetSearchScreenPopup=ref} 
                component={<MeetSearchScreenPopup {...this.props} getRightPopupRef={this.getRightPopupRef} callback={this.initialSearch} refname="MeetSearchScreenPopup" />}>
                <View style={{flexDirection:'row',justifyContent:'center'}}>
                  <Text style={styles.otherCond}>その他の条件</Text>
                  <Entypo name="chevron-right" style={styles.icArrowRight} />
                </View>
              </RightPopup>
            </View>
          </View>

        </View>

        {showLoading ? 
          <ActivityIndicator/>
        :
        <View style={
          Platform.OS==='web'?{ 
            height:this.state.scrollHeight
          }:{ 
            flex:1
        }}>
          {this.state.maskUp===true && (
            <View style={{position:'relative', width:'100%', height:'95%'}}>
              <View style={styles.maskUp}><ActivityIndicator/></View>
            </View>
          )}

          <FlatList 
            ref={(ref)=>this.scrollRef=ref}
            onScroll={this.handleScroll.bind(this)}
            contentContainerStyle={
              Platform.OS==='web'?{ 
                //height:this.state.scrollHeight
                paddingBottom:80,
                flexGrow:1  
              }:{ 
                flexGrow:1,
                paddingBottom:80, 
            }}
            refreshControl={
              <RefreshControl
                enabled={this.state.refreshControl}
                refreshing={this.state.isRefreshing}
                onRefresh={this.onRefresh}
                long={this.state.longRefreshControl}
              />
            }
            data={this.state.data}
            renderItem={(item) => this.renderItem(item)}
            ListEmptyComponent={this.noItemDisplay}
            keyExtractor={(item,index) => item?.memberId+index.toString()}
            extraData={this.state.data}
            ListFooterComponent={this.renderFooter.bind(this)}
            onEndReachedThreshold={0.5}
            onEndReached={this.handleLoadMore.bind(this)}
           //getItemLayout={this.getItemLayout}
           initialNumToRender={length}
          />
        </View>
        }

        <Animated.View style={[styles.btnCalendar,{bottom:this.state.CalendarButtonBottom}]}>
          <TouchableOpacity onPress={() => {
            // validate SMS
            if(!this.checkSMS()) return;
            // validate Age
            if(!this.checkAge()) return;

            this.props.navigation?.navigate('MeetCalendar');
          }}>
            <Image style={styles.imgCalendar} source={require('../assets/images/icon/ic-calendar.png')}/>
            <Text style={{fontSize:10, fontWeight:'bold', lineHeight: 18, color:'#fff'}}>会える日登録</Text>
          </TouchableOpacity>
        </Animated.View>

    </View>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    backgroundColor: '#ffffff',
    flex:1,
    width:'100%',
    marginTop: 0,
    ...Platform.select({
      ios:{},
      android:{},
      default:{
        paddingBottom:HeaderOptions.bottomTabHeight-1
      }
    })
    
  },
  insideContainer:{
    padding:8,
    flex:1,
  },
  item:{
    backgroundColor:'#ffffff',
    borderBottomWidth:1,
    borderBottomColor:'#e1e1e1',
    flexDirection:'row',
    justifyContent:'center',
    alignSelf:'stretch',
    alignItems:'flex-start',
  },
  itemThumb:{
    paddingTop:5,
    paddingBottom:12,
    paddingLeft:8,
    paddingRight:10,
    height:'100%',
  },
  itemThumbImg:{
    width:103,
    height:103,
    borderRadius:16,
  },
  itemDetail:{
    flex: 1,
    height:'100%',
    paddingTop:10,
    paddingRight:10,
  },
  itemBtn:{
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    height:'100%',
    paddingBottom:8,
  },
  btn:{
    flexDirection:'row', 
    justifyContent:'center', 
    borderWidth:1, 
    borderColor:'#fe8d64', 
    borderRadius:8,
    paddingTop:8, 
    paddingRight:10, 
    paddingBottom:8, 
    paddingLeft:10,
  },
  area:{
    flexDirection:'row',
    ...Platform.select({
      web:{
        marginBottom:10
      }
    })
  },
  oneWords:{
    marginBottom:10,
  },
  login:{
    flexDirection:'row',
    alignItems:'center', 
    alignSelf:'stretch',
    paddingLeft:5,
  },
  sortWrapper:{
    padding:4,
    backgroundColor:'#fff',
    borderBottomWidth:0.55,
    borderBottomColor:'rgba(0, 0, 0, 0.3)',
  },
  sortWrapperRow:{
    flexDirection:'row',
    justifyContent:'center',
  },
  sortRowStart:{
    alignItems:'flex-start',
  },
  sortRowEnd:{
    alignItems:'flex-end',
    justifyContent:'center'
  },
  inputArea:{
    flexDirection:'row',
    justifyContent:'center',
    margin:0,
    paddingTop:3,
    paddingRight:5,
    paddingBottom:3,
    paddingLeft:10,
    width:155,
    borderColor:'#e6e6e6', 
    borderWidth:2, 
    borderRadius:5,       
  },
  dateInput:{
    flex:1,
    alignItems:'flex-start',
    color:'#3274f6', 
    fontSize:17, 
    fontWeight:'bold',
    lineHeight:26
  },
  icArrowDown:{
    alignItems:'flex-end',
    color:'#5a5a5a',
    fontSize:24,      
    lineHeight:26
  },
  otherCanmeet:{
    marginLeft:10,
    fontSize:18,
    fontWeight:'bold',
    color:'#484848',
    lineHeight:30,    
  },
  locationDesc:{
    flexDirection:'row',
    width:'65%',
  },
  txtLocation:{
    marginRight:20,
    fontSize:14,
    fontWeight:'normal',
    lineHeight:30,
    color:'#484848',
  },
  icLocation:{
    marginRight:3,
    fontSize:14,
  },
  imgLocation:{
    width:12,
    height:15,
  },
  otherCond:{
    alignItems:'flex-start',
    fontSize:16,
    fontWeight:'bold',
    color:'#484848',
    lineHeight:30,      
  },
  icArrowRight:{
    alignItems:'flex-end',
    color:'#484848',
    fontSize:24,      
    lineHeight:30
  },
  btnCalendar:{
    position:'absolute',
    right:15,
    width:90,
    height:90,
    borderRadius:45,
    backgroundColor:'#5cc8ca',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    alignSelf:'center',
    shadowColor: '#000',
    shadowOffset:{
      width: 1,
      height: 1,
    },
    shadowRadius: 3.84,
    elevation: 5,
    shadowOpacity: 0.25,
  },
  imgCalendar:{
    justifyContent:'center',
    alignSelf:'center',
    width:48,
    height:48
  },
  nowStatus: {
    marginRight:4,
    fontSize:10,
    color:'#aeaeae',
  },
  greenStatus: {
    color:'#24eb25',
  },
  orangeStatus: {
    color:'#ebba24',
  },
  redStatus: {
    color:'#eb7524',
  },
  photoProfile: {
    flex:1,
    flexDirection:'row',
    justifyContent:'flex-end',
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
  },
  icPhotoProfile: {
    marginRight:4,
    fontSize:14,
    color:'#9aa5a7',
  },
  numPhotoProfile: {
    color:'#9aa5a7',
    fontSize:14,
    lineHeight:19,
    letterSpacing:-.4,
  },
  btnBlue:{
    width:'100%',
    padding:8,
    height:45,
    backgroundColor:'#5cc8ca',
    borderRadius:999,
    alignItems:"center",
    justifyContent:"center",
    shadowColor: "#10a4c3",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 1,
    elevation: 1,
  },
  txtBtnBlue:{
    fontSize:16,
    fontWeight:'bold',
    lineHeight:22,
    color:'#fff',
    textAlign:'center'
  },
  maskUp: {
    position:'absolute',
    backgroundColor:'#ffffff',
    width:'100%',
    height:'100%',
    left:0,
    top:0,
    zIndex:1,
    elevation:1
  }
});
