import React from 'react';
import { View, FlatList, StyleSheet, Dimensions, Image, Platform } from 'react-native';
import NoItemsBox from '../../components/common/NoItemsBox';
import { RefreshControl } from 'react-native-web-refresh-control';
import ActivityIndicator from '../../components/common/ActivityIndicator'
import LoadMore from '../../components/screens/LoadMore';
import UserUtil from '../../utils/UserUtil';
import Constants from '../../constants/Constants';
import CommonFunction from '../../utils/CommonFunction';
import HeaderOptions from '../../constants/HeaderOptions';
import OverlayWrapper from '../../components/common/OverlayWrapper';
import RenderItemSearchList from '../../components/common/RenderItemSearchList';
import RenderSearchListPanel from '../../components/common/RenderSearchListPanel';
import StorageHelper2 from '../../utils/StorageHelper2';

const screenWidth = Dimensions.get('window').width;
const screenHeight = 0;
const maleItemLayoutHeight = 250;
const femaleItemLayoutHeight = 170;
const maleItemLayoutHeightSmall = (screenWidth-12)/3;

export default class SearchList extends LoadMore {
  constructor() {
    super();
    this.state = {
      ...this.state,
      maskUp: false,
      maskUpStateData: false, 
      numColumns: Constants.numColumnsMale,
      itemLayoutHeight: maleItemLayoutHeight, // for getItemLayout
      isMale: true,
      account: UserUtil.getDefaultAccountAppState()
    }
    this.maxWidth = (screenWidth/Constants.numColumnsMale);
  }

  async componentDidMount(){
    super.componentDidMount();
    if(this._isMounted){
      await this.setColumn();
      this.setBadge();

      // from back page
      this.subscribeFocus = this.props.navigation?.addListener('focus', async() => {
        CommonFunction.setAccessTime();
        let otherTabRef = this.props?.otherTabRef();
        let reloadFlag = false;
        let setColumnFlag = false;
        if(otherTabRef!=undefined){
          if(otherTabRef?.state?.numColumns!=this.state.numColumns){
            reloadFlag = true;
            setColumnFlag = true;
          }
        }else{
          const userProfileAppState = await UserUtil.getUserProfileLocal(this.appContext.appState);
          const userProfile = await CommonFunction.getProfile();
          if(userProfileAppState?.memberId!=userProfile?.memberId){
            reloadFlag = true;
            setColumnFlag = true;
          }
        }
        if(this.state.data?.length==0) reloadFlag = true;
        let { appState, setAppState } = this.appContext;
        if(appState?.reloadApp==1) {
          reloadFlag = true;
          appState.reloadApp = 0;
          setAppState(appState);
        }

        if(setColumnFlag) await this.setColumn();
        if(reloadFlag){
          await this.onReload();
        }else{
          await this.checkRemoveSearchList();
          this.scrollToIndex();
        }
        this.setBadge();
        OverlayWrapper.hide();
      });
      OverlayWrapper.hide();
    }
  }

  componentWillUnmount(){
    super.componentWillUnmount();
    if(this.subscribeFocus!=undefined){
      this.subscribeFocus();
    }
  }

  setColumn = async(userProfile)=>{
    this.setState({maskUpStateData: true}, async()=>{
      if(!userProfile) userProfile = await CommonFunction.getProfile();
      let isFemale = UserUtil.isFeMale(userProfile);
      if(!isFemale && this.state.numColumns==Constants.numColumnsMale) {
        this.setState({maskUpStateData: false});
        return;
      }
      let numColumns = Constants.numColumnsMale;
      let itemLayoutHeight = maleItemLayoutHeight;
      let isMale = true;
      if(isFemale){
        numColumns = Constants.numColumnsFemale;
        itemLayoutHeight = femaleItemLayoutHeight;
        isMale = false;
      }
      this.setState({
        numColumns: numColumns,
        itemLayoutHeight: itemLayoutHeight,
        isMale: isMale,
        maskUpStateData: false
      });
      this.maxWidth = (screenWidth/numColumns);
    });
  }

  scrollToIndex = async()=>{
    if (this.state.sortOrder===2 && this.state.isMale==true) {
      return;
    }
    const viewIndex = await UserUtil.getAppState(this.appContext,'viewIndex');
    if(viewIndex==='') return;
    if(viewIndex>=0 && this.state.data?.length>0){
      let maxIndex = Math.floor((this.state.data?.length-1)/this.state.numColumns);
      let gotoIndex = Math.floor(viewIndex/this.state.numColumns);
      if(maxIndex <0) maxIndex = 0;
      if(gotoIndex > maxIndex) gotoIndex = maxIndex;
      
      if(Platform.OS==='web'){
        try{
          //this.setState({maskUp: true}, ()=>{
            //setTimeout(() => {
              this.scrollRef?.scrollToIndex({animated:false, index: gotoIndex, viewPosition: 0});
              //this.setState({maskUp: false});
              UserUtil.setAppState(this.appContext,'viewIndex','');
            //}, 350);
          //});
        }catch(e){
          if(__DEV__) console.log(e);
          //this.setState({maskUp: false});
        }
      }else{
        this.scrollRef?.scrollToIndex({animated:false, index: gotoIndex, viewPosition: 0});
        UserUtil.setAppState(this.appContext,'viewIndex','');
      }
    }
  }

  checkRemoveSearchList = async()=>{
    let { appState, setAppState } = this.appContext;
    if(appState.setChatHideMemberId!=undefined){
      this.setState({maskUpStateData: true},()=>{
        let filterData = this.state.data;
        filterData = filterData?.filter(function (item) {
          return item.memberId != appState.setChatHideMemberId;
        });
        appState.setChatHideMemberId = null;
        appState.searchResult = filterData;
        setAppState(appState);
        this.setState({
          data: filterData, 
          maskUpStateData: false
        });
      });
    }
  }

  checkIineSearchList = async()=>{
    let { appState, setAppState } = this.appContext;
    let matchList = appState?.matchList;
    if(matchList!=undefined){
      this.setState({maskUpStateData: true}, ()=>{
        let filterData = this.state.data;
        matchList?.forEach((val,index)=>{
          let searchIndex = filterData?.findIndex(item => val == item.memberId);
          if(searchIndex!=-1){
            filterData[searchIndex].goodFlg = 1;
          }
        });
        appState.matchList = null;
        appState.searchResult = filterData;
        setAppState(appState);
        this.setState({
          data: filterData,
          maskUpStateData: false
        });
      });
    }
  }

  noItemDisplay = () => {
    if(this.state.isRefreshing) return <></>;

    const msg = "絞り込みの条件に合うお相手がいません。\n条件を変えて検索してみましょう。";
    return (
      <NoItemsBox msg={msg} />
    );
  }

  getItemLayout = (data, index) => {
    const marginBottom = 20;
    const length = this.state.itemLayoutHeight+marginBottom;// row height
    const offset = length * index;
    return {
      length: length,
      offset: offset,
      index
    }
  }

  // プロフ一覧デザイン変更：ユーザタップ時の処理
  onPressAction = async(listIndex, memberId) => {
    var getPanelData = this.state.data[listIndex];
    var selectitem = '';
    for (var i=0; i<getPanelData.length; i++) {
      var item = getPanelData[i];
      var chMemberId = item.memberId;
      if (chMemberId==memberId) {
        selectitem = item;
        break;
      }
    }
    var selectMemberId = selectitem.memberId;
    var mainImageUrl = selectitem.mainImageUrl;
    var dataList = this.getDataList();

    UserUtil.setAppState(this.appContext,'viewIndex',listIndex);
    await UserUtil.setsearchResult(this.appContext, dataList);
    this.props.navigation?.navigate('Profile',{memberId:selectMemberId, imageUrl:mainImageUrl});
  }

  getDataList() {
    var list = [];
    for (var i=0; i<this.state.data.length; i++) {
      var panels = this.state.data[i];
      for (var p=0; p<panels.length; p++) {
        var item = panels[p];
        if (item) {
          list.push(item);
        }
      }
    }
    return list;
  }

  renderItem = ({ item, index })=>{
    if (this.state.sortOrder===2 && this.state.isMale==true) {
      return <RenderSearchListPanel
        index={index}
        item={item}
        itemLayoutHeight={maleItemLayoutHeightSmall}
        isMale={this.state.isMale}
        isRecommend={this.state.sortOrder===2?true:false}
        onPress={this.onPressAction}
      />    
    } else {
      return <RenderItemSearchList
        index={index}
        item={item} 
        numColumns={this.state.numColumns}
        itemLayoutHeight={this.state.itemLayoutHeight}
        isMale={this.state.isMale}
        isRecommend={this.state.sortOrder===2?true:false}
        onPress={async() => {
          UserUtil.setAppState(this.appContext,'viewIndex',item.listIndex);
          await UserUtil.setsearchResult(this.appContext, this.state.data);
          this.props.navigation?.navigate('Profile',{memberId:item.memberId, imageUrl:item.mainImageUrl});
        }}
      />
    }
  }

  keyExtractor = (item, index)=> {
    if (this.state.sortOrder===2 && this.state.isMale==true) {
      return item?.listIndex.toString();   
    } else {
      return item?.memberId+index.toString();
    }
  }
  
  onViewableItemsChanged = async({viewableItems, changed}) => {
    if (this.state.sortOrder===2 && this.state.isMale==true) {
      for (var i=0; i<viewableItems.length; i++) {
        var items = viewableItems[i];
        for (var l=0; l<items.item.length; l++) {
          var cItem = items.item[l];
          if(cItem.tmMainImageUrl){
            Image.prefetch(cItem.tmMainImageUrl);
          }
        }
      }
    } else {
      for (let ele of viewableItems) {
        if(ele.item?.tmMainImageUrl){
          Image.prefetch(ele.item.tmMainImageUrl);
        }
      }
    }
  };

  render() {
    if ((this.state.loading && this.page === 1) || this.state.maskUpStateData===true) return <ActivityIndicator />;
    //if(this.state.maskUpStateData===true) return <ActivityIndicator />;
    let length = this.state.data.length;
    if(length<42) length = 42;
    return (
      <View style={styles.container}>
        {this.state.maskUp===true && (<View style={styles.maskUp}><ActivityIndicator /></View>)}
        {this.state.sortOrder===2 && this.state.isMale==true ?
          <FlatList 
            contentContainerStyle={
              Platform.OS==='web'?{ 
                marginTop: 4, 
                height: screenHeight,
              }:{ 
                marginTop: 4, 
                flexGrow:1,
            }}
            ref={(ref)=>this.scrollRef=ref}
            refreshControl={
              <RefreshControl
                refreshing={this.state.isRefreshing}
                onRefresh={this.onRefresh}
                long={this.state.longRefreshControl}
              />
            }
            data={this.state.data}
            ListEmptyComponent={this.noItemDisplay}
            renderItem={this.renderItem}
            extraData={this.state.data}
            numColumns={Constants.numPicupColumnsMale}
            keyExtractor={this.keyExtractor}
            ListFooterComponent={this.renderFooter.bind(this)}
            onEndReachedThreshold={0.5}
            onEndReached={this.handleLoadMore.bind(this)}
            onViewableItemsChanged={this.onViewableItemsChanged}
            maxToRenderPerBatch={20}
            removeClippedSubviews={false}
            />
          :
          <FlatList 
            contentContainerStyle={
              Platform.OS==='web'?{ 
                marginTop: 4, 
                height: screenHeight,
              }:{ 
                marginTop: 4, 
                flexGrow:1,
            }}
            ref={(ref)=>this.scrollRef=ref}
            refreshControl={
              <RefreshControl
                refreshing={this.state.isRefreshing}
                onRefresh={this.onRefresh}
                long={this.state.longRefreshControl}
              />
            }
            data={this.state.data}
            ListEmptyComponent={this.noItemDisplay}
            renderItem={this.renderItem}
            extraData={this.state.data}
            numColumns={this.state.numColumns}
            keyExtractor={this.keyExtractor}
            ListFooterComponent={this.renderFooter.bind(this)}
            onEndReachedThreshold={0.5}
            onEndReached={this.handleLoadMore.bind(this)}
            getItemLayout={this.getItemLayout}
            onViewableItemsChanged={this.onViewableItemsChanged}
          />
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    width: '100%', 
    height: '100%',
    backgroundColor: '#ffffff',
    paddingLeft:4,
    paddingRight:4,
    ...Platform.select({
      ios: { /*marginBottom: 50*/ },
      android: { /*paddingBottom: 50*/ },
      default: { paddingBottom: HeaderOptions.bottomTabHeight-1 }
    }),  
  },
  listItem:{
    flex:1, 
  },
  listItemMale:{
    flex:1, 
  },
  list:{},
  newUser:{
    position:'absolute', 
    left:2, 
    top:0,
    width:50,
    height:50,
  },
  newUserText:{
    color:'#fff', 
    fontSize:8,
    fontWeight:'bold',
    textAlign: 'center',
  },
  newUserImg: {
    width:50,
    height:50,
  },
  maskUp: {
    position:'absolute',
    backgroundColor:'#ffffff',
    width:'100%',
    height:'100%',
    left:0,
    top:0,
    zIndex:1,
    elevation:1
  }
});
