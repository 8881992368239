import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image, Platform } from 'react-native';
import styles from '../../styles/UserInfoStyles';
import Carousel from 'react-native-snap-carousel';

export default class MessageTemplate extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.templateSwiper = React.createRef();
    this.templateItemWidth = this.props.templateItemWidth;
    this.templateSliderWidth = this.props.templateSliderWidth;
    this.myTemplateTitle = "マイ定型メッセージ";
    this.defaultTemplateTitle = "定型メッセージテンプレート";
    this.myLocalTemplateTitle = "前回送信したメッセージを使用する";
    this.template = this.props.template||[];
    this.firstItem = this.props.firstItem || 0;
    this.firstItemId = this.props.firstItemId;
    this.firstItemIndex = -1;
    if(this.firstItemId!=undefined){
      this.firstItemIndex = this.template?.findIndex(
        item => this.firstItemId == item?.id
      );
      if(this.firstItemIndex!=-1) this.firstItem = this.firstItemIndex;
    }
    if(this.firstItem>0 && this.firstItem >= this.template?.length){
      this.firstItem = this.template?.length - 1;
    }
    this.state = {
      template: this.template,
      titleTemplate: this.getDefaultTitleTemplate(this.template),
      imgCheckMsg: 0,
      loading: true
    }
    this.offset = 0;
    this.contentOffset = 5;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      setTimeout(() => {
        this.setState({loading: false});
      }, 100);
      setTimeout(() => {
        this.setSnapToItem(this.firstItem);
      }, 500);
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.template && prevProps && this.props.template!=prevProps.template){
      this.setState({template: this.props.template});
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  getDefaultTitleTemplate = (template)=>{
    let title = '';
    const currentTemplate = template[this.firstItem];
    if(currentTemplate!=undefined){
      if(currentTemplate?.myTemplate==1){
        title = `${this.myTemplateTitle}${currentTemplate?.counter}`;
      }else if(currentTemplate?.myTemplate==2){
        title = this.myLocalTemplateTitle;
      }else{
        title = `${this.defaultTemplateTitle}${currentTemplate?.counter}`;
      }
    }

    return title;
  }

  setTitleTemplate = ()=>{
    let title = '';
    if(this.templateSwiper!=undefined){
      const currentIndex = this.templateSwiper.currentIndex;
      const currentTemplate = this.state.template[currentIndex];
      if(currentTemplate!=undefined){
        if(currentTemplate?.myTemplate==1){
          title = `${this.myTemplateTitle}${currentTemplate?.counter}`;
        }else if(currentTemplate?.myTemplate==2){
          title = this.myLocalTemplateTitle;
        }else{
          title = `${this.defaultTemplateTitle}${currentTemplate?.counter}`;
        }
      }
    }

    this.setState({titleTemplate: title});
  }

  //web
  onTouchStart = ()=>{
    this.scrollDirection = '';
  }

  //web
  onTouchRelease = ()=>{
    if(this.scrollDirection=='left'){
      this.templateSwiper?.snapToPrev();
    }else if(this.scrollDirection=='right'){
      this.templateSwiper?.snapToNext();
    }
    this.scrollDirection ='';
    setTimeout(() => {
      this.onSnapToItem();
    }, 500);
  }

  setSnapToItem = (index)=>{
    let currentIndex = index;
    if(currentIndex==undefined){
      currentIndex = this.templateSwiper?.currentIndex;
    }
    if(currentIndex<0) return;

    const activeId = this.state.template[currentIndex]?.id;
    if(this.props.onSnapToItem!=undefined) this.props.onSnapToItem(currentIndex,activeId)
  }

  onSnapToItem = (index)=>{
    this.setSnapToItem(index);
    this.setTitleTemplate();
  }

  onCarouselScroll=(event) => {
    if(Platform.OS==='web'){
      const currentOffset = event.nativeEvent?.contentOffset?.x;
      if(currentOffset!=this.offset && Math.abs(currentOffset-this.offset)>this.contentOffset){
        this.scrollDirection = currentOffset > this.offset ? 'right' : 'left';
        this.offset = currentOffset;
      }
    }
  }
  
  render() {
    if(!this.state.template || this.state.template.length==0) return <></>;

    let initialNumToRender = 10;
    if(this.state.template?.length>10) initialNumToRender = this.state.template?.length;

    return (
      <View>
        <View style={styles.blogTxtTitleMsg}>
          <Text style={[styles.txtTitleMsg,{height:20}]}>{this.state.titleTemplate}</Text>
        </View>
        <View style={{width:'100%'}}>
        {this.state.loading===true ?
          <View style={{position:'absolute', backgroundColor:'#fff', width:'100%', height:'100%', left:0,top:0,zIndex:1}}></View>
        :null}
          <Carousel
            ref={swiper => {this.templateSwiper=swiper}}
            sliderWidth={this.templateSliderWidth}
            itemWidth={this.templateItemWidth}
            activeSlideAlignment={"start"}
            activeSlideOffset={1}
            enableSnap={Platform.OS==='web'?false:true}
            data={this.state.template}
            extraData={this.state.template}
            keyExtractor={item => item.id}
            initialNumToRender={initialNumToRender}
            firstItem={this.firstItem}
            onScroll={Platform.OS==='web'?this.onCarouselScroll:""}
            onTouchStart={()=>{
              // web
              if(Platform.OS==='web'){
                this.onTouchStart();
              }
            }}
            onTouchRelease={(event)=>{
              // web
              if(Platform.OS==='web'){
                this.onTouchRelease(event);
              }
            }}
            onSnapToItem = { index => {
              // app
              if(Platform.OS!=='web'){
                this.onSnapToItem();
              }
            }}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity activeOpacity={1} onPress={()=>{
                  if(this.props.onpressTemplate){
                    this.setState({imgCheckMsg: item.id});
                    this.props.onpressTemplate(item);
                  }
                }}>
                <View style={styles.blogTxtDescMsg}>
                  <Text numberOfLines={2} style={styles.txtDescMsg}>{item.text2}</Text>
                  {this.state.imgCheckMsg==item.id ?
                    <Image style={styles.imgCheckMsg} source={require('../../assets/images/icon/check-on.png')}/>
                  :
                    <Image style={styles.imgCheckMsg} source={require('../../assets/images/icon/check-off.png')}/>
                  }
                </View>
              </TouchableOpacity>
            )}}
          />
          </View>
      </View>
    );
  }
}