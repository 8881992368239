import SearchList from '../../screens/search/SearchList';
import Storage from '../../utils/StorageHelper2';
import Constants from '../../constants/Constants';
import CommonFunction from '../../utils/CommonFunction';
import UserUtil from '../../utils/UserUtil';
import Badge14Api from '../../services/api/Badge14Api';
import Search03Api from '../../services/api/Search03Api';
import StorageHelper from '../../utils/StorageHelper2';
import { Platform, Image } from 'react-native';

const fInterval = 5;
const oInterval = 17;

export default class SearchOrder extends SearchList {
  constructor() {
    super();
    this.templateOrder = { tplType01: 0, tplType02: 1, tplType03: 2, tplType04: 3, tplType05: 4, tplTypeNone: 5 };
    this.nextData = [];
    this.searchParams = {
      count: 42,
      page: 1,
      sortOrder: 0,
      mainImage: '',
      currentPref: 0,
      ageFrom: '',
      ageTo: '',
      heightFrom: '',
      heightTo: '',
      style: 0,
      vibe: 0,
      personality: 0,
      occupation: 0,
      income: 0,
      assets: 0,
      education: 0,
      birthCity: 0,
      alcohol: 0,
      cigarette: 0,
      holiday: 0,
      dateHope: 0,
      meetHope: 0,
      lastLoginTime: 0,
      recentJoin: 0,
    };

    // set api not check access time
    Search03Api.setCheckAccessFlg(false);
  }

  loadComplete(){
    this.setState({
      loading: false,
      isRefreshing: false
    });
  }

  // set badge
  async setBadge(){
    try{
      let badgeResponse = await Badge14Api.GetBadge();
      if(badgeResponse?.status==1){
        let badgeList = badgeResponse?.response;
        if(this.props.setBadge!=undefined){
          this.props?.setBadge(badgeList);
        }
        if(this.props.setTopBadge!=undefined){
          this.props?.setTopBadge(badgeList);
        }
      }
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  async setPrivilegeFlag(privilegeFlag){
    if(this.props.setPrivilegeFlag!=undefined){
      this.props?.setPrivilegeFlag(privilegeFlag);
    }
  }

  async setListIndex(data, oriCount){
    for (var i = oriCount; i < data.length; i++) {
      var item = data[i];
      item["listIndex"] = i;
    }
  }

  async setMaleSerchListPanelData(data,getProfileList,image){      
    const nextVal = getProfileList?.nextVal;
    var getData = getProfileList?.list;
    var items = [];
    var addItems = [];
    var typeIndex = '';
    var tplId = '';
    var nextTplId = '';
    var itemCount = 0;
    var nextItemCount = 0;

    if (data.length==0) {
      typeIndex = this.getStartIndex();
      if (getData.length<4) {
        // 4件以下の時のレイアウトは固定
        typeIndex = 0;
      }
      tplId = this.getIndexForTemplateId(typeIndex,0);
      nextTplId = this.getIndexForTemplateId(typeIndex,1);
    } else {
      // 追加読み込み時に直前のデータチェックと表示させる操作が必要
      if (this.nextData != undefined){
        if (this.nextData.length > 0) {
          var addData = this.nextData;
          for (var k=0; k<addData.maxCount; k++) {
            var addItem = getData[0];
            CommonFunction.thumbnailUrlConverSizeSingle(addItem,image.small);
            addData.push(addItem);
            getData.shift();
            if (addData.length==addData.maxCount) {
              break;
            }
          }
          addData = await this.changeImageCount(addData);
          addData = await this.changeLargeFlag(addData);
          if (this.checkAddTemplateForSmall(addData.typeIndex)==false) {
            CommonFunction.thumbnailUrlConverSizeSingle(addData[0],image.large);
          }
          data.push(addData);
          this.nextData = [];
        }
      }
      var last = data.slice(-1)[0];
      var lastTypeIndex = last.typeIndex;
      lastTypeIndex++;
      if (lastTypeIndex >= 14) {
        lastTypeIndex = 0;
      }
      typeIndex = lastTypeIndex

      if (this.checkAddTemplateForSmall(typeIndex)) {
        var addSmallData = [];
        for (var s=0; s<3; s++) {
          var addItem = getData[0];
          CommonFunction.thumbnailUrlConverSizeSingle(addItem,image.small);
          addSmallData.push(addItem);
          getData.shift();
          if (addSmallData.length==3) {
            break;
          }
        }
        var count = addSmallData.length;
        addSmallData["typeIndex"] = typeIndex;
        addSmallData["tpl"] = this.templateOrder.tplTypeNone;
        addSmallData["maxCount"] = 3;
        addSmallData["count"] = count
        data.push(addSmallData);
        typeIndex++;
        if (typeIndex >= 14) {
          typeIndex = 0;
        }
      }
      tplId = this.getIndexForTemplateId(typeIndex,0);
      nextTplId = this.getIndexForTemplateId(typeIndex,1);
    }
    for (var i = 0; i < getData.length; i++) {
      itemCount = this.getTemplateDataCount(tplId);
      nextItemCount = this.getTemplateDataCount(nextTplId);

      if (addItems.length <= itemCount+nextItemCount) {
        addItems.push(getData[i]);
        if (addItems.length == itemCount+nextItemCount ||  i==getData.length-1) {
          // 画像無し差し替え
          addItems = await this.changeImageCount(addItems);
          addItems = await this.changeLargeFlag(addItems);
          var add = [];
          var add2 = [];
          for (var q=0; q<addItems.length; q++) {
            var addData = addItems[q];
            if (q==0) {
              CommonFunction.thumbnailUrlConverSizeSingle(addData,image.large);
            } else {
              CommonFunction.thumbnailUrlConverSizeSingle(addData,image.small);
            }
            if (q<itemCount) {
              add.push(addData);
            } else {
              add2.push(addData);
            }
          }          
          if (add.length>0) {
            var tpl = this.getIndexForTemplateId(typeIndex,0);
            add["typeIndex"] = typeIndex;
            add["tpl"] = tpl;
            add["maxCount"] = itemCount;
            add["count"] = add.length;
            if (add.length<itemCount && nextVal==1) {
              this.nextData = add;
            } else {
              items.push(add);            
              typeIndex++;
              if (typeIndex>=14) {
                typeIndex = 0;
              }
            }
          }
          if (add2.length>0) {
            var tpl = this.getIndexForTemplateId(typeIndex,0);
            add2["typeIndex"] = typeIndex;
            add2["tpl"] = tpl;
            add2["maxCount"] = nextItemCount;
            add2["count"] = add2.length;
            if (add2.length<nextItemCount && nextVal==1) {
              this.nextData = add2;
            } else {
              items.push(add2);
              typeIndex++;
              if (typeIndex>=14) {
                typeIndex = 0;
              }   
            }
          }
          tplId = this.getIndexForTemplateId(typeIndex,0);
          nextTplId = this.getIndexForTemplateId(typeIndex,1);
          addItems = [];
        }
      }
    }
    data = data.concat(items);
    return data;
  }

  async changeImageCount(itemData){
    if (itemData[0].imageCount==0) {
      var chIndex2 = 0;
      for (var l=0; l<itemData.length; l++) {
        var cItem = itemData[l];
        if (cItem.imageCount!=='0') {
          chIndex2 = l;
          break;
        }
      }
      if (chIndex2>0) {
        const change2 = itemData[chIndex2];
        itemData.splice(chIndex2, 1);
        itemData.unshift(change2);
      }
    }
    return itemData;
  }

  async changeLargeFlag(itemData){
    var chIndex = 0;
    var changeFlg = false;
    var chSecIndex = 0;
    var changeSecFlg = false;
    // 大きい表示差し替え
    for (var p=0; p<itemData.length; p++) {
      var cItem = itemData[p];
      if (cItem.largeFlag==='1') {
        // 10代か20代かを判定
        var age = Number(cItem.age.replace('歳', ''));
        if (age<30 && age!=0) {
          chIndex = p;
          changeFlg = true;
          break;  
        } else {
          if(changeSecFlg==false) {
            changeSecFlg = true;
            chSecIndex = p;          
          }
        }
      }
    }
    if (changeFlg == true) {
      if (chIndex>0) {
        const change = itemData[chIndex];
        itemData.splice(chIndex, 1);
        itemData.unshift(change);
      }
    } else {
      if (chSecIndex>0) {
        const change = itemData[chSecIndex];
        itemData.splice(chSecIndex, 1);
        itemData.unshift(change);
      }
    }

    return itemData;
  }

  getTemplateDataCount = (tplId)=>{
    var itemCount = 0;
    switch(Number(tplId)){
      case this.templateOrder.tplType01:
        itemCount = 1;
        break; 
      case this.templateOrder.tplType02:
      case this.templateOrder.tplType03:
      case this.templateOrder.tplTypeNone:
        itemCount = 3;
        break; 
      case this.templateOrder.tplType04:
      case this.templateOrder.tplType05:
        itemCount = 4;
        break;
    }
    return Number(itemCount);
  }

  // 開始するテンプレートを判定
  getStartIndex = ()=>{
    let date = new Date();
    var dateMin = date.getMinutes();
    var strMin  = dateMin.toString().slice( -1 );
    var typeIndex = 0;
    switch(Number(strMin)){
      case 0:
      case 1:
        typeIndex = 2;
        break;
      case 2:
      case 3:
        typeIndex = 4;
        break;
      case 4:
      case 5:
        typeIndex = 6;
        break;
      case 6:
      case 7:
        typeIndex = 8;
        break;
      case 8:
      case 9:
        typeIndex = 12;
        break;
    }
    return typeIndex;
  }

  getIndexForTemplateId = (typeIndex, next)=>{
    if (next==true) {
      typeIndex++;
      if (typeIndex>=14) {
        typeIndex = 0;
      }
    }
    var typeId = 0;
    switch(Number(typeIndex)){
      case 0: typeId = this.templateOrder.tplType01; break;
      case 1: typeId = this.templateOrder.tplTypeNone; break;
      case 2: typeId = this.templateOrder.tplType02; break;
      case 3: typeId = this.templateOrder.tplTypeNone; break;
      case 4: typeId = this.templateOrder.tplType03; break;
      case 5: typeId = this.templateOrder.tplTypeNone; break;
      case 6: typeId = this.templateOrder.tplType04; break;
      case 7: typeId = this.templateOrder.tplTypeNone; break;
      case 8: typeId = this.templateOrder.tplType03; break;
      case 9: typeId = this.templateOrder.tplTypeNone; break;
      case 10: typeId = this.templateOrder.tplType02; break;
      case 11: typeId = this.templateOrder.tplTypeNone; break;
      case 12: typeId = this.templateOrder.tplType05; break;
      case 13: typeId = this.templateOrder.tplTypeNone; break;
    }
    return typeId;
  }

  checkAddTemplateForSmall = (typeIndex)=>{
    var isCheck = false;
    switch(Number(typeIndex)){
      case 1:
      case 3:
      case 5:
      case 7:
      case 9:
      case 11:
      case 13:
        isCheck = true;
        break;
    }
    return isCheck;
  }

  async getSearchList(page, sortOrder=1){
    if (this._isMounted) {
      if(!this.state.isRefreshing){
        this.setState({ loading: true });
      }

      const userProfileAppState = this.appContext.appState?.userProfile;
      const userProfile = await CommonFunction.getProfile();
      if(userProfileAppState?.memberId!=userProfile?.memberId){
        await this.setColumn(userProfile);
        this.appContext.appState.userProfile = userProfile;
        this.appContext?.setAppState(this.appContext.appState);
      }

      let searchInfo = await CommonFunction.getSearchHistory(userProfile?.memberId);
      let searchMale = false;
      if(userProfile?.sex==Constants.FEMALE){
        searchMale = true;
      }

      this.searchParams.page = page;
      this.searchParams.sortOrder = sortOrder;
      if(searchInfo!=null){
        this.searchParams.mainImage = searchInfo.mainImage?1:0;
        this.searchParams.currentPref = CommonFunction.makeTabData(searchInfo.adds);
        if(Array.isArray(searchInfo.age) && searchInfo.age.length>0){
          this.searchParams.ageFrom = searchInfo.age[0];
          this.searchParams.ageTo = searchInfo.age[1];
        }else{
          this.searchParams.ageFrom = '';
          this.searchParams.ageTo = '';
        }
        if(Array.isArray(searchInfo.height) && searchInfo.height.length>0){
          this.searchParams.heightFrom = searchInfo.height[0];
          this.searchParams.heightTo = searchInfo.height[1];
        }else{
          this.searchParams.heightFrom = '';
          this.searchParams.heightTo = '';
        }
        this.searchParams.style = CommonFunction.makeTabData(searchInfo.style);
        this.searchParams.occupation = CommonFunction.makeTabData(searchInfo.occupation);
        this.searchParams.education = CommonFunction.makeTabData(searchInfo.education);
        this.searchParams.birthCity = CommonFunction.makeTabData(searchInfo.born);
        if(typeof searchInfo.alcohol.id !='undefined'){
          this.searchParams.alcohol = searchInfo.alcohol.id;
        }else{
          this.searchParams.alcohol = 0;
        }
        if(typeof searchInfo.cigarette.id !='undefined'){
          this.searchParams.cigarette = searchInfo.cigarette.id;
        }else{
          this.searchParams.cigarette = 0;
        }
        this.searchParams.holiday = CommonFunction.makeTabData(searchInfo.holiday);
        this.searchParams.dateHope = CommonFunction.makeTabData(searchInfo.dateHope);
        this.searchParams.meetHope = CommonFunction.makeTabData(searchInfo.meetHope);
        if(typeof searchInfo.lastLoginTime.id !='undefined'){
          this.searchParams.lastLoginTime = searchInfo.lastLoginTime.id;
        }else{
          this.searchParams.lastLoginTime = 0;
        }
        this.searchParams.recentJoin = searchInfo.recentJoin?1:0;
       
        if(searchMale){
          this.searchParams.income = CommonFunction.makeTabData(searchInfo.income);
          this.searchParams.assets = CommonFunction.makeTabData(searchInfo.assets);
        }else{
          this.searchParams.vibe = CommonFunction.makeTabData(searchInfo.vibe);
          this.searchParams.personality = CommonFunction.makeTabData(searchInfo.personality);
        }
      }
      
      try{
        const getListResponse = await Search03Api.SearchProfileGetList(this.searchParams);     
        //check response status
        if(!CommonFunction.checkResponseStatus(getListResponse,false)) return;
        
        if(getListResponse!=undefined && getListResponse?.status==1){
          CommonFunction.setAccessTime();

          StorageHelper.storeData('ikuyoStatus', Number(getListResponse.ikuyoStatus));
          if(this.props.setIkuyoStatus!=undefined){
            this.props?.setIkuyoStatus(Number(getListResponse.ikuyoStatus));
          }

          const account = UserUtil.getAccountAppState(getListResponse, this.appContext);
          const profileList = getListResponse?.response?.profileList;
          let data = this.state.data;
          //if(Platform.OS!=='web' && this.state.isRefreshing) data = [];
          if(this.state.isRefreshing) data = [];
          const imageSize = getListResponse?.response?.thumbnailSize;
          let longRefreshControl = true;
          if(profileList!=undefined){
            if(profileList?.count > 0){
              let oriCount = data.length;
              if (sortOrder==2 && !searchMale) {
                data = await this.setMaleSerchListPanelData(data, profileList, imageSize);
                for (var i=oriCount; i<data.length; i++) {
                  const items = data[i];
                  for(var l=0; l<items.length; l++) {
                    const cacheUrl = items[l].tmMainImageUrl;
                    if (i<=3) {
                      await Image.prefetch(cacheUrl);
                    } else {
                      Image.prefetch(cacheUrl);
                    }
                  }
                }
              } else {
                data = data.concat(profileList?.list);
                CommonFunction.thumbnailUrlConver(data);
                for (var i=oriCount; i<data.length; i++) {
                  const cacheUrl = data[i].tmMainImageUrl;
                  if(cacheUrl){
                    if(i<=10){
                      await Image.prefetch(cacheUrl);
                    }else{
                      Image.prefetch(cacheUrl);
                    }
                  }
                }
              }
              this.setListIndex(data,oriCount);
            }
            this.no_more = (profileList?.nextVal == 0)?1:0;
            if(data?.length >0){
              longRefreshControl = false;
            }
          }
          this.setState({
            account: account,
            data: data,
            sortOrder: sortOrder,
            longRefreshControl: longRefreshControl
          });

          this.setPrivilegeFlag(getListResponse?.response?.privilegeFlag)
        }
      }catch(e){
        if(__DEV__) console.log(e);
      }finally{
        this.loadComplete(); 
      }
    }
  }
}
