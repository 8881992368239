import ApiPappy from '../../utils/ApiPappy';
import ApiConfig from '../../constants/ApiConfig';
import ResponseConstants from '../../constants/ResponseConstants';
import AuthHelper from '../../utils/AuthHelper';
import CommonFunction from '../../utils/CommonFunction';
//import Constants from '../../constants/Constants';
import WebUtil from '../../utils/WebUtil';
import AppUtil from '../../utils/AppUtil';

/**
 * API: 03_API仕様\レスポンス仕様書\05_メッセージ
 * Design: 02_画面仕様\13_メッセージ
 * Document: 02_画面仕様\13_メッセージ.xlsx
 */
export default class MailBoxApi extends ApiPappy{
  //1.1.1.メッセージBOX一覧
  static async MailBoxList(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/MailBox/Get/MailBoxList/",
        {
          count: (values?.count!=undefined)?values.count:15, 
          page: (values?.page!=undefined)?values.page:1, 
          type: (values?.type!=undefined)?values.type:1, 
          favorite: (values?.favorite!=undefined)?values.favorite:0,
          noReply: (values?.noReply!=undefined)?values.noReply:0,
        },
        true
      );
      if(__DEV__) console.log('/MailBox/Get/MailBoxList/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.MailBoxGetMailBoxList));
            console.log('/MailBox/Get/MailBoxList/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.1.2.メッセージ履歴
  static async MailBoxDetail(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/MailBox/Get/MailBoxDetail/",
        {
          count: (values?.count!=undefined)?values.count:15, 
          page: (values?.page!=undefined)?values.page:1, 
          targetMemberId: values?.targetMemberId
        },
        true
      );
      if(__DEV__) console.log('/MailBox/Get/MailBoxDetail/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.MailBoxGetMailBoxDetail));
            console.log('/MailBox/Get/MailBoxDetail/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.2.1.メッセージ送信
  static async SendMail(values, showError=1){
    const encodeData = true;
    const encodeExcludeVar = ['imageFile'];
    try{
      let webUUID = await WebUtil.getWebUUID();
      let adid = await AppUtil.getAdid();
      let parameter = {
        targetMemberId: values?.targetMemberId, 
        messageType: values?.messageType, 
        body: values?.body, 
        imageFile: values?.imageFile,
        web_uuid: webUUID,
        adid: adid
      }
      if(values?.code!=undefined) parameter.code = values.code;
      if(values?.forceSubtract!=undefined) parameter.forceSubtract = 1;

      const response = await this.post(
        ApiConfig.pappy_api_url+"/MailBox/Post/SendMail/",
        parameter,
        true,
        encodeData,
        encodeExcludeVar
      );
      if(__DEV__) console.log('/MailBox/Post/SendMail/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/MailBox/Post/SendMail/', responseField?.join(','));
          }

          $return = data;
          if(data?.status!=1){
            // error
            if(showError){
              CommonFunction.checkErrorCode(data);
            }else{
              CommonFunction.checkErrorCodeWithoutAlert(data);
            }
          }
        }
      }else AuthHelper.showNetworkError();
      
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.1.お気に入り設定
  static async Favorite(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/MailBox/Put/Favorite/",
        {
          messageBoxId: (values?.messageBoxId!=undefined)?values.messageBoxId:0,
          favorite: (values?.favorite!=undefined)?values.favorite:0,
        },
        true
      );
      if(__DEV__) console.log('/MailBox/Put/Favorite/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.MailBoxPutFavorite));
            console.log('/MailBox/Put/Favorite/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.2.お気に入り名称一覧取得
  static async FavoriteNameList(){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/MailBox/Get/FavoriteName/",
        {
        },
        true
      );
      if(__DEV__) console.log('/MailBox/Get/FavoriteName/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.FavoriteNameList));
            console.log('/MailBox/Get/FavoriteName/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.3.3.お気に入り名称設定
  static async FavoriteName(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/MailBox/Put/FavoriteName/",
        {
          id: (values?.favorite!=undefined)?values.favorite:0,
          name: (values?.name!=undefined)?values.name:null,
        },
        true
      );
      if(__DEV__) console.log('/MailBox/Put/FavoriteName/',response);

      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, 
              ResponseConstants.CommonResponseField.concat(ResponseConstants.FavoriteName));
            console.log('/MailBox/Put/FavoriteName/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();

      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }

  //1.4.1.メッセージ削除（現在は画像のみ）
  static async MailBoxDeleteMessage(values){
    try{
      const response = await this.post(
        ApiConfig.pappy_api_url+"/MailBox/Delete/Message/",
        {
          messageId: (values?.messageId!=undefined)?values.messageId:0,
        },
        true
      );
      if(__DEV__) console.log('/MailBox/Delete/Message/',response);
      let $return = {};
      if(response!=undefined){
        if(response?.status==200){
          const data = response?.data;
          if(__DEV__) {
            // check response field
            const responseField = CommonFunction.checkAllResponseField(data, ResponseConstants.CommonResponseField);
            console.log('/MailBox/Delete/Message/', responseField?.join(','));
          }
          $return = data;
          if(data?.status!=1){
            // error
            let res = data?.response;
            const errorGroup = res?.errorGroup;
            const errorCode = res?.errorCode;
            let errorMessage = res?.errorMessage;
            const code = `${errorGroup}-${errorCode}`;
            if(errorMessage){
              if(code=='10-00007'){
                return $return;
              }
            }
            CommonFunction.checkErrorCode(data);
          }
        }
      }else AuthHelper.showNetworkError();
      return $return;
    }catch(e){
      if(__DEV__) console.log(e);
    }
  }
}