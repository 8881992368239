import React, { Component } from 'react';
import {View, Text, TouchableOpacity, StyleSheet, Image, Platform} from 'react-native';
import { Entypo } from '@expo/vector-icons';

export default class ChatRoomMemo extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.memoText = this.props.memoText;
      if(this.props?.show===true){
        this.show();
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = ()=>{
    this.setState({ show: true});
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  render() {
    if(!this.state.show) return null;
    var notText = true;
    var text = this.props?.memoText;
    if (text.length>0) {
      notText = false;
      text = text.replaceAll("\n"," ");
    }

    return (
      <>
      <View style={styles.container}>
        <TouchableOpacity style={styles.flexRowCenter} onPress={()=>{
          this.props.inputMemo()
        }}>
          <View>
            <Image style={styles.icMemo} source={require('../../assets/images/icon/memo-icon.png')}/>
          </View>
          <View style={{flex:1, paddingTop:0, paddingLeft:10, paddingRight:10}}>
            {notText?
              <Text numberOfLines={1} style={styles.notMemoText}>このお相手に関する自分用のメモを残せます</Text>
            :
              <Text numberOfLines={1} style={styles.memoText}>{text}</Text>
            }
          </View>
          <Entypo name="chevron-right" style={styles.icArrowRight} />
        </TouchableOpacity>
      </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    paddingTop:5,
    paddingRight:15,
    paddingBottom:10,
    paddingLeft:15, 
    width:'100%',
    height:36,
    alignSelf:'center',
    alignItems:'center',
    alignContent:'center',
    backgroundColor:'#fff',
    elevation:1,
    zIndex:1,
    borderBottomWidth:1,
    borderBottomColor:'#e6e6e6',
  },
  flexRowCenter:{
    width:'100%',
    height:30,
    flexDirection:'row',
    alignItems:'center',
  },
  icMemo:{
    width:20, 
    height:18,
    marginBottom:5,
  },
  notMemoText:{
    marginBottom:5,
    fontSize:13,
    lineHeight:24,
    color:'#e4007f',
    textAlign:'left',
  },
  memoText:{
    marginBottom:5,
    fontSize:12,
    lineHeight:24,
    color:"#333",
    textAlign:'left',
  },
  icArrowRight:{
    color:'#e4007f',
    fontSize:24,
    lineHeight:22,
    ...Platform.select({
      web:{
        lineHeight:24,
      }
    })
  },
});