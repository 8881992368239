import React, { Component } from 'react';
import {Dimensions, View, Text, TouchableOpacity, StyleSheet, Image, Platform} from 'react-native';
import { AntDesign, Entypo } from '@expo/vector-icons';

const windowWidth = Dimensions.get('window').width;

export default class ChatWarning extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      toggle: false
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props.navigation;
      this.memberId = this.props.memberId;
      this.matchFlg = this.props.matchFlg;
      if(this.props?.show===true){
        this.show();
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  show = ()=>{
    this.setState({ show: true});
  }

  hide = ()=>{
    this.setState({ show: false});
  }

  setToggle = ()=>{
    const toggle = this.state.toggle;
    this.setState({toggle: !toggle});
  }

  render() {
    if(!this.state.show) return null;

    let hight = 35;
    if (this.matchFlg==1) {
      hight = 75;
    }

    return (
      <>
      <View style={styles.container}>
        <TouchableOpacity style={styles.flexRowCenter} onPress={()=>{
          this.setToggle();
        }}>
          <View>
            <AntDesign name="warning" style={styles.icWarning} />
          </View>
          <View style={{flex:1, paddingLeft:10, paddingRight:10}}>
            {/*<Text style={styles.titleWarning}>迷惑行為や不審なお相手は通報にてお知らせください</Text>*/}
            <Image style={styles.imgWarning} source={require('../../assets/images/textWarning.png')} resizeMode='contain' />
          </View>
          <View>
            {this.state.toggle?
              <Entypo name="chevron-up" style={styles.icArrowUp} />
            :
              <Entypo name="chevron-down" style={styles.icArrowUp} />
            }
          </View>
        </TouchableOpacity>
      </View>
      {this.state.toggle?
        <View style={[styles.animateContainer, {top: hight}]}>
          <View style={{marginTop:5}}>
            <Text style={styles.textWarning}>安心してPappyをご利用頂くため、迷惑行為をおこなう会員の取り締まり強化を行っております。</Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.textWarningLi}>・</Text>
            <Text style={styles.textWarningUnderlineLi}>お会いした際に勧誘されたり、事前の約束を守らなかった </Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.textWarning}>・</Text>
            <Text style={styles.textWarningUnderline}>約束した当日に、連絡もなく待ち合わせ場所に来なかった </Text>
          </View>
          <View>
            <Text style={styles.textWarning}>このような迷惑行為を受けた場合、通報にてお知らせください。</Text>
          </View>
          <View style={[styles.flexRow, {alignItems:'center', justifyContent:'center', width:windowWidth<375? '65%' :'55%'}]}>
            <TouchableOpacity style={styles.btnReport} onPress={()=>{
              if(this.memberId){
                this.navigation.navigate('Report',{memberId: this.memberId});
                this.setToggle();
              }
            }}>
              <Text style={styles.txtbtnReport}>この会員を通報する</Text>
              <Entypo name="chevron-right" style={styles.icArrowRight} />
            </TouchableOpacity>
          </View>
        </View>
        :
        <></>
        }
      </>
    );
  }
}

const styles = StyleSheet.create({
  container:{
    paddingTop:10,
    paddingRight:15,
    paddingBottom:10,
    paddingLeft:15, 
    width:'100%',
    height:40,
    alignSelf:'center',
    alignItems:'center',
    alignContent:'center',
    backgroundColor:'#eef1f2',
    elevation:1,
    zIndex:1,
  },
  animateContainer:{
    position:'absolute',
    top:35,
    paddingRight:15,
    paddingBottom:15,
    paddingLeft:15,
    width:'100%',
    alignSelf:'center',
    alignItems:'center',
    alignContent:'center',
    backgroundColor:'#eef1f2',
    zIndex:1,
  },
  flexRow:{
    flexDirection:'row',
  },
  flexRowCenter:{
    width:'100%',
    height:20,
    flexDirection:'row',
    alignItems:'center',
  },
  icWarning:{
    color:'#484848',
    fontSize:18,
    ...Platform.select({
      web:{
        lineHeight:24,
      }
    })
  },
  imgWarning:{
    width:'100%',
    height:14,
  },
  icArrowUp:{
    color:'#484848',
    fontSize:24,
    lineHeight:22,
    ...Platform.select({
      web:{
        lineHeight:24,
      }
    })
  },
  titleWarning:{
    marginBottom:10,
    fontSize:14,
    fontWeight:'bold',
    lineHeight:22,
    color:'#484848',
    textAlign:'left',
  },
  textWarning:{
    marginBottom:10,
    fontSize:13,
    fontWeight:'normal',
    lineHeight:20,
    color:'#484848',
    textAlign:'left',
  },
  textWarningLi:{
    fontSize:13,
    fontWeight:'normal',
    lineHeight:20,
    color:'#484848',
    textAlign:'left',
  },
  textWarningUnderline:{
    marginBottom:10,
    fontSize:13,
    fontWeight:'normal',
    lineHeight:20,
    color:'#484848',
    textAlign:'left',
    textDecorationLine:'underline',
  },
  textWarningUnderlineLi:{
    fontSize:13,
    fontWeight:'normal',
    lineHeight:20,
    color:'#484848',
    textAlign:'left',
    textDecorationLine:'underline',
  },
  btnReport: {
    position:'relative',
    margin:'auto',
    paddingLeft:20,
    paddingRight:30,
    width:'100%',
    height:30,
    backgroundColor:'#9fa7aa',
    borderRadius:999,
    flexDirection:'row',
    alignItems:"center",
    justifyContent:"center",
  },
  txtbtnReport:{
    fontSize:14,
    fontWeight:'normal',
    lineHeight:30,
    color:'#fff',
    textAlign:'center'
  },
  icArrowRight:{
    position:'absolute',
    top:0,
    right:0,
    alignItems:'flex-end',
    color:'#fff',
    fontSize:18,      
    lineHeight:30,
  },
});