import React,{ Component } from 'react';
import { StyleSheet, Text, FlatList, View, Platform, StatusBar, TouchableOpacity, Dimensions, Linking } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import HeadStyle from '../styles/HeadStyles';
import CommonFunction from '../utils/CommonFunction';
import RightSlidePopup from '../components/common/RightSlidePopup';
import ApiConfig from '../constants/ApiConfig';
import UserUtil from '../utils/UserUtil';
import { AppContext } from '../AppContext';
import Constants from '../constants/Constants';
import HeaderOptions from '../constants/HeaderOptions';
import ListStyles from '../styles/ListStyles';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import SafeAreaStyles from '../styles/SafeAreaStyles';

const scrollHeight = Dimensions.get('window').height - HeaderOptions.headerTopMenuHeight;

export default class HelpScreen extends Component {
  _isMounted = false;
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      myProfile: {}
    };
    this.helpList = [];
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.navigation = this.props?.navigation;
      this.setHeader();
      this.setHelpList();
      let myProfile = await UserUtil.getUserProfileLocal(this.context);
      this.setState({myProfile: myProfile});
      if(myProfile?.memberId!=undefined){
        CommonFunction.checkAccessTime();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }  

  setHelpList = () =>{
    this.helpList = [
      {
        id: 1,
        title: "はじめての方へ",
        data: [
          { id: 'id1-1', title: 'Pappyとは？', answer:
            "Pappyは成功した男性と魅力的な女性をつなぐ特別なマッチングサービスです。\n"+
            "Pappyは紳士的で成功した男性と夢を持つ魅力的な女性を真剣に応援しています。" 
          },
          { id: 'id1-2', title: '年齢確認について', answer:
            "年齢確認には公的証明書のご提出が必要となります。\n\n\n"+
            //"また、ご登録されている生年月日と年齢確認書類に相違がある場合、ご利用いただけません。\n\n\n"+
            "■年齢確認書類の種類\n"+
            "・運転免許証\n"+
            "・健康保険証\n"+
            "・パスポート etc\n\n"+
            "■必須の情報\n"+
            "・生年月日\n"+
            "・証明書の名称\n"+
            "・証明書の発行者の名称\n\n\n"+
            "健康保険証を提出する場合のみ、提出時に「記号と番号」「保険者番号」「QRコードと枝番」を隠した状態でご提出ください。" 
          },
          { id: 'id1-3', title: '違反報告', answer:
            "Pappyでは、皆様が安心・安全にご利用いただけるサービスを運営するために、\n"+
            "24時間365日の常時監視体制を行っております。\n\n"+
            "不快に感じる方がいた場合は、通報機能をご利用ください。\n\n"+
            "・18歳未満の方はご利用いただけません\n"+
            "・ご利用にあたり電話番号での本人確認が必要です\n"+
            "・一部機能は有料となります" ,
            answerFemale:
            "Pappyでは、皆様が安心・安全にご利用いただけるサービスを運営するために、\n"+
            "24時間365日の常時監視体制を行っております。\n\n"+
            "不快に感じる方がいた場合は、通報機能をご利用ください。\n\n"+
            "・18歳未満の方はご利用いただけません\n"+
            "・ご利用にあたり電話番号での本人確認が必要です"
          },
        ]
      },
      {
        id: 2,
        title: "登録",
        data: [
          { id: 'id2-1', title: '性別を変更したい', answer:
            "一度登録した性別は変更することは出来ません。\n"
            //"性別の変更をご希望の場合、現在のアカウントは退会手続きを行い、新規登録を行ってください。" 
          },
          { id: 'id2-2', title: '居住地の変更はできますか？', answer:'1.マイページの「プロフィールを編集する」より変更できます。' },
        ]
      },
      {
        id: 3,
        title: "ログイン",
        data: [
          { id: 'id3-1', title: 'ログイン方法', answer:'アカウントをお持ちの場合、ログイン情報(メールアドレスとパスワード)を入力することでログインできます。' },
          { id: 'id3-2', title: 'ログアウト機能はありますか？', answer:"1.マイページの「設定」をタップ\n"+"2.画面下部の「ログアウト」をタップ" },
        ]
      },
      {
        id: 14,
        title: "ポイント",
        data: [
          { id: 'id14-1', title: 'ポイントでできることは？', answer:"・ダイレクトメッセージ：3pt\n"+
            "・いいね!に交換\n"+
            "・セーフティチェックを確認：3pt"
          },
          { id: 'id14-2', title: 'ポイントを取得する方法は？', answer:"・ポイントを購入する\n"+
          "・有料会員になる（特典として付与）"
          },
          { id: 'id14-3', title: 'ポイントに有効期限はありますか？', answer:"購入日を含めて180日です。" },
          { id: 'id14-4', title: 'メッセージ付きいいね！とは何ですか？', answer:"メッセージ付きいいね！はいいね！と一緒にメッセージを送れる機能です。" }
        ]
      },
      {
        id: 4,
        title: "プロフィール",
        data: [
          { id: 'id4-1', title: 'プロフィール画像を設定するには？', answer:
            "1.マイページの画面上部のシルエットをタップする\n"+
            "2.メイン画像をタップする\n"+
            "3.写真を選択する\n\n"+            
            "プロフィール画像は最大8枚まで掲載できます。" 
          },
          { id: 'id4-2', title: 'プロフィール画像等の審査はいつ終わりますか？', answer:
            "年齢確認書類やプロフィール画像、自己紹介等は、1件ずつ目視にて審査・チェックを行っております。\n\n"+
            "審査は通常1時間以内に完了しますが、場合によっては24時間程度お時間をいただくこともございます。また、ご提出いただいた書類や画像に不備があった場合は、お知らせ等でその旨をご連絡させていただきます。\n\n"+            
            "24時間経過しても審査が完了しない場合には、お手数ですが、お問い合わせフォームよりご連絡いただきますようお願い致します。" 
          },
          { id: 'id4-3', title: 'プロフィール画像の審査基準は？', answer:
          "以下のような画像は審査NGとなります。\n\n"+
          "・不鮮明\n"+            
          "・本人以外が写っている画像\n"+ 
          "・イラスト、ＡＩ画像\n"+ 
          "・わいせつ・卑猥な画像\n"+ 
          "・加工、フィルター感が強い\n"+ 
          "・インターネット上に掲載されている画像\n"+ 
          "・著作権を侵害するもの\n"+ 
          "・個人情報を含むもの\n"+ 
          "・公序良俗に反するもの\n"+ 
          "・児童を連想させるもの(学生服など)\n"+ 
          "・その他、運営側で掲載不適切と判断したもの"
          },
          { id: 'id4-4', title: 'プロフィール情報が否認されるのはなぜですか？', answer:
            "以下に該当するものは、自己紹介等のプロフィールへの掲載を否認しております。\n"+
            "不特定多数に公開される場所での金銭授与を想起されるもの\n"+
            "援助交際を助長するおそれがある文言\n"+
            "ユーザー様が不愉快に感じられる文章\n"+
            "LINE IDや他連絡先の公開\n"+
            "他サイトやビジネスへの勧誘、またはそれを促す文章" 
          },
          { id: 'id4-5', title: '相手のオンライン状態は確認できますか？', answer:
            "一覧や相手プロフに表示している丸いアイコンはお相手が最後にログインしてからの経過時間の目安を表したものです。\n"+
            "※表示色については、ご利用の環境によって異なります。\n\n"+            
            "・オンライン中(緑色)\n"+
            "・24時間以内(ピンク)\n"+
            "・2日以内(オレンジ)\n"+
            "・3日以内(灰色)" 
          },
          { id: 'id4-6', title: '年齢を変更したい', answer:'一度入力した生年月日から自動更新されるため、変更はできません。' },
          { id: 'id4-7', title: 'ニックネームに絵文字を使いたい', answer:'Pappyでは絵文字は使えません。' },
        ]
      },
      {
        id: 5,
        title: "いいね",
        data: [
          { id: 'id5-1', title: 'いいねとは？', 
          answer:"興味を持ったお相手にはいいねを送りましょう。\n"+
          "いいねを受けたお相手に通知され、お相手からもいいねを受けることができればマッチングとなります。\n\n"+
          "お相手に1いいねを消費することでいいねを送ることができます。\n"+
          "残いいね数がなくても、1ポイント=1いいねとして利用できます。\n\n"+
          "一度送ったいいねを取り消すことはできません。\n"+
          "送信する前に、よくご確認ください。"
          ,
          answerFemale:
            "興味を持ったお相手にはいいねを送りましょう。\n"+
            "いいねを受けたお相手に通知され、お相手からもいいねを受けることができればマッチングとなります。\n\n"+
            "一度送ったいいねを取り消すことはできません。\n"+
            "送信する前に、よくご確認ください。\n"
          },
          // { id: 'id5-3', title: '質問付きいいねとは？', 
          // answer:"あなたが設定した質問に回答したお相手が、あなたにいいねを送ることができます。\n\n"+
          // "質問付きいいねの設定から、質問を最大3つまで設定することができます。"
          // },
          { id: 'id5-2', title: 'いいねありがとうとは？', answer:
            "お相手があなたにいいねを送ってくれた場合、いいねを消費することなくいいねありがとうを送ることができます。\n"+
            "いいねありがとうを送るとマッチング成立となります。" ,
            answerFemale: "お相手があなたにいいねを送ってくれた場合、いいねありがとうを送ることができます。\n"+
            "いいねありがとうを送るとマッチング成立となります。" 
          }
        ]
      },
      {
        id: 6,
        title: "メッセージ",
        data: [
          { id: 'id6-1', title: 'メッセージが見れません', answer:
            "メッセージ機能のご利用には、ステータスに応じて下記登録が必要です。\n\n"+
            "■男性\n"+
            "男性のお客様は有料会員登録をご登録頂くと過去のやり取りを含め閲覧できるようになります。\n\n"+            
            "■女性\n"+
            "女性のお客様は年齢確認が完了していればメッセージ閲覧できます。" 
          },
          { id: 'id6-2', title: '送信したメッセージや画像は消せますか？', answer:
            "送信から5分以内かつお相手が未読のメッセージを長押しすると送信の取り消しが可能です。\n"+
            "※マッチング前に送信するダイレクトメッセージは取り消しが出来ません。\n"+
            "画像は送信した画像右上の「✕」から削除することができます。\n"+
            "お相手に閲覧されたくない場合はブロック機能をご利用ください。" 
          },
          { id: 'id6-3', title: 'メッセージをやりとりしていた相手が表示されなくなった', answer:
            "お相手が退会をした場合、リストから表示されなくなります。\n"+
            "ブロックした、された場合も表示されなくなります。" 
          },
        ]
      },
      {
        id: 7,
        title: "会いたい",
        data: [
          { id: 'id7-1', title: '日付から探すとは？', answer:'会いたいと思うお相手を日付及び都道府県から検索できる機能です。' },
          { id: 'id7-2', title: 'どうやって登録するの？', answer:
            "日付から探す画面から登録できます。\n"+
            "デート可能日をカレンダーに登録して頂きます。1ヶ月先まで登録可能です。\n"+
            "あなたのプロフィールにも表示されますので、出会いのチャンスが広がります。" 
          },
          { id: 'id7-3', title: '登録できる都道府県は？', answer:'最大3都道府県まで設定できます。4つ以上は登録できません。' },
          { id: 'id7-4', title: '検索できる都道府県は？', answer:'最大5都道府県まで設定できます。6つ以上は設定できません。' },
          { id: 'id7-5', title: '会いたいをタップするとどうなるの？', answer:'お相手に会いたいメッセージが送信されます。' },
        ]
      },
      {
        id: 15,
        title: "お気に入り",
        data: [
          { id: 'id15-1', title: 'お気に入りとは？', answer:
            "マッチングしたお相手をお気に入りマークで仕分け設定できる機能です。\n\n"+
            "メッセージ一覧画面の絞り込むから三段階のお気に入りマークで絞り込み表示することができます。\n\n"+
            "お気に入りマークを付けたことは、お相手には伝わりません。\n"+
            "また名称は「名称を変更する」から自由に記入することが可能です。"
          },
          { id: 'id15-2', title: 'どうやって登録するの？', answer:
            "・メッセージ一覧画面から設定したいお相手をスワイプしてお気に入りをタップ\n"+
            "・やりとり画面の★マークをタップ\n"
          },
        ]
      },
      {
        id:16,
        title:"非表示・ブロック",
        data: [
          { id: 'id16-1', title: '非表示とは？', answer:
            "お相手からの「いいね！」をスキップ・やりとり中の画面からお相手をスライド・相手プロフ右上の「・・・」マークから非表示リストに追加することができます。\n"+
            "非表示にするとご自身の画面からお相手が見えなくなります。\n"+            
            "お相手にはお客様が非表示にしたことは伝わりません。\n"+
            "マイページ＞設定＞非表示リストより解除することができます。"
          },
          { id: 'id16-2', title: 'ブロックとは？', answer:
            "相手プロフ右上の「・・・」マークからブロックリストに追加することができます。\n"+
            "ブロックするとご自身とお相手の画面にお互いを表示しないようになり、お相手はあなたのプロフィール、メッセージ履歴を見ることができなくなります。\n"+            
            " 一度ブロックすると解除はできませんのでご注意ください。\n"+
            "マイページ＞設定＞ブロックリストよりブロックしたお相手の確認が可能です。"
          }
        ]
      },
      {
        id: 8,
        title: "通知設定",
        data: [
          { id: 'id8-1', title: '認証コードが届かない', answer:
            "認証コードが届かない方は以下をお試しください。\n\n"+
            "・メールアドレスが間違っていないか\n"+
            "・迷惑メールフォルダに振り分けられていないか\n"+
            "・「pappy.jp」が迷惑メールフィルターや拒否リストに設定されていないか\n"+
            "・キャリアメールではなく、gmail、yahooメールなどのフリーメールを利用する" 
          },
          { id: 'id8-2', title: '通知メールの受信設定を変更したい', answer:
            "いいねやメッセージが届いた場合、ご登録のメールアドレス宛にお知らせメールをお送りします。\n"+
            "通知メール受信のON/OFFは、以下の手順で設定することができます。\n\n"+            
            "1.マイページの「設定」をタップ\n"+
            "2.「通知設定」をタップ\n"+
            "3.受信したい項目をオンに設定し変更するをタップ" 
          },
        ]
      },
      {
        id: 9,
        title: "不具合について",
        data: [
          { id: 'id9-1', title: '不具合の報告', answer:
            "Pappyでは24時間お客様からのお問い合わせ、ご相談を受け付けております。\n"+
            "ヘルプをご覧いただき、解決に至らない場合には以下の手順にてお気軽にお問い合わせください。\n\n"+            
            "1.「マイページ」のお問い合わせを選択\n"+
            "2.新規お問い合わせボタンを選択\n"+
            "3.お問い合わせ内容を入力し送信\n\n"+            
            "サポートからの返信はお問い合わせ履歴に表示されます。" 
          },
        ]
      },
      {
        id: 10,
        title: "有料会員",
        data: [
          { id: 'id10-1', title: '有料会員とは？', answer:
              "有料会員は男性向けのサービスになります。\n"+
              "有料会員になると下記のサービスがご利用頂けるようになります。\n\n"+              
              "1.メッセージのやりとりがし放題\n"+
              "2.気になる女性に何人がいいねしているかがわかる\n"+
              "3.有料会員登録時にポイントをプレゼント！さらに毎月プレゼント！"
          },
          { id: 'id10-2', title: 'クレジットカードを変更したい', answer:'「会員ステータス」の「クレジットカード情報変更」から変更が可能です。\n'+'※WEBからのみ' },
          { id: 'id10-3', title: '有料会員の解約', answer:''},
          { id: 'id10-4', title: '決済が完了したのに反映されない', answer:''},
        ]
      },
     {
        id: 11,
        title: "プライベートモード",
        data: [
          { id: 'id11-1', title: 'プライベートモード', answer:
              "・プライベートモードとは？\n"+
              "プライベートモードとは、プロフィールの公開状態を設定できるサービスです。\n\n"+
              "・プロフィールの公開範囲について\n"+
              "プライベートモードを登録すると以下のお相手にのみプロフィールを公開します。\n"+
              "・いいね！を送ったお相手\n"+
              "・マッチングしたお相手"
          },
        ]
      },
      {
        id: 12,
        title: "退会",
        data: [
          { id: 'id12-1', title: '退会したい', answer:'',
              answerFemale:
              "【退会手順】\n"+
              "1.マイページの「設定」をタップ\n"+
              "2.「お問い合わせ」をタップ\n"+
              "3.お問い合わせカテゴリの「退会」を選択\n"+
              "4.退会理由を選択し「退会手続きを続ける」をタップ\n"+
              "5.退会理由をご入力後「確認画面」をタップ\n"+
              "6.確認画面で「退会する」をタップ\n\n"+              
              "退会されるとこれまでのデータが全て削除されます\n"+
              "再登録後、以前のデータは引き継げません\n"+
              "Pappyを退会されますと、退会日から30日間は再登録ができませんのでお気をつけください"
          },
        ]
      },
      {
        id: 13,
        title: "その他",
        data: [
          { id: 'id13-1', title: '推奨環境について', answer:
            "【スマートフォンブラウザ】\n"+
            "iPhone端末：safari\n"+
            "Android端末：Google Chrome\n\n\n"+
            "ガラケー型スマートフォン(ガラホ)は非推奨です。\n\n"+            
            "※Cookie、JavaScriptを有効にした状態でご利用ください。\n"+
            "※セキュリティソフトや広告ブロック機能を有効にしていると一部のページが表示されなかったり、レイアウトが崩れたりする場合がございます。"
          },
          { id: 'id13-2', title: '他の人の画像を使用している', answer:
            "自分や知人の画像が勝手に使用されている、またプロフィール情報などが無断で"+
            "使用されている場合には、削除申請をお願い致します。\n\n"+            
            "運営にて確認の上、無断使用であると判断できた場合、該当部分の削除や当該アカウントについて利用停止などの対応を行います。"
          },
        ]
      }
    ];
  }

  setHeader(){
    this.title = 'ヘルプ';
    if(this.props.setHeader!=undefined){
      this.props.setHeader(this.title,this.navigation);
      return;
    }
    const fromContact = this.props.route?.params?.contact;
    CommonFunction.setHeaderSetting(this.navigation, this.title, true, fromContact);
  }

  renderItem = ({item, index})=>{
    if((item.id===10 || item.id===14) && this.state.myProfile?.sex==Constants.FEMALE) return <></>;
    if(item.data){
      return (      
        <RightSlidePopup ref={ref=>this.refIntro = ref} title={'ヘルプ'} showChild={true}
        component={<ChildHelp sex={this.state.myProfile?.sex} data={item.data} {...this.props} />}>
          <View style={styles.itemQuestion}>
            <View style={styles.blockTxtQuestion}><Text style={styles.txtQuestion}>{item.title}</Text></View>
            <View style={styles.blockIconQuestion}><AntDesign name='right' style={styles.rightIcon} /></View>
          </View>
        </RightSlidePopup>
      );
    }else{
      let data = [item];
      return <ChildHelp sex={this.state.myProfile?.sex} data={data} {...this.props} icon="right"/>
    }
  }

  render() {
    return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) =>
      <View style={[ListStyles.container,SafeAreaStyles(insets).container]}>
        <View style={styles.blogInner}>
          <FlatList
            contentContainerStyle={ListStyles.scrollContainer}
            data={this.helpList}
            renderItem={this.renderItem}
            initialNumToRender={this.helpList.length}
            keyExtractor={(item) => item.id}
          />
        </View>
      </View>
    }
    </SafeAreaInsetsContext.Consumer>
    );
  }
}

class ChildHelp extends Component{
  _isMounted = false;
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      ShowId: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  toggle = (id) =>{
    let {ShowId} = this.state;
    if(ShowId[id]===false || typeof ShowId[id]==='undefined'){
      ShowId[id] = true;
    }else{
      ShowId[id] = false;
    }
    this.setState({ShowId: ShowId});
  }

  renderItem = ({item, index})=>{
    let toggle = this.state.ShowId[item.id];
    let rightIcon = 'down';
    if(this.props.icon!=undefined){
      rightIcon = this.props.icon;
    }
    if(toggle=== true) rightIcon = 'down';

    let answer = item.answer;
    if(item.answerFemale!=undefined && this.props?.sex==Constants.FEMALE) {
      answer = item.answerFemale;
    }

    return (
      <View style={styles.blogInner}>
        <TouchableOpacity onPress={()=>{
          this.toggle(item.id);
        }}>
          <View style={styles.itemQuestion}>
            <View style={styles.blockTxtQuestion}><Text style={styles.txtQuestion}>{item.title}</Text></View>
            <View style={styles.blockIconQuestion}><AntDesign name={rightIcon} style={styles.rightIcon} /></View>
          </View>
        </TouchableOpacity>
        {toggle=== true && (item.id!='id10-4' && item.id!='id10-3' && item.id!='id12-1') &&
        <View style={styles.blocAnswer}>
          <Text style={styles.txtAnswer}>{answer}</Text>
        </View>
        }
        {toggle=== true && item.id=='id12-1' && (item.answerFemale!=undefined && this.props?.sex==Constants.FEMALE) &&
          <View style={styles.blocAnswer}>
            <Text style={styles.txtAnswer}>{answer}</Text>
          </View>
        } 
        {toggle=== true && item.id=='id10-4' &&
          <Answer10_4 {...this.props} />
        }
        {toggle=== true && item.id=='id10-3' &&
          <Answer10_3 {...this.props} />
        }
        {toggle=== true && item.id=='id12-1' &&  this.props?.sex==Constants.MALE &&
          <Answer12_1 {...this.props} />
        } 
      </View>
    );
  }

  render(){
    const insets = this.context?.appState?.insets;
    return (
      <View style={[styles.container,SafeAreaStyles(insets).containerMargin]}>
       <FlatList
          data={this.props.data}
          renderItem={this.renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>
    );
  }
}

class Answer10_4 extends Component{
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      ShowId: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  gotoContract = async()=>{
    if(Platform.OS==='web'){
      const token = await CommonFunction.getEncryptAccessToken();
      window.location.href = ApiConfig.contact.url+'?token='+token;
    }else{
      this.props.navigation?.navigate('Inquiry');
    }
  }

  render(){
    return (
      <View style={styles.blocAnswer}>
      <Text style={styles.txtAnswer}>
        各種有料プランへのご登録・ポイントのご購入など、決済を伴うお申込みをされたにも関わらず、{"\n"}
        {"\n"}
        内容がPappy内に反映されない場合は、下記手順に沿ってご確認ください。{"\n"}
        {"\n"}
      </Text>
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>
        【決済の完了を再確認】{"\n"}
        {"\n"}
        ○Apple ID決済の場合{"\n"}
        {"\n"}
      </Text>            
      <Text style={styles.txtAnswer}>  
        Appleからメールで領収書が届きます。{"\n"}
        {"\n"}
      </Text>   
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>
        ○Google Play決済の場合{"\n"}
        {"\n"}
      </Text>            
      <Text style={styles.txtAnswer}>  
        Googleからメールで領収書が届きます。{"\n"}
        {"\n"}
      </Text>
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>  
        ○クレジットカード決済・あと払い（ペイディ） の場合{"\n"}
        {"\n"}
      </Text>  
      <Text style={styles.txtAnswer}>  
      「マイページ」{'>'}「会員ステータス」{'>'}「有料会員」よりご確認いただき、{"\n"}{"\n"}
        データが更新されていない場合は<TouchableOpacity onPress={()=>{this.gotoContract()}}><Text style={{textDecorationLine:'underline'}} >Pappyカスタマーサポート</Text></TouchableOpacity>
        までお問い合わせください。
      </Text>
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>  
        {"\n"}
        {"\n"}
        【購入情報を復元（リストア）する】{"\n"}
        {"\n"}
      </Text>  
      <Text style={styles.txtAnswer}>  
        決済処理の途中で接続状況が悪化するなどの原因で、決済が行われた情報がシステムに届かない場合が、ごくまれにあります。{"\n"}
        {"\n"}
        その場合、下記の手順で決済情報の再送が行えます。{"\n"}
        {"\n"}
        ※リストアは「既に存在する決済情報を再送する」操作です。{"\n"}
        {"\n"}
        重複しての決済とはなりませんのでご安心ください。{"\n"}
        {"\n"}
      </Text>
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>  
        Apple ID/GooglePlay決済{"\n"}
        {"\n"}
      </Text>  
      <Text style={styles.txtAnswer}>  
        各種有料プランへのご登録・ポイントのご購入など、決済を伴うお申込みをされたにも関わらず、内容がPappy内に反映されない場合は下記手順に沿ってご確認ください。{"\n"}
        {"\n"}
      </Text>
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>  
        【Apple ID決済/GooglePlay決済の場合】{"\n"}
        {"\n"}
      </Text>  
      <Text style={styles.txtAnswer}>  
        1.Apple/Googleからメールで領収書が届いてることをご確認ください。{"\n"}
        {"\n"}
        2.決済処理の途中で接続状況が悪化するなどの原因で、決済が行われた情報がシステムに届かない場合が、ごくまれにあります。 その場合、下記の手順で決済情報の再送が行えます。{"\n"}
        {"\n"}
        ————————————–{"\n"}
        {"\n"}
        ・「有料会員登録」＞「リストアする」ボタンを押下する{"\n"}
        {"\n"}
        ————————————–{"\n"}
        {"\n"}
        ※購入情報の復元（リストア）は「既に存在する決済情報を再送する」操作です。{"\n"}
        {"\n"}
        重複しての決済とはなりませんのでご安心ください。{"\n"}
        {"\n"}
        また、決済が正常に行われない場合には、Apple ID/GooglePlayにご登録されているクレジットカードの与信枠が上限に達している場合もございます。{"\n"}
        {"\n"}
        一度クレジットカード情報をご確認いただき、「Apple ID のお支払い情報を変更・削除する」/GooglePlay「アプリ内購入に関する問題」をお試しくださいませ。{"\n"}
        {"\n"}
        上記をお試しいただいても問題が解決されない場合は、<TouchableOpacity onPress={()=>{this.gotoContract()}}><Text style={{textDecorationLine:'underline'}} >Pappyカスタマーサポート</Text></TouchableOpacity>までお問い合わせください。{"\n"}
        {"\n"}
      </Text>
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>  
        クレジットカード決済・あと払い（ペイディ）{"\n"}
      </Text>  
      <Text style={styles.txtAnswer}>  
        {"\n"}
        クレジットカード決済・あと払い（ペイディ）にはリストア処理はございません。{"\n"}
        {"\n"}
        <TouchableOpacity onPress={()=>{this.gotoContract()}}><Text style={{textDecorationLine:'underline'}} >Pappyカスタマーサポート</Text></TouchableOpacity>までお問い合わせください。{"\n"}
        {"\n"}
        問題が解決されない場合{"\n"}
        {"\n"}
        <TouchableOpacity onPress={()=>{this.gotoContract()}}><Text style={{textDecorationLine:'underline'}} >Pappyカスタマーサポート</Text></TouchableOpacity>までお問い合わせください。
      </Text>
    </View>
    );
  }
}

class Answer10_3 extends Component{
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      ShowId: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  handleClick = (url) => {
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url);
      }
    });
  };

  render(){
    return (
    <View style={styles.blocAnswer}>
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>
        ■クレジットカード決済・あと払い（ペイディ）{"\n"}
        {"\n"}
      </Text>            
      <Text style={styles.txtAnswer}>  
        WEB版のマイページ「会員ステータス」の「有料会員の解約」から解約画面にお進みください。{"\n"}
        有料会員を解約される場合、ご契約中のプランの有効期限24時間前までに、お客様ご自身で解約手続きを行っていただく必要があります{"\n"}
        退会と同時に解約されますのでご注意ください。{"\n"}
        {"\n"}
      </Text>   
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>
        ■iPhoneアプリ内課金(Apple ID決済){"\n"}
        {"\n"}
      </Text>            
      <Text style={styles.txtAnswer}>  
        有料会員を解約される場合、更新予定日の24時間以上前にお手続きを行わないと、Apple社のシステムの関係で更新処理が行われてしまいますのでご注意ください。{"\n"}
        Apple ID決済は、Apple社提供のサービスになるため、Pappy上で解約手続きを行うことができません。{"\n"}
        解約手続きの詳細については
          <TouchableOpacity onPress={()=>{this.handleClick('https://support.apple.com/ja-jp/HT202039')}}>
            <Text style={styles.aLink}>Apple社のヘルプ</Text>
          </TouchableOpacity>
        を直接ご参照ください。{"\n"}
        {"\n"}
      </Text> 
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>
        ■Androidアプリ内課金(Google Play決済){"\n"}
        {"\n"}
      </Text>            
      <Text style={styles.txtAnswer}>  
        有料会員を解約される場合、更新予定日の24時間以上前にお手続きを行わないと、Google社のシステムの関係で更新処理が行われてしまいますのでご注意ください。{"\n"}
        Google Play決済は、Google社提供のサービスになるため、Pappy上で解約手続きを行うことができません。{"\n"}
        解約手続きの詳細については
          <TouchableOpacity onPress={()=>{this.handleClick('https://support.google.com/googleplay/answer/7018481')}}>
            <Text style={styles.aLink}>Google社のヘルプ</Text>
          </TouchableOpacity>
        を直接ご参照ください。{"\n"}
        {"\n"}
      </Text> 
      
    </View>
    );
  }
}

class Answer12_1 extends Component{
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      ShowId: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  handleClick = (url) => {
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url);
      }
    });
  };

  render(){
    return (
    <View style={styles.blocAnswer}>
      <Text style={styles.txtAnswer}>  
        ※有料会員に登録中の場合、解約後に退会手続きを行ってください。{"\n"}{"\n"}
        【退会手順】{"\n"}
        1.有料会員に登録している場合は解約する{"\n"}
        2.マイページの「設定」をタップ{"\n"}
        3.「お問い合わせ」をタップ{"\n"}
        4.お問い合わせカテゴリの「退会」を選択{"\n"}
        5.退会理由を選択し「退会手続きを続ける」をタップ{"\n"}
        6.退会理由をご入力後「確認画面」をタップ{"\n"}
        7.確認画面で「退会する」をタップ{"\n"}
        退会されるとこれまでのデータが全て削除されます{"\n"}
        再登録後、以前のデータは引き継げません{"\n"}
        Pappyを退会されますと、退会日から30日間は再登録ができませんのでお気をつけください{"\n"}
        {"\n"}
      </Text>  
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>
        ■クレジットカード決済・あと払い（ペイディ）{"\n"}
        {"\n"}
      </Text>            
      <Text style={styles.txtAnswer}>  
        クレジットカード決済・あと払い（ペイディ）で購入された有料会員プランは、退会と同時に解約されます。{"\n"}
        {"\n"}
      </Text>   
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>
        ■iPhoneアプリ内課金(Apple ID決済){"\n"}
        {"\n"}
      </Text>            
      <Text style={styles.txtAnswer}>  
        iOSアプリから購入された有料会員プランは、退会手続きをしても解約されません。{"\n"}
        解約手続きの詳細については
          <TouchableOpacity onPress={()=>{this.handleClick('https://support.apple.com/ja-jp/HT202039')}}>
            <Text style={styles.aLink}>Apple社のヘルプ</Text>
          </TouchableOpacity>
        を直接ご参照ください。{"\n"}
        {"\n"}
      </Text> 
      <Text style={[styles.txtAnswer,{fontWeight:800}]}>
        ■Androidアプリ内課金(Google Play決済){"\n"}
        {"\n"}
      </Text>            
      <Text style={styles.txtAnswer}>  
        Androidアプリから購入された有料会員プランは、退会手続きをしても解約されません。{"\n"}
        解約手続きの詳細については
          <TouchableOpacity onPress={()=>{this.handleClick('https://support.google.com/googleplay/answer/7018481')}}>
            <Text style={styles.aLink}>Google社のヘルプ</Text>
          </TouchableOpacity>
        を直接ご参照ください。{"\n"}
        {"\n"}
      </Text> 
      
    </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor:'#ffffff',
    marginTop:0
  },
  blogInner: {
    //paddingTop:16,
    //paddingBottom:20,
    flex:1
  },
  rightIcon:{
    //position:'absolute',
    //top:'50%',
    right:10,
    fontSize:18,
    color:'#484848',
    //transform:[{translateY:-10}]
  },
  itemQuestion: {
    flex:1,
    width:'100%',
    flexDirection:'row',
    paddingRight:10,
    paddingLeft:16,
    borderBottomColor:'#ededed',
    borderBottomWidth:1,
  },
  txtQuestion: {
    fontSize:14,
    color:'#484848',
    //lineHeight:50,
  },
  blockTxtQuestion:{
    flex:1, 
    minHeight:50,
    justifyContent:'center'
  },
  blockIconQuestion:{
    justifyContent:'center'
  },
  blocAnswer: {
    paddingTop:15,
    paddingBottom:15,
    paddingRight:20,
    paddingLeft:20,
    borderBottomColor:'#ededed',
    borderBottomWidth:1,
  },
  txtAnswer: {
    fontSize:14,
    color:'#484848',
    lineHeight:18,
  },
  touch:{
    flex:1,
    width:'100%',
    alignItems:'stretch',
    justifyContent:'center',
    alignContent:'center',
    alignSelf:'center',
  },
  aLink:{
    color:'#0070c9'
  }
});
